import {action} from "mobx"
import {printReport} from "../../global/helpers/actions"
import api from "../../global/actions/api"
import GlobalStore from "../../global/store/GlobalStore"
import moment from "moment"

class RequestPrintStore {
	@action print(form) {
		const formVals = form.values()

		let pravnickaOsoba = ""
		if (isNotEmpty(formVals.companyDesc)) {
			let type = GlobalStore.CL["companyType"].find((row) => row._id == formVals.companyDesc)
			if (isSafe(type)) {
				pravnickaOsoba = type.name_ext
			}
		}

		let adresaObec = ""
		if (isNotEmpty(formVals.companyCity)) {
			let address = GlobalStore.CL["city"].find((row) => row._id == formVals.companyCity)
			if (isSafe(address)) {
				adresaObec = address.name_ext
			}
		}

		let adresaKraj = ""
		if (isNotEmpty(formVals.companyRegion)) {
			let address = GlobalStore.CL["region"].find((row) => row._id == formVals.companyRegion)
			if (isSafe(address)) {
				adresaKraj = address.name_ext
			}
		}

		let adresaCorspondingObec = ""
		if (isNotEmpty(formVals.companyCity)) {
			let address = GlobalStore.CL["city"].find((row) => row._id == formVals.companyCorrespondenceCity)
			if (isSafe(address)) {
				adresaCorspondingObec = address.name_ext
			}
		}

		let pravnaForma = ""
		if (isNotEmpty(formVals.companyLegalForm)) {
			let forma = GlobalStore.CL["legalForm"].find((row) => row._id == formVals.companyLegalForm)
			if (isSafe(forma)) {
				pravnaForma = forma.name_ext
			}
		}

		let typOpravnenychAktivit = ""
		if (isNotEmpty(formVals.projectActivityType)) {
			let aktivity = GlobalStore.CL["activityType"].find((row) => row._id == formVals.projectActivityType)
			if (isSafe(aktivity)) {
				typOpravnenychAktivit = aktivity.name_ext
			}
		} else if (isNotEmpty(formVals.eligibleArea)) {
			let aktivity = GlobalStore.CL["activityType"].find((row) => row._id == formVals.eligibleArea)
			if (isSafe(aktivity)) {
				typOpravnenychAktivit = aktivity.name_ext
			}
		}

		let printValues = {
			obchodne_meno: formVals.companyName,
			zoip: formVals.zoIPNumber,
			customFileName: formVals.zoIPNumber,
			pravnicka_osoba: pravnickaOsoba,
			adresa_ulica: formVals.companyStreet,
			adresa_popisne_cislo: formVals.companyStreetNumber,
			adresa_obec: adresaObec,
			adresa_psc: formVals.companyPSC,
			adresa_kraj: adresaKraj,
			koresp_adresa_ulica: isNotEmpty(formVals.companyCorrespondenceStreet)
				? formVals.companyCorrespondenceStreet
				: formVals.companyStreet,
			koresp_adresa_popisne_cislo: isNotEmpty(formVals.companyCorrespondenceStreetNumber)
				? formVals.companyCorrespondenceStreetNumber
				: formVals.companyStreetNumber,
			koresp_adresa_obec: isNotEmpty(adresaCorspondingObec) ? adresaCorspondingObec : adresaObec,
			koresp_adresa_psc: isNotEmpty(formVals.companyCorrespondencePSC)
				? formVals.companyCorrespondencePSC
				: formVals.companyPSC,
			pravna_forma: pravnaForma,
			ic_dph: formVals.icdph,
			ico: formVals.ico,
			dic: formVals.dic,
			iban: formVals.companyIBAN,
			kontakt_meno: formVals.contactPersonName,
			email: formVals.contactPersonMail,
			telefon: formVals.contactPersonPhone,
			project_name: formVals.projectName,
			typ_opravnenych_aktivit: typOpravnenychAktivit,
			strucny_popis_projektu: formVals.projectDescAndTarget,
			vychodiskova_situacia: formVals.projectBasicParameters,
			sposob_realizacie_projektu: formVals.projectImplementationMethod,
			doplnujuce_udaje: formVals.projectAdditionalInfo,
			potentialSolverName: formVals.potentialSolverName,
			potentialSolverIco: formVals.potentialSolverIco,
			potentialSolverDic: formVals.potentialSolverDic,
			potentialSolverIcdph: formVals.potentialSolverIcdph,
			potentialSolverCompletePrice: formVals.potentialSolverCompletePrice,
			potentialSolverVoucherPrice: formVals.potentialSolverVoucherPrice,
			potentialSolverCofinancedPrice: formVals.potentialSolverCofinancedPrice,
			sendDate: formVals.sent_date ? moment(formVals.sent_date).format("DD.MM.YYYY HH:mm") : "",
			consent_marketing_text: formVals.sentMarketingDataYes == true ? "ÁNO súhlasím" : "NIE, nesúhlasím",
			miesto_realizacie_projektu: formVals.projectRealizationPlace,
			lehota_plnenia_projektu: formVals.projectDeadlineCompletion
		}

		let statutari_tr = ""
		//Statutari
		let statutarData = isEmpty(formVals.applicantChipForm) ? [] : JSON.parse(formVals.applicantChipForm)
		statutarData.map((statutar) => {
			statutari_tr += `
			<tr>
				<td class="td_80 bg_blueLight center">${statutar.name}
				<td class="td_20 bold center">${statutar.isStatutar ? "x" : ""}</td>
			</tr>`
		})

		printValues["statutarny_organ_ziadatela_table"] = `<table style="margin-top: 20px;">
			<tr>
				<td class="td_80 bg_blue bold blue center" style="font-size: 13px;">2. Štatutárny orgán žiadateľa / Fyzická osoba žiadateľa</td>
				<td class="td_20 bg_blue bold blue center" style="font-size: 13px;">ŽoZSP v INOWA odosiela</td>
			</tr>
			${statutari_tr}	
		</table>`

		printValues["oblast_poradenstva"] =
			formVals.ipceiAdvice1 == true
				? "Poradenské služby zamerané na tvorbu dokumentácie pre proces notifikácie IPCEI - Vytvorenie dokumentu Project Portfolio podľa templatu, ktorý je definovaný EK a je súčasťou dokumentácie výzvy IPCEI H2, dostupné na https://www.siea.sk/inovacie/projekty-ipcei/vyzva-ipcei-2020/ a výzvy IPCEI mikrolektronika, dostupné na https://www.siea.sk/inovacie/projekty-ipcei/vyzva-ipcei-2021/ vrátane Funding Gap Questionnaire – template EK."
				: "Poradenské služby zamerané na tvorbu dokumentácie pre proces notifikácie IPCEI – Vybrané jednotlivé kapitoly dokumentu Project Portfolio, alebo Funding Gap Questionnaire podľa výberu a potreby žiadateľa."

		if (formVals.callCode == "IP_ZS_122") {
			let podaktivity_table = ""
			if (formVals.eligibleAreaCode == "prjvavonkoneumetakvsroio") {
				podaktivity_table = `
				<tr>
				<td class="td_50 bg_blue bold">A.1. Vývoj nových produktov a procesov používaných v diagnostike alebo monitoringu stavu ochorenia pre laboratóriá, najmä molekulovú diagnostiku a patológiu, vrátane nových laboratórnych prístrojov.</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityA1 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">A.2. Vývoj nových produktov a procesov používaných na diagnostiku alebo monitorovanie ochorenia s využitím zobrazovacích technológií, vrátane zobrazovacích a optických prístrojov.</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityA2 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">A.3.  Vývoj nových produktov a procesov implementovateľných v prevencii uvedených ochorení.</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityA3 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">A.4. Vývoj nových produktov a procesov implementovateľných v následnej starostlivosti uvedených ochorení.</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityA4 == true ? "X" : ""}</td>
				</tr>
				`
			} else if (formVals.eligibleAreaCode == "matabiomatzdrav") {
				podaktivity_table = `
				<tr>
				<td class="td_50 bg_blue bold">B.1. Vývoj produktov pre regeneračnú medicínu, vrátane nových línií kmeňových buniek a bunkovej terapie; produkty na regeneráciu chronických rán.</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityB1 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">B.2. Vývoj biokompatibilných materiálov, vrátane zlúčenín špeciálnych kovov a iných typov implantátov.</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityB2 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">B.3. Vývoj biomateriálov pre špecifické ochorenia, vrátane nanomateriálov, zeolitov, stentov a pod.</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityB3 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">B.4. Vývoj dezinfekčných a antibakteriálnych materiálov.</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityB4 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">B.5. Vývoj inteligentných materiálov vrátane textílií.</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityB5 == true ? "X" : ""}</td>
				</tr>
				`
			} else if (formVals.eligibleAreaCode == "liekyaformy") {
				podaktivity_table = `
				<tr>
				<td class="td_50 bg_blue bold">C.1. Vývoj liekov a liečivých prípravkov pre humánne použitie.</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityC1 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">C.2. Vývoj kozmetických produktov používaných v zdravotnej starostlivosti</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityC2 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">C.3. Vývoj chemických produktov používaných v zdravotnej starostlivosti.</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityC3 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">C.4. Vývoj nových foriem liekov a liečivých prípravkov.</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityC4 == true ? "X" : ""}</td>
				</tr>
				`
			} else if (formVals.eligibleAreaCode == "tridtlacabiotlac") {
				podaktivity_table = `
				<tr>
				<td class="td_50 bg_blue bold">D.1. Vývoj nových typov implantátov, zdravotných pomôcok a zdravotníckej techniky prostredníctvom 3D tlače vrátane personalizovaných implantátov a zdravotných pomôcok.</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityD1 == true ? "X" : ""}</td>
				</tr>
				`
			} else if (formVals.eligibleAreaCode == "senzorydiagtechmonzdrav") {
				podaktivity_table = `
				<tr>
				<td class="td_50 bg_blue bold">E.1. Vývoj a pilotná implementácia diagnostických, terapeutických a monitorovacích systémov ochorení a pacienta vrátane prístrojov a technológií pre použitie v domácnosti, v teréne, domovoch sociálnych služieb, na miestach kde sa združuje vyšší počet ľudí a tam kde je dostupnosť štandardnej zdravotnej starostlivosti obmedzená.</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityE1 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">E.2. Vývoj nových senzorových a mikrosenzorových systémov.</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityE2 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">E.3. Vývoj nových produktov "nositeľnej" elektroniky.</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityE3 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">E.4. Vývoj nových produktov "ambient assistant living”</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityE4 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">E.5. Vývoj produktov a služieb pre monitorovanie vplyvu rizikových faktorov, zložiek životného a pracovného prostredia na zdravie.</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityE5 == true ? "X" : ""}</td>
				</tr>
				`
			}

			printValues["typ_podaktivit"] = podaktivity_table
		} else if (formVals.callCode == "IP_ZS2_123") {
			let podaktivity_table = ""
			if (formVals.eligibleAreaCode == "zs2-123-A") {
				podaktivity_table = `
				<tr>
				<td class="td_50 bg_blue bold">A.1. Vývoj nových produktov a procesov používaných v diagnostike alebo monitoringu stavu ochorenia pre laboratóriá, najmä molekulovú diagnostiku a patológiu, vrátane nových laboratórnych prístrojov.</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityA1 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">A.2. Vývoj nových produktov a procesov používaných na diagnostiku alebo monitorovanie ochorenia s využitím zobrazovacích technológií, vrátane zobrazovacích a optických prístrojov.</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityA2 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">A.3.  Vývoj nových produktov a procesov implementovateľných v prevencii uvedených ochorení.</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityA3 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">A.4. Vývoj nových produktov a procesov implementovateľných v následnej starostlivosti uvedených ochorení.</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityA4 == true ? "X" : ""}</td>
				</tr>
				`
			} else if (formVals.eligibleAreaCode == "zs2-123-B") {
				podaktivity_table = `
				<tr>
				<td class="td_50 bg_blue bold">B.1. Vývoj produktov pre regeneračnú medicínu, vrátane nových línií kmeňových buniek a bunkovej terapie; produkty na regeneráciu chronických rán.</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityB1 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">B.2. Vývoj biokompatibilných materiálov, vrátane zlúčenín špeciálnych kovov a iných typov implantátov.</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityB2 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">B.3. Vývoj biomateriálov pre špecifické ochorenia, vrátane nanomateriálov, zeolitov, stentov a pod.</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityB3 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">B.4. Vývoj dezinfekčných a antibakteriálnych materiálov.</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityB4 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">B.5. Vývoj inteligentných materiálov vrátane textílií.</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityB5 == true ? "X" : ""}</td>
				</tr>
				`
			} else if (formVals.eligibleAreaCode == "zs2-123-C") {
				podaktivity_table = `
				<tr>
				<td class="td_50 bg_blue bold">C.1. Vývoj liekov a liečivých prípravkov pre humánne použitie.</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityC1 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">C.2. Vývoj kozmetických produktov používaných v zdravotnej starostlivosti</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityC2 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">C.3. Vývoj chemických produktov používaných v zdravotnej starostlivosti.</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityC3 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">C.4. Vývoj nových foriem liekov a liečivých prípravkov.</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityC4 == true ? "X" : ""}</td>
				</tr>
				`
			} else if (formVals.eligibleAreaCode == "zs2-123-D") {
				podaktivity_table = `
				<tr>
				<td class="td_50 bg_blue bold">D.1. Vývoj nových typov implantátov, zdravotných pomôcok a zdravotníckej techniky prostredníctvom 3D tlače vrátane personalizovaných implantátov a zdravotných pomôcok.</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityD1 == true ? "X" : ""}</td>
				</tr>
				`
			} else if (formVals.eligibleAreaCode == "zs2-123-E") {
				podaktivity_table = `
				<tr>
				<td class="td_50 bg_blue bold">E.1. Vývoj a pilotná implementácia diagnostických, terapeutických a monitorovacích systémov ochorení a pacienta vrátane prístrojov a technológií pre použitie v domácnosti, v teréne, domovoch sociálnych služieb, na miestach kde sa združuje vyšší počet ľudí a tam kde je dostupnosť štandardnej zdravotnej starostlivosti obmedzená.</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityE1 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">E.2. Vývoj nových senzorových a mikrosenzorových systémov.</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityE2 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">E.3. Vývoj nových produktov "nositeľnej" elektroniky.</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityE3 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">E.4. Vývoj nových produktov "ambient assistant living”</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityE4 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">E.5. Vývoj produktov a služieb pre monitorovanie vplyvu rizikových faktorov, zložiek životného a pracovného prostredia na zdravie.</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityE5 == true ? "X" : ""}</td>
				</tr>
				`
			}

			printValues["typ_podaktivit"] = podaktivity_table
		} else if (formVals.callCode == "IP_SK2_122") {
			let podaktivity_table = ""
			if (formVals.eligibleAreaCode == "spracsurdovyrobsvyspridhod") {
				podaktivity_table = `
				<tr>
				<td class="td_50 bg_blue bold">A.1.Príprava technologického plánu</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityIPSK2A1 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">A.2. mapovanie a implementácia nových postupov a procesov pre zvýšenie efektívnosti spracovania surovín a polotovarov</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityIPSK2A2 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">A.3.  poradenstvo pri príprave plánu zavádzania čistejších výrobných technológií využitím nových technológií a inovácií pri spracovaní surovín a polotovarov</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityIPSK2A3 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">A.4. skúmanie komerčného potenciálu inovatívneho produktu alebo služby</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityIPSK2A4 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">A.5. výskum a vývoj technologických inovácií a výrobných postupov</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityIPSK2A5 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">A.6. výskum a vývoj inovačných konceptov nových high-tech výrobkov</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityIPSK2A6 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">A.7. príprava plánu a realizácia nákupu služieb výskumu a vývoja zameraných na prípravu a/alebo implementáciu inovatívnych riešení</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityIPSK2A7 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">A.8. výskum a vývoj robotizovaných systémov a príslušenstva pre spracovanie surovín a polotovarov</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityIPSK2A8 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">A.9. poradenstvo v oblasti ochrany duševného vlastníctva a transferu technológií</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityIPSK2A9 == true ? "X" : ""}</td>
				</tr>
				`
			} else if (formVals.eligibleAreaCode == "zvysenergefektvhosp") {
				podaktivity_table = `
				<tr>
				<td class="td_50 bg_blue bold">B.1. Poradenstvo spojené s vypracovaním plánu pre implementáciu nových a inovatívnych riešení pre zníženie energetickej náročnosti podnikov</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityIPSK2B1 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">B.2. poradenstvo spojené s vypracovaním návrhov nových riešení pre zvýšenie energetickej efektívnosti podniku, efektívneho uskladňovania a prenosu energie</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityIPSK2B2 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">B.3. výskum a vývoj inteligentných energetických systémov a sietí</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityIPSK2B3 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">B.4. výskum a vývoj v oblasti uskladňovania energie (využívanie alternatívnych energetických nosičov)</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityIPSK2B4 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">B.5. poradenstvo v oblasti ochrany duševného vlastníctva a transferu technológií</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityIPSK2B5 == true ? "X" : ""}</td>
				</tr>
				`
			} else if (formVals.eligibleAreaCode == "systodolazdravlokalpotr") {
				podaktivity_table = `
				<tr>
				<td class="td_50 bg_blue bold">C.1. Poradenstvo spojené s prípravou plánu pre implementovanie riešení v oblasti inovatívnych potravinových systémov</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityIPSK2C1 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">C.2. poradenstvo spojené s prípravou návrhov nových a inovatívnych riešení pre zlepšenie distribúcie potravín s využitím nových technológií a procesov</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityIPSK2C2 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">C.3. skúmanie nových/inovatívnych riešení pre zlepšenie odolnosti, bezpečnosti a ochrany potravinových systémov</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityIPSK2C3 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">C.4. implementovanie nových technológii a postupov pre zlepšenie bezpečnosti a ochrany potravinových systémov</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityIPSK2C4 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">C.5. poradenstvo v oblasti ochrany duševného vlastníctva a transferu technológií</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityIPSK2C5 == true ? "X" : ""}</td>
				</tr>
				`
			}

			printValues["typ_podaktivit"] = podaktivity_table
		} else if (formVals.callCode == "IP_SK3_122") {
			let podaktivity_table = ""
			if (formVals.eligibleAreaCode == "sk3-122-A") {
				podaktivity_table = `
				<tr>
				<td class="td_50 bg_blue bold">A.1. Poradenstvo spojené s vypracovaním plánu pre implementáciu nových a inovatívnych riešení pre zníženie energetickej náročnosti podnikov</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityIPSK3A1 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">A.2. poradenstvo spojené s vypracovaním návrhov nových riešení pre zvýšenie energetickej efektívnosti podniku, efektívneho uskladňovania a prenosu energie</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityIPSK3A2 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">A.3. výskum a vývoj inteligentných energetických systémov a sietí; uskladňovania energie (využívanie alternatívnych energetických nosičov)</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityIPSK3A3 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">A.4. výskum a vývoj v oblasti transformácie energetických systémov - zvyšovanie energetickej efektívnosti prevádzok podnikov prostredníctvom moderných digitálnych nástrojov</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityIPSK3A4 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">A.5. poradenstvo v oblasti ochrany duševného vlastníctva a transferu technológií</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityIPSK3A5 == true ? "X" : ""}</td>
				</tr>
				<tr>
				`
			} else if (formVals.eligibleAreaCode == "sk3-122-B") {
				podaktivity_table = `
				<tr>
				<td class="td_50 bg_blue bold">B.1. Príprava technologického plánu</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityIPSK3B1 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">B.2. mapovanie a implementácia nových postupov a procesov pre zvýšenie efektívnosti spracovania surovín a polotovarov</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityIPSK3B2 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">B.3. poradenstvo pri príprave plánu zavádzania čistejších výrobných technológií využitím nových technológií a inovácií pri spracovaní surovín a polotovarov</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityIPSK3B3 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">B.4. skúmanie komerčného potenciálu inovatívneho produktu alebo služby</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityIPSK3B4 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">B.5. výskum a vývoj technologických inovácií a výrobných postupov; inovačných konceptov nových high-tech výrobkov; robotizovaných systémov a príslušenstva pre spracovanie surovín a polotovarov; inovatívnych technológií a materiálov a ich zavádzanie do výrobných procesov</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityIPSK3B5 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">B.6. príprava plánu a realizácia nákupu služieb výskumu a vývoja zameraných na prípravu a/alebo implementáciu inovatívnych riešení</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityIPSK3B6 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">B.7. poradenstvo v oblasti ochrany duševného vlastníctva a transferu technológií</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.eligibleActivityIPSK3B7 == true ? "X" : ""}</td>
				</tr>
				`
			}

			printValues["typ_podaktivit"] = podaktivity_table
		} else if (formVals.callCode == "IP_SK_121") {
			let activity_types_table = ""
			if (formVals.projectActivityTypeCode == "accinnconssvc") {
				activity_types_table = `
				<tr>
				<td class="td_50 bg_blue bold">A.1. Zhodnotenie inovačného a výskumno-vývojového potenciálu podniku a stanovenie priorít v oblasti výskumu, vývoja a podpory inovácií</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.activityTypeAddAC1 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">A.2. Skúmanie komerčného potenciálu inovatívneho produktu alebo služby</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.activityTypeAddAC2 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">A.3. Príprava nových/inovatívnych riešení</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.activityTypeAddAC3 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">A.4. Poradenstvo v oblasti ochrany duševného vlastníctva a transferu technológií</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.activityTypeAddAC4 == true ? "X" : ""}</td>
				</tr>
				`
			} else {
				activity_types_table = `
				<tr>
				<td class="td_50 bg_blue bold">B.1. Príprava výskumného plánu na mieru potrieb podniku</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.activityTypeAddRE1 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">B.2. Príprava plánu a jeho implementácie na vybudovanie/rozvoj interných výskumno-vývojových kapacít pripravených v budúcnosti vykonávať aplikovaný výskum a experimentálny vývoj v oblasti podnikania žiadateľa</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.activityTypeAddRE2 == true ? "X" : ""}</td>
				</tr>
				<tr>
				<td class="td_50 bg_blue bold">B.3. Príprava plánu a realizácia nákupu služieb výskumu a vývoja zameraných na prípravu a/alebo implementáciu inovatívnych riešení</td>
				<td class="td_50 bg_blueLight center" style="height: 40px;">${formVals.activityTypeAddRE3 == true ? "X" : ""}</td>
				</tr>
				`
			}
			printValues["activity_types_table"] = activity_types_table
		}

		if (formVals.callCode == "IP_ZS_122") {
			printReport("ipZS", printValues)
		} else if (formVals.callCode == "IP_ZS2_123") {
			printReport("ipZS2", printValues)
		} else if (formVals.callCode == "IP_SK2_122") {
			printReport("ipSK2", printValues, null, true)
		} else if (formVals.callCode == "IP_SK3_122") {
			printReport("ipSK3", printValues, null, true)
		} else if (formVals.callCode == "IP_IPCEI_121") {
			printReport("ipcei", printValues, null, true)
		} else if (formVals.callCode == "IP_BBSK_121") {
			printReport("bbsk", printValues)
		} else if (formVals.callCode == "ZSP_123") {
			printReport("zsp", printValues)
		} else {
			printReport("skSK", printValues, null, true)
		}
	}

	@action generateExcel(form) {
		const formVals = form.values()

		let pravnickaOsoba = ""
		if (isNotEmpty(formVals.companyDesc)) {
			let type = GlobalStore.CL["companyType"].find((row) => row._id == formVals.companyDesc)
			if (isSafe(type)) {
				pravnickaOsoba = type.name_ext
			}
		}

		let adresaObec = ""
		if (isNotEmpty(formVals.companyCity)) {
			let address = GlobalStore.CL["city"].find((row) => row._id == formVals.companyCity)
			if (isSafe(address)) {
				adresaObec = address.name_ext
			}
		}

		let adresaKraj = ""
		if (isNotEmpty(formVals.companyRegion)) {
			let address = GlobalStore.CL["region"].find((row) => row._id == formVals.companyRegion)
			if (isSafe(address)) {
				adresaKraj = address.name_ext
			}
		}

		let adresaCorspondingObec = ""
		if (isNotEmpty(formVals.companyCity)) {
			let address = GlobalStore.CL["city"].find((row) => row._id == formVals.companyCorrespondenceCity)
			if (isSafe(address)) {
				adresaCorspondingObec = address.name_ext
			}
		}

		let pravnaForma = ""
		if (isNotEmpty(formVals.companyLegalForm)) {
			let forma = GlobalStore.CL["legalForm"].find((row) => row._id == formVals.companyLegalForm)
			if (isSafe(forma)) {
				pravnaForma = forma.name_ext
			}
		}

		let typOpravnenychAktivit = ""
		if (isNotEmpty(formVals.projectActivityType)) {
			let aktivity = GlobalStore.CL["activityType"].find((row) => row._id == formVals.projectActivityType)
			if (isSafe(aktivity)) {
				typOpravnenychAktivit = aktivity.name_ext
			}
		}

		if (
			(formVals.callCode == "IP_ZS_122" || formVals.callCode == "IP_SK2_122" || formVals.callCode == "IP_SK3_122") &&
			isNotEmpty(formVals.eligibleArea)
		) {
			let aktivity = GlobalStore.CL["activityType"].find((row) => row._id == formVals.eligibleArea)
			if (isSafe(aktivity)) {
				typOpravnenychAktivit = aktivity.name_ext
			}
		}

		let fileName = ""
		if (formVals.callCode == "IP_IPCEI_121") {
			fileName = "08_ZoIP_IPCEI.xlsx"
		} else if (formVals.callCode == "IP_SK2_122") {
			fileName = "08_ZoIP_IP_SK2_122.xlsx"
		} else if (formVals.callCode == "IP_SK3_122") {
			fileName = "08_ZoIP_IP_SK3_122.xlsx"
		} else if (formVals.callCode == "IP_SK_121") {
			fileName = "08_ZoIP_SK.xlsx"
		} else if (formVals.callCode == "IP_ZS_122") {
			fileName = "08_ZoIP_IP_ZS_122.xlsx"
		} else if (formVals.callCode == "IP_ZS2_123") {
			fileName = "08_ZoIP_IP_ZS2_123.xlsx"
		} else if (formVals.callCode == "ZSP_123") {
			fileName = "08_ZoZSP_ZSK_123.xlsx"
		} else {
			fileName = "08_ZoIP_BBSK.xlsx"
		}

		let oblast_poradenstva =
			formVals.ipceiAdvice1 == true
				? "Poradenské služby zamerané na tvorbu dokumentácie pre proces notifikácie IPCEI - Vytvorenie dokumentu Project Portfolio podľa templatu, ktorý je definovaný EK a je súčasťou dokumentácie výzvy IPCEI H2, dostupné na https://www.siea.sk/inovacie/projekty-ipcei/vyzva-ipcei-2020/ a výzvy IPCEI mikrolektronika, dostupné na https://www.siea.sk/inovacie/projekty-ipcei/vyzva-ipcei-2021/ vrátane Funding Gap Questionnaire – template EK."
				: "Poradenské služby zamerané na tvorbu dokumentácie pre proces notifikácie IPCEI – Vybrané jednotlivé kapitoly dokumentu Project Portfolio, alebo Funding Gap Questionnaire podľa výberu a potreby žiadateľa."

		let req = {
			file_name: fileName,
			data: [
				{
					key: "obchodne_meno",
					value: formVals.companyName
				},
				{
					key: "cislo_zoip",
					value: formVals.zoIPNumber
				},
				{
					key: "nazov_projektu",
					value: formVals.projectName
				},
				{
					key: "pravnicka_osoba",
					value: pravnickaOsoba
				},
				{
					key: "adresa_ulica",
					value: formVals.companyStreet
				},
				{
					key: "adresa_popisne_cislo",
					value: formVals.companyStreetNumber
				},
				{
					key: "adresa_obec",
					value: adresaObec
				},
				{
					key: "adresa_psc",
					value: formVals.companyPSC
				},
				{
					key: "adresa_kraj",
					value: adresaKraj
				},
				{
					key: "koresp_adresa_ulica",
					value: isNotEmpty(formVals.companyCorrespondenceStreet)
						? formVals.companyCorrespondenceStreet
						: formVals.companyStreet
				},
				{
					key: "koresp_adresa_popisne_cislo",
					value: isNotEmpty(formVals.companyCorrespondenceStreetNumber)
						? formVals.companyCorrespondenceStreetNumber
						: formVals.companyStreetNumber
				},
				{
					key: "koresp_adresa_obec",
					value: isNotEmpty(adresaCorspondingObec) ? adresaCorspondingObec : adresaObec
				},
				{
					key: "koresp_adresa_psc",
					value: isNotEmpty(formVals.companyCorrespondencePSC) ? formVals.companyCorrespondencePSC : formVals.companyPSC
				},
				{
					key: "pravna_forma",
					value: pravnaForma
				},
				{
					key: "ic_dph",
					value: formVals.icdph
				},
				{
					key: "ico",
					value: formVals.ico
				},
				{
					key: "dic",
					value: formVals.dic
				},
				{
					key: "iban",
					value: formVals.companyIBAN
				},
				{
					key: "kontakt_meno",
					value: formVals.contactPersonName
				},
				{
					key: "email",
					value: formVals.contactPersonMail
				},
				{
					key: "telefon",
					value: formVals.contactPersonPhone
				},
				// {
				// 	key: "nazov_projektu",
				// 	value: formVals.projectName
				// },
				{
					key: "typ_aktivit",
					value: typOpravnenychAktivit
				},
				{
					key: "strucny_popis",
					value: htmlToPlain(formVals.projectDescAndTarget)
				},
				{
					key: "situacia",
					value: htmlToPlain(formVals.projectBasicParameters)
				},
				{
					key: "sposob_realizacie",
					value: htmlToPlain(formVals.projectImplementationMethod)
				},
				{
					key: "dopln_udaje",
					value: htmlToPlain(formVals.projectAdditionalInfo)
				},
				{
					key: "bp_meno",
					value: formVals.potentialSolverName
				},
				{
					key: "bp_ico",
					value: formVals.potentialSolverIco
				},
				{
					key: "bp_dic",
					value: formVals.potentialSolverDic
				},
				{
					key: "bp_icdph",
					value: formVals.potentialSolverIcdph
				},
				{
					key: "celkova_cena",
					value: formVals.potentialSolverCompletePrice
				},
				{
					key: "hodnota_ip",
					value: formVals.potentialSolverVoucherPrice
				},
				{
					key: "spolufinancovanie",
					value: formVals.potentialSolverCofinancedPrice
				},
				{
					key: "oblast_poradenstva",
					value: oblast_poradenstva
				},
				{
					key: "consent_marketing_information",
					value: formVals.sentMarketingDataYes == true ? "ÁNO súhlasím" : "NIE, nesúhlasím"
				},
				{
					key: "miesto_realizacie",
					value: htmlToPlain(formVals.projectRealizationPlace)
				},
				{
					key: "lehota_plnenia",
					value: htmlToPlain(formVals.projectDeadlineCompletion)
				}
			]
		}

		//Statutari
		let statutarData = isEmpty(formVals.applicantChipForm) ? [] : JSON.parse(formVals.applicantChipForm)
		statutarData.map((statutar, index) => {
			req["data"].push({
				key: "statutar" + (index + 1),
				value: statutar.name
			})
			req["data"].push({
				key: "podpise" + (index + 1),
				value: statutar.isStatutar ? "x" : ""
			})
			//podpise1
		})

		if (formVals.callCode == "IP_SK_121") {
			req["data"].push({
				key: "datum_odoslania_ziadosti",
				value: formVals.sent_date ? moment(formVals.sent_date).format("DD.MM.YYYY HH:mm") : ""
			})

			if (formVals.projectActivityTypeCode == "accinnconssvc") {
				req["data"].push({
					key: "podkategoria1",
					value:
						"A.1. Zhodnotenie inovačného a výskumno-vývojového potenciálu podniku a stanovenie priorít v oblasti výskumu, vývoja a podpory inovácií"
				})
				req["data"].push({
					key: "podkategoria1_value",
					value: formVals.activityTypeAddAC1 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria2",
					value: "A.2. Skúmanie komerčného potenciálu inovatívneho produktu alebo služby"
				})
				req["data"].push({
					key: "podkategoria2_value",
					value: formVals.activityTypeAddAC2 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria3",
					value: "A.3. Príprava nových/inovatívnych riešení"
				})
				req["data"].push({
					key: "podkategoria3_value",
					value: formVals.activityTypeAddAC3 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria4",
					value: "A.4. Poradenstvo v oblasti ochrany duševného vlastníctva a transferu technológií"
				})
				req["data"].push({
					key: "podkategoria4_value",
					value: formVals.activityTypeAddAC4 == true ? "X" : ""
				})
			} else {
				req["data"].push({
					key: "podkategoria1",
					value: "B.1. Príprava výskumného plánu na mieru potrieb podniku"
				})
				req["data"].push({
					key: "podkategoria1_value",
					value: formVals.activityTypeAddRE1 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria2",
					value:
						"B.2. Príprava plánu a jeho implementácie na vybudovanie/rozvoj interných výskumno-vývojových kapacít pripravených v budúcnosti vykonávať aplikovaný výskum a experimentálny vývoj v oblasti podnikania žiadateľa"
				})
				req["data"].push({
					key: "podkategoria2_value",
					value: formVals.activityTypeAddRE2 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria3",
					value:
						"B.3. Príprava plánu a realizácia nákupu služieb výskumu a vývoja zameraných na prípravu a/alebo implementáciu inovatívnych riešení"
				})
				req["data"].push({
					key: "podkategoria3_value",
					value: formVals.activityTypeAddRE3 == true ? "X" : ""
				})
			}
		} else if (formVals.callCode == "IP_ZS_122") {
			req["data"].push({
				key: "datum_odoslania_ziadosti",
				value: formVals.sent_date ? moment(formVals.sent_date).format("DD.MM.YYYY HH:mm") : ""
			})
			req["data"].push({
				key: "typ_aktivi",
				value: typOpravnenychAktivit
			})

			if (formVals.eligibleAreaCode == "prjvavonkoneumetakvsroio") {
				req["data"].push({
					key: "podkategoria1",
					value:
						"A.1. Vývoj nových produktov a procesov používaných v diagnostike alebo monitoringu stavu ochorenia pre laboratóriá, najmä molekulovú diagnostiku a patológiu, vrátane nových laboratórnych prístrojov."
				})
				req["data"].push({
					key: "podkategoria1_value",
					value: formVals.eligibleActivityA1 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria2",
					value:
						"A.2. Vývoj nových produktov a procesov používaných na diagnostiku alebo monitorovanie ochorenia s využitím zobrazovacích technológií, vrátane zobrazovacích a optických prístrojov."
				})
				req["data"].push({
					key: "podkategoria2_value",
					value: formVals.eligibleActivityA2 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria3",
					value: "A.3.  Vývoj nových produktov a procesov implementovateľných v prevencii uvedených ochorení."
				})
				req["data"].push({
					key: "podkategoria3_value",
					value: formVals.eligibleActivityA3 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria4",
					value:
						"A.4. Vývoj nových produktov a procesov implementovateľných v následnej starostlivosti uvedených ochorení."
				})
				req["data"].push({
					key: "podkategoria4_value",
					value: formVals.eligibleActivityA4 == true ? "X" : ""
				})
			} else if (formVals.eligibleAreaCode == "matabiomatzdrav") {
				req["data"].push({
					key: "podkategoria1",
					value:
						"B.1. Vývoj produktov pre regeneračnú medicínu, vrátane nových línií kmeňových buniek a bunkovej terapie; produkty na regeneráciu chronických rán."
				})
				req["data"].push({
					key: "podkategoria1_value",
					value: formVals.eligibleActivityB1 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria2",
					value:
						"B.2. Vývoj biokompatibilných materiálov, vrátane zlúčenín špeciálnych kovov a iných typov implantátov."
				})
				req["data"].push({
					key: "podkategoria2_value",
					value: formVals.eligibleActivityB2 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria3",
					value: "B.3. Vývoj biomateriálov pre špecifické ochorenia, vrátane nanomateriálov, zeolitov, stentov a pod."
				})
				req["data"].push({
					key: "podkategoria3_value",
					value: formVals.eligibleActivityB3 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria4",
					value: "B.4. Vývoj dezinfekčných a antibakteriálnych materiálov."
				})
				req["data"].push({
					key: "podkategoria4_value",
					value: formVals.eligibleActivityB4 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria5",
					value: "B.5. Vývoj inteligentných materiálov vrátane textílií."
				})
				req["data"].push({
					key: "podkategoria5_value",
					value: formVals.eligibleActivityB5 == true ? "X" : ""
				})
			} else if (formVals.eligibleAreaCode == "liekyaformy") {
				req["data"].push({
					key: "podkategoria1",
					value: "C.1. Vývoj liekov a liečivých prípravkov pre humánne použitie."
				})
				req["data"].push({
					key: "podkategoria1_value",
					value: formVals.eligibleActivityC1 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria2",
					value: "C.2. Vývoj kozmetických produktov používaných v zdravotnej starostlivosti."
				})
				req["data"].push({
					key: "podkategoria2_value",
					value: formVals.eligibleActivityC2 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria3",
					value: "C.3. Vývoj chemických produktov používaných v zdravotnej starostlivosti."
				})
				req["data"].push({
					key: "podkategoria3_value",
					value: formVals.eligibleActivityC3 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria4",
					value: "C.4. Vývoj nových foriem liekov a liečivých prípravkov."
				})
				req["data"].push({
					key: "podkategoria4_value",
					value: formVals.eligibleActivityC4 == true ? "X" : ""
				})
			} else if (formVals.eligibleAreaCode == "tridtlacabiotlac") {
				req["data"].push({
					key: "podkategoria1",
					value:
						"D.1. Vývoj nových typov implantátov, zdravotných pomôcok a zdravotníckej techniky prostredníctvom 3D tlače vrátane personalizovaných implantátov a zdravotných pomôcok."
				})
				req["data"].push({
					key: "podkategoria1_value",
					value: formVals.eligibleActivityD1 == true ? "X" : ""
				})
			} else if (formVals.eligibleAreaCode == "senzorydiagtechmonzdrav") {
				req["data"].push({
					key: "podkategoria1",
					value: `E.1. Vývoj a pilotná implementácia diagnostických, terapeutických a monitorovacích systémov ochorení a pacienta vrátane prístrojov a technológií pre použitie v domácnosti, v teréne, domovoch sociálnych služieb, na miestach kde sa združuje vyšší počet ľudí a tam kde je dostupnosť štandardnej zdravotnej starostlivosti obmedzená.`
				})
				req["data"].push({
					key: "podkategoria1_value",
					value: formVals.eligibleActivityE1 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria2",
					value: `E.2. Vývoj nových senzorových a mikrosenzorových systémov.`
				})
				req["data"].push({
					key: "podkategoria2_value",
					value: formVals.eligibleActivityE2 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria3",
					value: `E.3. Vývoj nových produktov "nositeľnej" elektroniky.`
				})
				req["data"].push({
					key: "podkategoria3_value",
					value: formVals.eligibleActivityE3 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria4",
					value: `E.4. Vývoj nových produktov "ambient assistant living”.`
				})
				req["data"].push({
					key: "podkategoria4_value",
					value: formVals.eligibleActivityE4 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria5",
					value:
						"E.5. Vývoj produktov a služieb pre monitorovanie vplyvu rizikových faktorov, zložiek životného a pracovného prostredia na zdravie."
				})
				req["data"].push({
					key: "podkategoria5_value",
					value: formVals.eligibleActivityE5 == true ? "X" : ""
				})
			}
		} else if (formVals.callCode == "IP_ZS2_123") {
			req["data"].push({
				key: "datum_odoslania_ziadosti",
				value: formVals.sent_date ? moment(formVals.sent_date).format("DD.MM.YYYY HH:mm") : ""
			})
			req["data"].push({
				key: "typ_aktivi",
				value: typOpravnenychAktivit
			})

			if (formVals.eligibleAreaCode == "zs2-123-A") {
				req["data"].push({
					key: "podkategoria1",
					value:
						"A.1. Vývoj nových produktov a procesov používaných v diagnostike alebo monitoringu stavu ochorenia pre laboratóriá, najmä molekulovú diagnostiku a patológiu, vrátane nových laboratórnych prístrojov."
				})
				req["data"].push({
					key: "podkategoria1_value",
					value: formVals.eligibleActivityA1 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria2",
					value:
						"A.2. Vývoj nových produktov a procesov používaných na diagnostiku alebo monitorovanie ochorenia s využitím zobrazovacích technológií, vrátane zobrazovacích a optických prístrojov."
				})
				req["data"].push({
					key: "podkategoria2_value",
					value: formVals.eligibleActivityA2 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria3",
					value: "A.3.  Vývoj nových produktov a procesov implementovateľných v prevencii uvedených ochorení."
				})
				req["data"].push({
					key: "podkategoria3_value",
					value: formVals.eligibleActivityA3 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria4",
					value:
						"A.4. Vývoj nových produktov a procesov implementovateľných v následnej starostlivosti uvedených ochorení."
				})
				req["data"].push({
					key: "podkategoria4_value",
					value: formVals.eligibleActivityA4 == true ? "X" : ""
				})
			} else if (formVals.eligibleAreaCode == "zs2-123-B") {
				req["data"].push({
					key: "podkategoria1",
					value:
						"B.1. Vývoj produktov pre regeneračnú medicínu, vrátane nových línií kmeňových buniek a bunkovej terapie; produkty na regeneráciu chronických rán."
				})
				req["data"].push({
					key: "podkategoria1_value",
					value: formVals.eligibleActivityB1 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria2",
					value:
						"B.2. Vývoj biokompatibilných materiálov, vrátane zlúčenín špeciálnych kovov a iných typov implantátov."
				})
				req["data"].push({
					key: "podkategoria2_value",
					value: formVals.eligibleActivityB2 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria3",
					value: "B.3. Vývoj biomateriálov pre špecifické ochorenia, vrátane nanomateriálov, zeolitov, stentov a pod."
				})
				req["data"].push({
					key: "podkategoria3_value",
					value: formVals.eligibleActivityB3 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria4",
					value: "B.4. Vývoj dezinfekčných a antibakteriálnych materiálov."
				})
				req["data"].push({
					key: "podkategoria4_value",
					value: formVals.eligibleActivityB4 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria5",
					value: "B.5. Vývoj inteligentných materiálov vrátane textílií."
				})
				req["data"].push({
					key: "podkategoria5_value",
					value: formVals.eligibleActivityB5 == true ? "X" : ""
				})
			} else if (formVals.eligibleAreaCode == "zs2-123-C") {
				req["data"].push({
					key: "podkategoria1",
					value: "C.1. Vývoj liekov a liečivých prípravkov pre humánne použitie."
				})
				req["data"].push({
					key: "podkategoria1_value",
					value: formVals.eligibleActivityC1 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria2",
					value: "C.2. Vývoj kozmetických produktov používaných v zdravotnej starostlivosti."
				})
				req["data"].push({
					key: "podkategoria2_value",
					value: formVals.eligibleActivityC2 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria3",
					value: "C.3. Vývoj chemických produktov používaných v zdravotnej starostlivosti."
				})
				req["data"].push({
					key: "podkategoria3_value",
					value: formVals.eligibleActivityC3 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria4",
					value: "C.4. Vývoj nových foriem liekov a liečivých prípravkov."
				})
				req["data"].push({
					key: "podkategoria4_value",
					value: formVals.eligibleActivityC4 == true ? "X" : ""
				})
			} else if (formVals.eligibleAreaCode == "zs2-123-D") {
				req["data"].push({
					key: "podkategoria1",
					value:
						"D.1. Vývoj nových typov implantátov, zdravotných pomôcok a zdravotníckej techniky prostredníctvom 3D tlače vrátane personalizovaných implantátov a zdravotných pomôcok."
				})
				req["data"].push({
					key: "podkategoria1_value",
					value: formVals.eligibleActivityD1 == true ? "X" : ""
				})
			} else if (formVals.eligibleAreaCode == "zs2-123-E") {
				req["data"].push({
					key: "podkategoria1",
					value: `E.1. Vývoj a pilotná implementácia diagnostických, terapeutických a monitorovacích systémov ochorení a pacienta vrátane prístrojov a technológií pre použitie v domácnosti, v teréne, domovoch sociálnych služieb, na miestach kde sa združuje vyšší počet ľudí a tam kde je dostupnosť štandardnej zdravotnej starostlivosti obmedzená.`
				})
				req["data"].push({
					key: "podkategoria1_value",
					value: formVals.eligibleActivityE1 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria2",
					value: `E.2. Vývoj nových senzorových a mikrosenzorových systémov.`
				})
				req["data"].push({
					key: "podkategoria2_value",
					value: formVals.eligibleActivityE2 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria3",
					value: `E.3. Vývoj nových produktov "nositeľnej" elektroniky.`
				})
				req["data"].push({
					key: "podkategoria3_value",
					value: formVals.eligibleActivityE3 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria4",
					value: `E.4. Vývoj nových produktov "ambient assistant living”.`
				})
				req["data"].push({
					key: "podkategoria4_value",
					value: formVals.eligibleActivityE4 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria5",
					value:
						"E.5. Vývoj produktov a služieb pre monitorovanie vplyvu rizikových faktorov, zložiek životného a pracovného prostredia na zdravie."
				})
				req["data"].push({
					key: "podkategoria5_value",
					value: formVals.eligibleActivityE5 == true ? "X" : ""
				})
			}
		} else if (formVals.callCode == "IP_SK2_122") {
			req["data"].push({
				key: "datum_odoslania_ziadosti",
				value: formVals.sent_date ? moment(formVals.sent_date).format("DD.MM.YYYY HH:mm") : ""
			})
			req["data"].push({
				key: "typ_aktivi",
				value: typOpravnenychAktivit
			})

			if (formVals.eligibleAreaCode == "spracsurdovyrobsvyspridhod") {
				req["data"].push({
					key: "podkategoria1",
					value: "A.1.Príprava technologického plánu"
				})
				req["data"].push({
					key: "podkategoria1_value",
					value: formVals.eligibleActivityIPSK2A1 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria2",
					value:
						"A.2. mapovanie a implementácia nových postupov a procesov pre zvýšenie efektívnosti spracovania surovín a polotovarov"
				})
				req["data"].push({
					key: "podkategoria2_value",
					value: formVals.eligibleActivityIPSK2A2 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria3",
					value:
						"A.3.  poradenstvo pri príprave plánu zavádzania čistejších výrobných technológií využitím nových technológií a inovácií pri spracovaní surovín a polotovarov"
				})
				req["data"].push({
					key: "podkategoria3_value",
					value: formVals.eligibleActivityIPSK2A3 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria4",
					value: "A.4. skúmanie komerčného potenciálu inovatívneho produktu alebo služby"
				})
				req["data"].push({
					key: "podkategoria4_value",
					value: formVals.eligibleActivityIPSK2A4 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria5",
					value: "A.5. výskum a vývoj technologických inovácií a výrobných postupov"
				})
				req["data"].push({
					key: "podkategoria5_value",
					value: formVals.eligibleActivityIPSK2A5 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria6",
					value: "A.6. výskum a vývoj inovačných konceptov nových high-tech výrobkov"
				})
				req["data"].push({
					key: "podkategoria6_value",
					value: formVals.eligibleActivityIPSK2A6 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria7",
					value:
						"A.7. príprava plánu a realizácia nákupu služieb výskumu a vývoja zameraných na prípravu a/alebo implementáciu inovatívnych riešení"
				})
				req["data"].push({
					key: "podkategoria7_value",
					value: formVals.eligibleActivityIPSK2A7 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria8",
					value: "A.8. výskum a vývoj robotizovaných systémov a príslušenstva pre spracovanie surovín a polotovarov"
				})
				req["data"].push({
					key: "podkategoria8_value",
					value: formVals.eligibleActivityIPSK2A8 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria9",
					value: "A.9. poradenstvo v oblasti ochrany duševného vlastníctva a transferu technológií"
				})
				req["data"].push({
					key: "podkategoria9_value",
					value: formVals.eligibleActivityIPSK2A9 == true ? "X" : ""
				})
			} else if (formVals.eligibleAreaCode == "zvysenergefektvhosp") {
				req["data"].push({
					key: "podkategoria1",
					value:
						"B.1. Poradenstvo spojené s vypracovaním plánu pre implementáciu nových a inovatívnych riešení pre zníženie energetickej náročnosti podnikov"
				})
				req["data"].push({
					key: "podkategoria1_value",
					value: formVals.eligibleActivityIPSK2B1 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria2",
					value:
						"B.2. poradenstvo spojené s vypracovaním návrhov nových riešení pre zvýšenie energetickej efektívnosti podniku, efektívneho uskladňovania a prenosu energie"
				})
				req["data"].push({
					key: "podkategoria2_value",
					value: formVals.eligibleActivityIPSK2B2 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria3",
					value: "B.3. výskum a vývoj inteligentných energetických systémov a sietí"
				})
				req["data"].push({
					key: "podkategoria3_value",
					value: formVals.eligibleActivityIPSK2B3 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria4",
					value: "B.4. výskum a vývoj v oblasti uskladňovania energie (využívanie alternatívnych energetických nosičov)"
				})
				req["data"].push({
					key: "podkategoria4_value",
					value: formVals.eligibleActivityIPSK2B4 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria5",
					value: "B.5. poradenstvo v oblasti ochrany duševného vlastníctva a transferu technológií"
				})
				req["data"].push({
					key: "podkategoria5_value",
					value: formVals.eligibleActivityIPSK2B5 == true ? "X" : ""
				})
			} else if (formVals.eligibleAreaCode == "systodolazdravlokalpotr") {
				req["data"].push({
					key: "podkategoria1",
					value:
						"C.1. Poradenstvo spojené s prípravou plánu pre implementovanie riešení v oblasti inovatívnych potravinových systémov"
				})
				req["data"].push({
					key: "podkategoria1_value",
					value: formVals.eligibleActivityIPSK2C1 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria2",
					value:
						"C.2. poradenstvo spojené s prípravou návrhov nových a inovatívnych riešení pre zlepšenie distribúcie potravín s využitím nových technológií a procesov"
				})
				req["data"].push({
					key: "podkategoria2_value",
					value: formVals.eligibleActivityIPSK2C2 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria3",
					value:
						"C.3. skúmanie nových/inovatívnych riešení pre zlepšenie odolnosti, bezpečnosti a ochrany potravinových systémov"
				})
				req["data"].push({
					key: "podkategoria3_value",
					value: formVals.eligibleActivityIPSK2C3 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria4",
					value:
						"C.4. implementovanie nových technológii a postupov pre zlepšenie bezpečnosti a ochrany potravinových systémov"
				})
				req["data"].push({
					key: "podkategoria4_value",
					value: formVals.eligibleActivityIPSK2C4 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria5",
					value: "C.5. poradenstvo v oblasti ochrany duševného vlastníctva a transferu technológií"
				})
				req["data"].push({
					key: "podkategoria5_value",
					value: formVals.eligibleActivityIPSK2C5 == true ? "X" : ""
				})
			}
		} else if (formVals.callCode == "IP_SK3_122") {
			req["data"].push({
				key: "datum_odoslania_ziadosti",
				value: formVals.sent_date ? moment(formVals.sent_date).format("DD.MM.YYYY HH:mm") : ""
			})
			req["data"].push({
				key: "typ_aktivi",
				value: typOpravnenychAktivit
			})

			if (formVals.eligibleAreaCode == "sk3-122-A") {
				req["data"].push({
					key: "podkategoria1",
					value:
						"A.1. Poradenstvo spojené s vypracovaním plánu pre implementáciu nových a inovatívnych riešení pre zníženie energetickej náročnosti podnikov"
				})
				req["data"].push({
					key: "podkategoria1_value",
					value: formVals.eligibleActivityIPSK3A1 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria2",
					value:
						"A.2. poradenstvo spojené s vypracovaním návrhov nových riešení pre zvýšenie energetickej efektívnosti podniku, efektívneho uskladňovania a prenosu energie"
				})
				req["data"].push({
					key: "podkategoria2_value",
					value: formVals.eligibleActivityIPSK3A2 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria3",
					value:
						"A.3. výskum a vývoj inteligentných energetických systémov a sietí; uskladňovania energie (využívanie alternatívnych energetických nosičov)"
				})
				req["data"].push({
					key: "podkategoria3_value",
					value: formVals.eligibleActivityIPSK3A3 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria4",
					value:
						"A.4. výskum a vývoj v oblasti transformácie energetických systémov - zvyšovanie energetickej efektívnosti prevádzok podnikov prostredníctvom moderných digitálnych nástrojov"
				})
				req["data"].push({
					key: "podkategoria4_value",
					value: formVals.eligibleActivityIPSK3A4 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria5",
					value: "A.5. poradenstvo v oblasti ochrany duševného vlastníctva a transferu technológií"
				})
				req["data"].push({
					key: "podkategoria5_value",
					value: formVals.eligibleActivityIPSK3A5 == true ? "X" : ""
				})
			} else if (formVals.eligibleAreaCode == "sk3-122-B") {
				req["data"].push({
					key: "podkategoria1",
					value: "B.1. Príprava technologického plánu"
				})
				req["data"].push({
					key: "podkategoria1_value",
					value: formVals.eligibleActivityIPSK3B1 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria2",
					value:
						"B.2. mapovanie a implementácia nových postupov a procesov pre zvýšenie efektívnosti spracovania surovín a polotovarov"
				})
				req["data"].push({
					key: "podkategoria2_value",
					value: formVals.eligibleActivityIPSK3B2 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria3",
					value:
						"B.3. poradenstvo pri príprave plánu zavádzania čistejších výrobných technológií využitím nových technológií a inovácií pri spracovaní surovín a polotovarov"
				})
				req["data"].push({
					key: "podkategoria3_value",
					value: formVals.eligibleActivityIPSK3B3 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria4",
					value: "B.4. skúmanie komerčného potenciálu inovatívneho produktu alebo služby"
				})
				req["data"].push({
					key: "podkategoria4_value",
					value: formVals.eligibleActivityIPSK3B4 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria5",
					value:
						"B.5. výskum a vývoj technologických inovácií a výrobných postupov; inovačných konceptov nových high-tech výrobkov; robotizovaných systémov a príslušenstva pre spracovanie surovín a polotovarov; inovatívnych technológií a materiálov a ich zavádzanie do výrobných procesov"
				})
				req["data"].push({
					key: "podkategoria5_value",
					value: formVals.eligibleActivityIPSK3B5 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria6",
					value:
						"B.6. príprava plánu a realizácia nákupu služieb výskumu a vývoja zameraných na prípravu a/alebo implementáciu inovatívnych riešení"
				})
				req["data"].push({
					key: "podkategoria6_value",
					value: formVals.eligibleActivityIPSK3B6 == true ? "X" : ""
				})
				req["data"].push({
					key: "podkategoria7",
					value: "B.7. poradenstvo v oblasti ochrany duševného vlastníctva a transferu technológií"
				})
				req["data"].push({
					key: "podkategoria7_value",
					value: formVals.eligibleActivityIPSK3B7 == true ? "X" : ""
				})
			}
		} else {
			req["data"].push({
				key: "datum_odoslania_ziadosti",
				value: formVals.sent_date ? moment(formVals.sent_date).format("DD.MM.YYYY HH:mm") : ""
			})
		}

		api
			.exportToXLS(req)
			.call()
			.then((res) => {
				if (res.ok == true) {
					let fileName = formVals.zoIPNumber ? formVals.zoIPNumber : "export"
					// isSafe(res.headers.get("content-disposition"))
					// 	? res.headers.get("content-disposition").split("filename=")[1]
					// 	:
					res.blob().then((blob) => {
						let FileSaver = require("file-saver") // eslint-disable-line no-undef
						FileSaver.saveAs(blob, fileName)
					})
				}
			})
	}
}

var singleton = new RequestPrintStore()
export default singleton

