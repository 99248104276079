// import {FormattedMessage} from "react-intl"
import React from "react"
// import {/*getPerson, */ getUser} from "../../global/helpers/actions"
// import moment from "moment"

export default {
	load() {
		return {
			fields: {
				status: {},
				version: {},
				request_id: {},
				callId: {},
				callCode: {},
				zoIPNumber: {},
				sent_date: {},
				ico: {
					label: "IČO",
					rules: "required|between:8,8"
				},
				dic: {
					label: "DIČ",
					rules: "required|between:10,10"
				},
				icdph: {
					label: "IČ DPH",
					rules: ["required", "regex:/^(?=[aA-zZ0-9]*$)(?:.{1}|.{12})$/"]
				},
				companyId: {},
				companyName: {
					label: "Obchodné meno",
					rules: "required"
				},
				companyDesc: {
					label: "Základná charakteristika žiadateľa",
					rules: "required"
				},
				companyLegalForm: {
					label: "Právna forma",
					rules: "required"
				},
				companyIBAN: {
					label: "IBAN Žiadateľa na preplatenie Inovačnej poukážky",
					rules: "required",
					value: "SK"
				},
				companyRegion: {
					label: "Kraj",
					rules: "required"
				},
				// companyCounty: {
				// 	label: "County",
				// 	rules: "required"
				// },
				companyStreet: {
					label: "Ulica",
					rules: "required"
				},
				companyStreetNumber: {
					label: " Popisné číslo",
					rules: "required"
				},
				companyCity: {
					label: "Obec",
					rules: "required"
				},
				companyPSC: {
					label: "PSČ",
					rules: "required|between:5,5"
				},
				companyCorrespondenceRegion: {
					label: "Kraj"
				},
				companyCorrespondenceStreet: {
					label: "Ulica"
				},
				companyCorrespondenceCity: {
					label: "Obec"
				},
				companyCorrespondenceStreetNumber: {
					label: " Popisné číslo"
				},
				companyCorrespondencePSC: {
					label: "PSČ",
					rules: "between:5,5"
				},
				companyAddressSameYes: {
					label: "Áno",
					type: "checkbox",
					value: true
				},
				companyAddressSameNo: {
					label: "Nie",
					type: "checkbox",
					value: false
				},
				applicantChipForm: {value: JSON.stringify([{name: "", isStatutar: false}])},
				contactPersonName: {
					label: "Titul, Meno, Priezvisko, Titul",
					rules: "required"
				},
				contactPersonPhone: {
					label: "Telefónne číslo",
					rules: "required"
				},
				contactPersonMail: {
					label: "Email",
					rules: "email|required"
				},
				contactPersonId: {},
				projectName: {
					label: "Názov inovačného projektu",
					rules: "required"
				},
				projectActivityType: {
					label: "Typ oprávnených aktivít IP",
					rules: "required"
				},
				projectActivityTypeCode: {},
				activityTypeAddAC1: {
					type: "checkbox",
					value: false,
					label:
						"A.1. Zhodnotenie inovačného a výskumno-vývojového potenciálu podniku a stanovenie priorít v oblasti výskumu, vývoja a podpory inovácií."
				},
				activityTypeAddAC2: {
					type: "checkbox",
					value: false,
					label: "A.2. Skúmanie komerčného potenciálu inovatívneho produktu alebo služby."
				},
				activityTypeAddAC3: {
					type: "checkbox",
					value: false,
					label: "A.3. Príprava nových/inovatívnych riešení."
				},
				activityTypeAddAC4: {
					type: "checkbox",
					value: false,
					label: "A.4. Poradenstvo v oblasti ochrany duševného vlastníctva a transferu technológií."
				},
				activityTypeAddRE1: {
					type: "checkbox",
					value: false,
					label: "B.1. Príprava výskumného plánu na mieru potrieb podniku."
				},
				activityTypeAddRE2: {
					type: "checkbox",
					value: false,
					label:
						"B.2. Príprava plánu a jeho implementácie na vybudovanie/rozvoj interných výskumno-vývojových kapacít pripravených v budúcnosti vykonávať aplikovaný výskum a experimentálny vývoj v oblasti podnikania žiadateľa."
				},
				activityTypeAddRE3: {
					type: "checkbox",
					value: false,
					label:
						"B.3. Príprava plánu a realizácia nákupu služieb výskumu a vývoja zameraných na prípravu a/alebo implementáciu inovatívnych riešení."
				},
				projectDescAndTarget: {
					label: "Stručný popis projektu a ciele riešenia",
					rules: "required"
				},
				projectBasicParameters: {
					label: "Východisková situácia, základné parametre riešenia a popis inovatívneho produktu/služby",
					rules: "required"
				},
				projectImplementationMethod: {
					label: "Spôsob realizácie aktivít projektu, situácia po realizácii projektu a udržateľnosť projektu",
					rules: "required"
				},
				projectAdditionalInfo: {
					label: "Doplňujúce údaje",
					rules: "required"
				},
				projectRealizationPlace: {
					label: "Miesto realizácie inovačného projektu "
					// rules: "required"
				},
				projectDeadlineCompletion: {
					label: "Lehota plnenia inovačného projektu"
					// rules: "required"
				},
				potentialSolverName: {
					label: "Obchodné meno",
					rules: "required"
				},
				potentialSolverIco: {
					label: "IČO",
					rules: "required|between:8,8"
				},
				potentialSolverDic: {
					label: "DIČ",
					rules: "required|between:10,10"
				},
				potentialSolverIcdph: {
					label: "IČ DPH",
					rules: ["required", "regex:/^(?=[aA-zZ0-9]*$)(?:.{1}|.{12})$/"]
				},
				potentialSolverCompletePrice: {
					label: (
						<span>
							Celková cena Inovačného projektu podľa cenového prieskumu <sup>1</sup>
						</span>
					),
					rules: "required"
				},
				potentialSolverVoucherPrice: {
					label: (
						<span>
							Výška Inovačnej poukážky <sup>2</sup>
						</span>
					),
					rules: "required"
				},
				potentialSolverCofinancedPrice: {
					label: (
						<span>
							Výška spolufinancovania z vlastných zdrojov Prijímateľa v EUR <sup>3</sup>
						</span>
					),
					rules: "required"
				},

				confirmCheckbox12mothsPassed: {
					label: "ÁNO, vyhlasujem/e",
					type: "checkbox",
					value: false
				},
				confirmCheckboxAddress: {
					label: "ÁNO, vyhlasujem/e",
					type: "checkbox",
					value: false
				},
				confirmCheckboxAllCorrect: {
					label: "ÁNO, vyhlasujem/e",
					type: "checkbox",
					value: false
				},
				confirmCheckboxConfirmConsulting: {
					label: "ÁNO, vyhlasujem/e",
					type: "checkbox",
					value: false
				},
				confirmCheckboxIP2SK5: {
					label: "ÁNO, vyhlasujem/e",
					type: "checkbox",
					value: false
				},
				ipceiAdvice1: {
					label: (
						<span>
							1. Poradenské služby zamerané na tvorbu dokumentácie pre proces notifikácie IPCEI - Vytvorenie dokumentu
							Project Portfolio podľa templatu, ktorý je definovaný EK a je súčasťou dokumentácie výzvy IPCEI H2,
							dostupné na https://www.siea.sk/inovacie/projekty-ipcei/vyzva-ipcei-2020/ a výzvy IPCEI mikroelektronika,
							dostupné na https://www.siea.sk/inovacie/projekty-ipcei/vyzva-ipcei-2021/ vrátane Funding Gap
							Questionnaire - template EK.
						</span>
					),
					type: "checkbox",
					value: true
				},
				ipceiAdvice2: {
					label: (
						<span>
							2. Poradenské služby zamerané na tvorbu dokumentácie pre proces notifikácie IPCEI – Vybrané jednotlivé
							kapitoly dokumentu Project Portfolio, alebo Funding Gap Questionnaire podľa výberu a potreby žiadateľa.
						</span>
					),
					type: "checkbox",
					value: false
				},
				callValidity: {},
				dropzone: {},
				skDomain: {
					label: "Doména SK",
					value:
						"Zdravá spoločnosť - 4-2 Inovatívne produkty (vrátane (bio)materiálov a biotechnológií), procesy a postupy v zdravotníctve"
				},
				// skDomainSK2: {
				// 	label: "Doména SK RIS3 2021+",
				// 	value: "Inovatívny priemysel pre 21. storočie alebo Zdravé potraviny a životné prostredie"
				// },
				eligibleArea: {
					label: "Oprávnená oblasť"
				},
				eligibleAreaCode: {},
				eligibleActivityA1: {
					type: "checkbox",
					value: false,
					label:
						"A.1. Vývoj nových produktov a procesov používaných v diagnostike alebo monitoringu stavu ochorenia pre laboratóriá, najmä molekulovú diagnostiku a patológiu, vrátane nových laboratórnych prístrojov."
				},
				eligibleActivityA2: {
					type: "checkbox",
					value: false,
					label:
						"A.2. Vývoj nových produktov a procesov používaných na diagnostiku alebo monitorovanie ochorenia s využitím zobrazovacích technológií, vrátane zobrazovacích a optických prístrojov."
				},
				eligibleActivityA3: {
					type: "checkbox",
					value: false,
					label: "A.3.  Vývoj nových produktov a procesov implementovateľných v prevencii uvedených ochorení."
				},
				eligibleActivityA4: {
					type: "checkbox",
					value: false,
					label:
						"A.4. Vývoj nových produktov a procesov implementovateľných v následnej starostlivosti uvedených ochorení. "
				},
				eligibleActivityB1: {
					type: "checkbox",
					value: false,
					label:
						"B.1. Vývoj produktov pre regeneračnú medicínu, vrátane nových línií kmeňových buniek a bunkovej terapie; produkty na regeneráciu chronických rán."
				},
				eligibleActivityB2: {
					type: "checkbox",
					value: false,
					label:
						"B.2. Vývoj biokompatibilných materiálov, vrátane zlúčenín špeciálnych kovov a iných typov implantátov."
				},
				eligibleActivityB3: {
					type: "checkbox",
					value: false,
					label: "B.3. Vývoj biomateriálov pre špecifické ochorenia, vrátane nanomateriálov, zeolitov, stentov a pod."
				},
				eligibleActivityB4: {
					type: "checkbox",
					value: false,
					label: "B.4. Vývoj dezinfekčných a antibakteriálnych materiálov."
				},
				eligibleActivityB5: {
					type: "checkbox",
					value: false,
					label: "B.5. Vývoj inteligentných materiálov vrátane textílií."
				},
				eligibleActivityC1: {
					type: "checkbox",
					value: false,
					label: "C.1. Vývoj liekov a liečivých prípravkov pre humánne použitie."
				},
				eligibleActivityC2: {
					type: "checkbox",
					value: false,
					label: "C.2. Vývoj kozmetických produktov používaných v zdravotnej starostlivosti"
				},
				eligibleActivityC3: {
					type: "checkbox",
					value: false,
					label: "C.3. Vývoj chemických produktov používaných v zdravotnej starostlivosti."
				},
				eligibleActivityC4: {
					type: "checkbox",
					value: false,
					label: "C.4. Vývoj nových foriem liekov a liečivých prípravkov."
				},
				eligibleActivityD1: {
					type: "checkbox",
					value: false,
					label:
						"D.1. Vývoj nových typov implantátov, zdravotných pomôcok a zdravotníckej techniky prostredníctvom 3D tlače vrátane personalizovaných implantátov a zdravotných pomôcok."
				},
				eligibleActivityE1: {
					type: "checkbox",
					value: false,
					label:
						"E.1. Vývoj a pilotná implementácia diagnostických, terapeutických a monitorovacích systémov ochorení a pacienta vrátane prístrojov a technológií pre použitie v domácnosti, v teréne, domovoch sociálnych služieb, na miestach kde sa združuje vyšší počet ľudí a tam kde je dostupnosť štandardnej zdravotnej starostlivosti obmedzená."
				},
				eligibleActivityE2: {
					type: "checkbox",
					value: false,
					label: "E.2. Vývoj nových senzorových a mikrosenzorových systémov."
				},
				eligibleActivityE3: {
					type: "checkbox",
					value: false,
					label: `E.3. Vývoj nových produktov "nositeľnej" elektroniky.`
				},
				eligibleActivityE4: {
					type: "checkbox",
					value: false,
					label: `E.4. Vývoj nových produktov "ambient assistant living”`
				},
				eligibleActivityE5: {
					type: "checkbox",
					value: false,
					label:
						"E.5. Vývoj produktov a služieb pre monitorovanie vplyvu rizikových faktorov, zložiek životného a pracovného prostredia na zdravie."
				},
				eligibleActivityIPSK2A1: {
					type: "checkbox",
					value: false,
					label: "A.1.Príprava technologického plánu"
				},
				eligibleActivityIPSK2A2: {
					type: "checkbox",
					value: false,
					label:
						"A.2. mapovanie a implementácia nových postupov a procesov pre zvýšenie efektívnosti spracovania surovín a polotovarov"
				},
				eligibleActivityIPSK2A3: {
					type: "checkbox",
					value: false,
					label:
						"A.3.  poradenstvo pri príprave plánu zavádzania čistejších výrobných technológií využitím nových technológií a inovácií pri spracovaní surovín a polotovarov"
				},
				eligibleActivityIPSK2A4: {
					type: "checkbox",
					value: false,
					label: "A.4. skúmanie komerčného potenciálu inovatívneho produktu alebo služby"
				},
				eligibleActivityIPSK2A5: {
					type: "checkbox",
					value: false,
					label: "A.5. výskum a vývoj technologických inovácií a výrobných postupov"
				},
				eligibleActivityIPSK2A6: {
					type: "checkbox",
					value: false,
					label: "A.6. výskum a vývoj inovačných konceptov nových high-tech výrobkov"
				},
				eligibleActivityIPSK2A7: {
					type: "checkbox",
					value: false,
					label:
						"A.7. príprava plánu a realizácia nákupu služieb výskumu a vývoja zameraných na prípravu a/alebo implementáciu inovatívnych riešení"
				},
				eligibleActivityIPSK2A8: {
					type: "checkbox",
					value: false,
					label: "A.8. výskum a vývoj robotizovaných systémov a príslušenstva pre spracovanie surovín a polotovarov"
				},
				eligibleActivityIPSK2A9: {
					type: "checkbox",
					value: false,
					label: "A.9. poradenstvo v oblasti ochrany duševného vlastníctva a transferu technológií"
				},
				eligibleActivityIPSK2B1: {
					type: "checkbox",
					value: false,
					label:
						"B.1. Poradenstvo spojené s vypracovaním plánu pre implementáciu nových a inovatívnych riešení pre zníženie energetickej náročnosti podnikov"
				},
				eligibleActivityIPSK2B2: {
					type: "checkbox",
					value: false,
					label:
						"B.2. poradenstvo spojené s vypracovaním návrhov nových riešení pre zvýšenie energetickej efektívnosti podniku, efektívneho uskladňovania a prenosu energie"
				},
				eligibleActivityIPSK2B3: {
					type: "checkbox",
					value: false,
					label: "B.3. výskum a vývoj inteligentných energetických systémov a sietí"
				},
				eligibleActivityIPSK2B4: {
					type: "checkbox",
					value: false,
					label: "B.4. výskum a vývoj v oblasti uskladňovania energie (využívanie alternatívnych energetických nosičov)"
				},
				eligibleActivityIPSK2B5: {
					type: "checkbox",
					value: false,
					label: "B.5. poradenstvo v oblasti ochrany duševného vlastníctva a transferu technológií"
				},
				eligibleActivityIPSK2C1: {
					type: "checkbox",
					value: false,
					label:
						"C.1. Poradenstvo spojené s prípravou plánu pre implementovanie riešení v oblasti inovatívnych potravinových systémov"
				},
				eligibleActivityIPSK2C2: {
					type: "checkbox",
					value: false,
					label:
						"C.2. poradenstvo spojené s prípravou návrhov nových a inovatívnych riešení pre zlepšenie distribúcie potravín s využitím nových technológií a procesov"
				},
				eligibleActivityIPSK2C3: {
					type: "checkbox",
					value: false,
					label:
						"C.3. skúmanie nových/inovatívnych riešení pre zlepšenie odolnosti, bezpečnosti a ochrany potravinových systémov"
				},
				eligibleActivityIPSK2C4: {
					type: "checkbox",
					value: false,
					label:
						"C.4. implementovanie nových technológii a postupov pre zlepšenie bezpečnosti a ochrany potravinových systémov"
				},
				eligibleActivityIPSK2C5: {
					type: "checkbox",
					value: false,
					label: "C.5. poradenstvo v oblasti ochrany duševného vlastníctva a transferu technológií"
				},

				eligibleActivityIPSK3A1: {
					type: "checkbox",
					value: false,
					label:
						"A.1. Poradenstvo spojené s vypracovaním plánu pre implementáciu nových a inovatívnych riešení v procesoch hospodárskej činnosti podniku, ktoré zároveň prispejú k zníženiu energetickej náročnosti podnikov"
				},
				eligibleActivityIPSK3A2: {
					type: "checkbox",
					value: false,
					label:
						"A.2. poradenstvo spojené s vypracovaním návrhov nových riešení v rámci procesov hospodárskej činnosti podniku s cieľom  zvýšenia jeho energetickej efektívnosti, ako aj efektívne uskladňovanie a prenos energie"
				},
				eligibleActivityIPSK3A3: {
					type: "checkbox",
					value: false,
					label:
						"A.3. výskum a vývoj inteligentných energetických systémov a sietí; uskladňovania energie (využívanie alternatívnych energetických nosičov)"
				},
				eligibleActivityIPSK3A4: {
					type: "checkbox",
					value: false,
					label:
						"A.4. výskum a vývoj v oblasti transformácie energetických systémov - zvyšovanie energetickej efektívnosti prevádzok podnikov prostredníctvom moderných digitálnych nástrojov"
				},
				eligibleActivityIPSK3A5: {
					type: "checkbox",
					value: false,
					label: "A.5. poradenstvo v oblasti ochrany duševného vlastníctva a transferu technológií"
				},
				// eligibleActivityIPSK3A6: {
				// 	type: "checkbox",
				// 	value: false,
				// 	label: "A.6. výskum a vývoj inovačných konceptov nových high-tech výrobkov"
				// },
				// eligibleActivityIPSK3A7: {
				// 	type: "checkbox",
				// 	value: false,
				// 	label:
				// 		"A.7. príprava plánu a realizácia nákupu služieb výskumu a vývoja zameraných na prípravu a/alebo implementáciu inovatívnych riešení"
				// },
				// eligibleActivityIPSK3A8: {
				// 	type: "checkbox",
				// 	value: false,
				// 	label: "A.8. výskum a vývoj robotizovaných systémov a príslušenstva pre spracovanie surovín a polotovarov"
				// },
				// eligibleActivityIPSK3A9: {
				// 	type: "checkbox",
				// 	value: false,
				// 	label: "A.9. výskum, vývoj a zavádzanie inovatívnych technológií a materiálov"
				// },
				// eligibleActivityIPSK3A10: {
				// 	type: "checkbox",
				// 	value: false,
				// 	label: "A.10. poradenstvo v oblasti ochrany duševného vlastníctva a transferu technológií"
				// },
				eligibleActivityIPSK3B1: {
					type: "checkbox",
					value: false,
					label: "B.1. Príprava technologického plánu"
				},
				eligibleActivityIPSK3B2: {
					type: "checkbox",
					value: false,
					label:
						"B.2. mapovanie a implementácia nových postupov a procesov pre zvýšenie efektívnosti spracovania surovín a polotovarov"
				},
				eligibleActivityIPSK3B3: {
					type: "checkbox",
					value: false,
					label:
						"B.3.  poradenstvo pri príprave plánu zavádzania čistejších výrobných technológií využitím nových technológií a inovácií pri spracovaní surovín a polotovarov"
				},
				eligibleActivityIPSK3B4: {
					type: "checkbox",
					value: false,
					label: "B.4. skúmanie komerčného potenciálu inovatívneho produktu alebo služby"
				},
				eligibleActivityIPSK3B5: {
					type: "checkbox",
					value: false,
					label:
						"B.5. výskum a vývoj technologických inovácií a výrobných postupov; inovačných konceptov nových high-tech výrobkov; robotizovaných systémov a príslušenstva pre spracovanie surovín a polotovarov; inovatívnych technológií a materiálov a ich zavádzanie do výrobných procesov"
				},
				eligibleActivityIPSK3B6: {
					type: "checkbox",
					value: false,
					label:
						"B.6. príprava plánu a realizácia nákupu služieb výskumu a vývoja zameraných na prípravu a/alebo implementáciu inovatívnych riešení"
				},
				eligibleActivityIPSK3B7: {
					type: "checkbox",
					value: false,
					label: "B.7. poradenstvo v oblasti ochrany duševného vlastníctva a transferu technológií"
				},
				// eligibleActivityIPSK3C1: {
				// 	type: "checkbox",
				// 	value: false,
				// 	label:
				// 		"C.1. Poradenstvo spojené s prípravou plánu pre implementovanie riešení v oblasti inovatívnych potravinových systémov"
				// },
				// eligibleActivityIPSK3C2: {
				// 	type: "checkbox",
				// 	value: false,
				// 	label:
				// 		"C.2. poradenstvo spojené s prípravou návrhov nových a inovatívnych riešení pre zlepšenie distribúcie potravín s využitím nových technológií a procesov"
				// },
				// eligibleActivityIPSK3C3: {
				// 	type: "checkbox",
				// 	value: false,
				// 	label:
				// 		"C.3. skúmanie nových/inovatívnych riešení pre zlepšenie odolnosti, bezpečnosti a ochrany potravinových systémov"
				// },
				// eligibleActivityIPSK3C4: {
				// 	type: "checkbox",
				// 	value: false,
				// 	label:
				// 		"C.4. implementovanie nových technológií a postupov pre zlepšenie bezpečnosti a ochrany potravinových systémov"
				// },
				// eligibleActivityIPSK3C5: {
				// 	type: "checkbox",
				// 	value: false,
				// 	label: "C.5. poradenstvo v oblasti ochrany duševného vlastníctva a transferu technológií"
				// },
				sentMarketingDataYes: {
					label: "Áno, súhlasím",
					type: "checkbox",
					value: true
				},
				sentMarketingDataNo: {
					label: "Nie, nesúhlasím",
					type: "checkbox",
					value: false
				}
			}
		}
	}
}
