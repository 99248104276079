import {observable, action} from "mobx"
import api from "../../global/actions/api"
import {getUserCompanyInfo} from "../../global/helpers/actions"
import GlobalStore from "../../global/store/GlobalStore"
import RegistersCacheStore from "../../global/store/RegistersCacheStore"
import InfoStore from "../../global/store/InfoStore"

class RequestFormStore {
	@observable applicantChipForm = []
	@observable callId = null
	@observable callCode = null
	@observable dropedFiles0 = []
	@observable dropedFiles1 = []
	@observable dropedFiles2 = []
	@observable dropedFiles3 = []
	@observable dropedFilesOther = []
	@observable attachmentTemplates = ["price"]

	formRef = null

	defaultFormValues = {}

	@action resetVals() {
		this.applicantChipForm = []
		this.callId = null
		this.dropedFiles0 = []
		this.dropedFiles1 = []
		this.dropedFiles2 = []
		this.dropedFiles3 = []
		this.dropedFilesOther = []
		this.attachmentTemplates = ["price"]
		this.formRef = null
		this.defaultFormValues = {}
	}

	@action isSameFormValues() {
		return JSON.stringify(this.defaultFormValues) === JSON.stringify(this.formRef.values())
	}

	@action deleteLoadedAttachemnt(id, dropzoneIndex, attachmentIndex) {
		let data = {
			_id: id,
			active: false
		}
		api
			.saveAttachment(data)
			.call()
			.then(() => {
				this[`dropedFiles${dropzoneIndex}`].splice(attachmentIndex, 1)
			})
	}

	@action resetDropzones() {
		this.dropedFiles0 = []
		this.dropedFiles1 = []
		this.dropedFiles2 = []
		this.dropedFiles3 = []
		this.dropedFilesOther = []
	}

	generateDataAndSave(form, version, callback) {
		let droppedFiles = [
			...this.dropedFiles0,
			...this.dropedFiles1,
			...this.dropedFiles2,
			...this.dropedFiles3,
			...this.dropedFilesOther
		]
		if (isSafe(droppedFiles) && droppedFiles.length) {
			let req = []
			let files = droppedFiles
			var saveFunction = this.save
			// const rel_object_id = response._id
			let binaryDataArr = []
			let getdata = function(index) {
				let data = files[index]
				let binaryData
				var reader = new FileReader()
				reader.readAsBinaryString(data)
				reader.onloadend = function() {
					binaryData = reader.result
					binaryDataArr.push(binaryData)
					req.push({
						attachment_id: isNotEmpty(data._id) ? data._id : null,
						_ref: false,
						active: true,
						content_type: isNotEmpty(data.type) ? data.type : "application/octet-stream",
						// content: binaryData.split(",")[1], ???
						content: btoa(binaryData),
						// type: data.databaseType,
						file_name: data.name,
						size: data.size
					})
					if (files.length > index + 1) {
						getdata(index + 1)
					} else {
						saveFunction(form, req, version, callback)
					}
				}
			}
			getdata(0)
		} else {
			this.save(form, [], version, callback)
		}
	}

	@action save(form, dropzoneData, version, callback) {
		GlobalStore.loading(true)
		const formVals = form.values()
		// const companyResponse = await this.saveCompany(form)

		const isIP_ZS_122 = formVals.callCode == "IP_ZS_122"
		const isIP_ZS2_123 = formVals.callCode == "IP_ZS2_123"
		const isIP_SK2_122 = formVals.callCode == "IP_SK2_122"
		const isIP_SK3_122 = formVals.callCode == "IP_SK3_122"
		const isZSP_123 = formVals.callCode == "ZSP_123"

		let req = {
			record_id: isNotEmpty(formVals.request_id) ? formVals.request_id : null, // vyplnaj pri UPDATE
			_type: isZSP_123
				? "INN.API.JSON.Proxy.Request.ZSK123"
				: isIP_ZS2_123
				? "INN.API.JSON.Proxy.Request.IPZS2123"
				: isIP_SK3_122
				? "INN.API.JSON.Proxy.Request.IPSK3122"
				: isIP_SK2_122
				? "INN.API.JSON.Proxy.Request.IPSK2122"
				: isIP_ZS_122
				? "INN.API.JSON.Proxy.Request.IPZS122"
				: formVals.callCode == "IP_SK_121"
				? "INN.API.JSON.Proxy.Request.IPSK121"
				: "INN.API.JSON.Proxy.InnovationRequest",
			version: isNotEmpty(version) ? version : "draft", // alebo "final"
			status: isNotEmpty(version) && version == "final" ? "sent" : "inp",
			type_id: isZSP_123
				? "INN.Data.Request.ZSK123"
				: isIP_ZS2_123
				? "INN.Data.Request.IPZS2123"
				: isIP_SK3_122
				? "INN.Data.Request.IPSK3122"
				: isIP_SK2_122
				? "INN.Data.Request.IPSK2122"
				: isIP_ZS_122
				? "INN.Data.Request.IPZS122"
				: formVals.callCode == "IP_SK_121"
				? "INN.Data.Request.IPSK121"
				: "INN.Data.InnovationRequest",
			attached_files: dropzoneData,
			is_diffrent_kores: formVals.companyAddressSameNo == true,
			company_data: {
				company: formVals.companyId,
				identifier: formVals.ico,
				name: formVals.companyName,
				tax_identification_number: formVals.dic,
				vat_identification_number: formVals.icdph,
				company_type: formVals.companyDesc, //(Entity.CL.CompanyType)
				legal_form: formVals.companyLegalForm, //(Entity.CL.LegalForm)
				iban: formVals.companyIBAN,
				oa_address: {
					// adresa sidla
					country: {
						_type: "Entity.CL.Country",
						_id: "SK"
					},
					obj_city: {
						_type: "Entity.CL.Address.City",
						_id: formVals.companyCity
					},
					primary: true,
					region: {
						_type: "Entity.CL.Address.Region",
						_id: formVals.companyRegion
					},
					street_plain: formVals.companyStreet,
					house_number: formVals.companyStreetNumber,
					type: {
						_type: "Entity.CL.AddressType",
						_id: "OA"
					},
					zip_plain: formVals.companyPSC
				}
			},
			statutories_data: [],
			call: {
				_ref: true,
				_type: "INN.Data.Call",
				_id: +formVals.callId
			},
			advice_area: isIP_SK2_122 || isIP_SK3_122 ? null : formVals.ipceiAdvice1 == true ? "OP1" : "OP2",
			project_name: formVals.projectName,
			activity_type:
				isIP_ZS_122 || isIP_ZS2_123 || isIP_SK2_122 || isIP_SK3_122
					? formVals.eligibleArea
					: formVals.projectActivityType,
			project_desc_solution_target: formVals.projectDescAndTarget,
			default_situation: formVals.projectBasicParameters,
			project_activity_realization: formVals.projectImplementationMethod,
			additional_information: formVals.projectAdditionalInfo,
			better_price_company_data: {
				identifier: formVals.potentialSolverIco,
				name: formVals.potentialSolverName,
				tax_identification_number: formVals.potentialSolverDic,
				vat_identification_number: formVals.potentialSolverIcdph
			},
			total_project_price: formVals.potentialSolverCompletePrice,
			innovation_voucher_amount: formVals.potentialSolverVoucherPrice,
			sub_fund_amount: formVals.potentialSolverCofinancedPrice
		}

		if (isIP_SK2_122 || isIP_SK3_122 || isIP_ZS2_123) {
			req["project_location_realization"] = formVals.projectRealizationPlace
			req["project_implementation_deadline"] = formVals.projectDeadlineCompletion
		}

		//Kontaktna osoba
		if (
			isNotEmpty(formVals.contactPersonName) ||
			isNotEmpty(formVals.contactPersonMail) ||
			isNotEmpty(formVals.contactPersonPhone)
		) {
			req["request_contact_data"] = {
				name: formVals.contactPersonName,
				email: formVals.contactPersonMail,
				phone: formVals.contactPersonPhone
			}
		}

		//Pri update sa vlozi ID persony
		if (isNotEmpty(formVals.contactPersonId)) {
			req.request_contact_data["person"] = formVals.contactPersonId
		}

		//Statutari
		let statutarData = isEmpty(formVals.applicantChipForm) ? [] : JSON.parse(formVals.applicantChipForm)
		statutarData.map((statutar) => {
			req["statutories_data"].push({
				statutory_name: statutar.name,
				can_sign: statutar.isStatutar
			})
		})

		//Korespondecna adresa
		if (formVals.companyAddressSameNo == true) {
			req.company_data["ca_address"] = {
				country: {
					_type: "Entity.CL.Country",
					_id: "SK"
				},
				house_number: formVals.companyCorrespondenceStreetNumber,
				obj_city: {
					_type: "Entity.CL.Address.City",
					_id: formVals.companyCorrespondenceCity
				},
				city_plain: "",
				primary: true,
				region: {
					_type: "Entity.CL.Address.Region",
					_id: formVals.companyCorrespondenceRegion
				},
				street_plain: formVals.companyCorrespondenceStreet,
				type: {
					_type: "Entity.CL.AddressType",
					_id: "CA"
				},
				zip_plain: formVals.companyCorrespondencePSC
			}
		}

		let subTypes = []

		if (isIP_ZS_122) {
			if (formVals.eligibleAreaCode == "prjvavonkoneumetakvsroio") {
				const typeObject = GlobalStore.CL["activityType"].find((row) => row.code == formVals.eligibleAreaCode)

				if (formVals.eligibleActivityA1 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "vyvprodaprocpouzdiagmonprelab")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityA2 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "vyvprodaprocpouzdiagmonzobrtech")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityA3 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "vyvprodaprocimplvprevochor")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityA4 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "vyvprodaprocimplvstarostochor")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}
				req["activity_sub_types"] = subTypes
			} else if (formVals.eligibleAreaCode == "matabiomatzdrav") {
				const typeObject = GlobalStore.CL["activityType"].find((row) => row.code == formVals.eligibleAreaCode)

				if (formVals.eligibleActivityB1 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "vyvprodregmedkmenbun")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityB2 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "vyvbiomatzlucspeckovaimpl")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityB3 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "vyvbiomatspecochnanomatzeostent")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityB4 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "vyvdezinfantibmat")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityB5 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "vyvintmattext")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}
				req["activity_sub_types"] = subTypes
			} else if (formVals.eligibleAreaCode == "liekyaformy") {
				const typeObject = GlobalStore.CL["activityType"].find((row) => row.code == formVals.eligibleAreaCode)

				if (formVals.eligibleActivityC1 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "vyvliekapriprhumpouz")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityC2 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "vyvkozmprodzdravstarost")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityC3 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "vyvchemprodzdravstarost")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityC4 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "vyvnovforliekpriprav")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				req["activity_sub_types"] = subTypes
			} else if (formVals.eligibleAreaCode == "tridtlacabiotlac") {
				const typeObject = GlobalStore.CL["activityType"].find((row) => row.code == formVals.eligibleAreaCode)

				if (formVals.eligibleActivityD1 == true) {
					const clObject = typeObject.activity_sub_types.find(
						(row) => row.code == "vyvnovtypimplzdravpomatechprostrtridtlace"
					)
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				req["activity_sub_types"] = subTypes
			} else if (formVals.eligibleAreaCode == "senzorydiagtechmonzdrav") {
				const typeObject = GlobalStore.CL["activityType"].find((row) => row.code == formVals.eligibleAreaCode)

				if (formVals.eligibleActivityE1 == true) {
					const clObject = typeObject.activity_sub_types.find(
						(row) => row.code == "vyvpilotimpldiagterapmonsyspouzdomterdss"
					)
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityE2 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "vyvnovsenzmikrosenzsys")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityE3 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "vyvnovprodnositelektro")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityE4 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "vyvnovprodambiassistliving")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityE5 == true) {
					const clObject = typeObject.activity_sub_types.find(
						(row) => row.code == "vyvprodsluzmonvplyvrizikfaktprostrnazdrav"
					)
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				req["activity_sub_types"] = subTypes
			}
		} else if (isIP_ZS2_123) {
			if (formVals.eligibleAreaCode == "zs2-123-A") {
				const typeObject = GlobalStore.CL["activityType"].find((row) => row.code == formVals.eligibleAreaCode)

				if (formVals.eligibleActivityA1 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "zs2-123-A-1")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityA2 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "zs2-123-A-2")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityA3 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "zs2-123-A-3")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityA4 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "zs2-123-A-4")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}
				req["activity_sub_types"] = subTypes
			} else if (formVals.eligibleAreaCode == "zs2-123-B") {
				const typeObject = GlobalStore.CL["activityType"].find((row) => row.code == formVals.eligibleAreaCode)

				if (formVals.eligibleActivityB1 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "zs2-123-B-1")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityB2 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "zs2-123-B-2")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityB3 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "zs2-123-B-3")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityB4 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "zs2-123-B-4")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityB5 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "zs2-123-B-5")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}
				req["activity_sub_types"] = subTypes
			} else if (formVals.eligibleAreaCode == "zs2-123-C") {
				const typeObject = GlobalStore.CL["activityType"].find((row) => row.code == formVals.eligibleAreaCode)

				if (formVals.eligibleActivityC1 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "zs2-123-C-1")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityC2 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "zs2-123-C-2")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityC3 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "zs2-123-C-3")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityC4 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "zs2-123-C-14")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				req["activity_sub_types"] = subTypes
			} else if (formVals.eligibleAreaCode == "zs2-123-D") {
				const typeObject = GlobalStore.CL["activityType"].find((row) => row.code == formVals.eligibleAreaCode)

				if (formVals.eligibleActivityD1 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "zs2-123-D-1")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				req["activity_sub_types"] = subTypes
			} else if (formVals.eligibleAreaCode == "zs2-123-E") {
				const typeObject = GlobalStore.CL["activityType"].find((row) => row.code == formVals.eligibleAreaCode)

				if (formVals.eligibleActivityE1 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "zs2-123-E-1")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityE2 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "zs2-123-E-2")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityE3 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "zs2-123-E-3")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityE4 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "zs2-123-E-4")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityE5 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "zs2-123-E-5")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				req["activity_sub_types"] = subTypes
			}
		} else if (isIP_SK2_122) {
			if (formVals.eligibleAreaCode == "spracsurdovyrobsvyspridhod") {
				const typeObject = GlobalStore.CL["activityType"].find((row) => row.code == formVals.eligibleAreaCode)

				if (formVals.eligibleActivityIPSK2A1 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "priptechnologplan")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityIPSK2A2 == true) {
					const clObject = typeObject.activity_sub_types.find(
						(row) => row.code == "mapaimplnovpostupproceszvysefektspracsur"
					)
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityIPSK2A3 == true) {
					const clObject = typeObject.activity_sub_types.find(
						(row) => row.code == "poradpripplanzavcistvyrtechvyuznovtech"
					)
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityIPSK2A4 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "skumkomerpotencinovprodasluz")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityIPSK2A5 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "vyskavyvtechinovavyrobpostup")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityIPSK2A6 == true) {
					const clObject = typeObject.activity_sub_types.find(
						(row) => row.code == "vyskavyvinovkonceptnovhightechvyrob"
					)
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityIPSK2A7 == true) {
					const clObject = typeObject.activity_sub_types.find(
						(row) => row.code == "pripplanrealnakupsluzvyskavyvzamimplinovries"
					)
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityIPSK2A8 == true) {
					const clObject = typeObject.activity_sub_types.find(
						(row) => row.code == "vyskavyvrobotsysaprislspracsurpolot"
					)
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityIPSK2A9 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "poradochrdusvlasttransftecha")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				req["activity_sub_types"] = subTypes
			}
			if (formVals.eligibleAreaCode == "zvysenergefektvhosp") {
				const typeObject = GlobalStore.CL["activityType"].find((row) => row.code == formVals.eligibleAreaCode)

				if (formVals.eligibleActivityIPSK2B1 == true) {
					const clObject = typeObject.activity_sub_types.find(
						(row) => row.code == "poradvypracplanimplinovriesznizenergnarocpodnik"
					)
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityIPSK2B2 == true) {
					const clObject = typeObject.activity_sub_types.find(
						(row) => row.code == "poradvyprnavrhrieszvysenergefektusklprenos"
					)
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityIPSK2B3 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "vyskavyvinteligenergetsyssiet")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityIPSK2B4 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "vyskavyvuskladenergeltenergnosic")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityIPSK2B5 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "poradochrdusvlasttransftechb")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				req["activity_sub_types"] = subTypes
			}
			if (formVals.eligibleAreaCode == "systodolazdravlokalpotr") {
				const typeObject = GlobalStore.CL["activityType"].find((row) => row.code == formVals.eligibleAreaCode)

				if (formVals.eligibleActivityIPSK2C1 == true) {
					const clObject = typeObject.activity_sub_types.find(
						(row) => row.code == "poradpriprplanimplriesinovlokalpotravsys"
					)
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityIPSK2C2 == true) {
					const clObject = typeObject.activity_sub_types.find(
						(row) => row.code == "poradpriprnavrhinovriesdistrlokalpotrnovtechproc"
					)
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityIPSK2C3 == true) {
					const clObject = typeObject.activity_sub_types.find(
						(row) => row.code == "skumnovinovriesodolnbezpochrlokalpotrsys"
					)
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityIPSK2C4 == true) {
					const clObject = typeObject.activity_sub_types.find(
						(row) => row.code == "implnovtechpostupbezpochrlokalpotrsys"
					)
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityIPSK2C5 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "poradochrdusvlasttransftechc")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				req["activity_sub_types"] = subTypes
			}
		} else if (isIP_SK3_122) {
			if (formVals.eligibleAreaCode == "sk3-122-A") {
				const typeObject = GlobalStore.CL["activityType"].find((row) => row.code == formVals.eligibleAreaCode)

				if (formVals.eligibleActivityIPSK3A1 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "sk3-122-A-1")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}
				if (formVals.eligibleActivityIPSK3A2 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "sk3-122-A-2")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}
				if (formVals.eligibleActivityIPSK3A3 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "sk3-122-A-3")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}
				if (formVals.eligibleActivityIPSK3A4 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "sk3-122-A-4")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}
				if (formVals.eligibleActivityIPSK3A5 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "sk3-122-A-5")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}
				// if (formVals.eligibleActivityIPSK3A6 == true) {
				// 	const clObject = typeObject.activity_sub_types.find((row) => row.code == "sk3-122-A-6")
				// 	if (isSafe(clObject)) {
				// 		subTypes.push(clObject._id)
				// 	}
				// }
				// if (formVals.eligibleActivityIPSK3A7 == true) {
				// 	const clObject = typeObject.activity_sub_types.find((row) => row.code == "sk3-122-A-7")
				// 	if (isSafe(clObject)) {
				// 		subTypes.push(clObject._id)
				// 	}
				// }
				// if (formVals.eligibleActivityIPSK3A8 == true) {
				// 	const clObject = typeObject.activity_sub_types.find((row) => row.code == "sk3-122-A-8")
				// 	if (isSafe(clObject)) {
				// 		subTypes.push(clObject._id)
				// 	}
				// }
				// if (formVals.eligibleActivityIPSK3A9 == true) {
				// 	const clObject = typeObject.activity_sub_types.find((row) => row.code == "sk3-122-A-9")
				// 	if (isSafe(clObject)) {
				// 		subTypes.push(clObject._id)
				// 	}
				// }
				// if (formVals.eligibleActivityIPSK3A10 == true) {
				// 	const clObject = typeObject.activity_sub_types.find((row) => row.code == "sk3-122-A-10")
				// 	if (isSafe(clObject)) {
				// 		subTypes.push(clObject._id)
				// 	}
				// }

				req["activity_sub_types"] = subTypes
			}
			if (formVals.eligibleAreaCode == "sk3-122-B") {
				const typeObject = GlobalStore.CL["activityType"].find((row) => row.code == formVals.eligibleAreaCode)

				if (formVals.eligibleActivityIPSK3B1 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "sk3-122-B-1")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityIPSK3B2 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "sk3-122-B-2")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityIPSK3B3 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "sk3-122-B-3")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityIPSK3B4 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "sk3-122-B-4")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityIPSK3B5 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "sk3-122-B-5")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityIPSK3B6 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "sk3-122-B-6")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.eligibleActivityIPSK3B7 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "sk3-122-B-7")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				req["activity_sub_types"] = subTypes
			}
		} else {
			if (formVals.projectActivityTypeCode == "accinnconssvc") {
				const typeObject = GlobalStore.CL["activityType"].find((row) => row.code == formVals.projectActivityTypeCode)

				if (formVals.activityTypeAddAC1 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "zhodinovpotencial")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.activityTypeAddAC2 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "skumkomerpotenc")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.activityTypeAddAC3 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "priprinovreis")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.activityTypeAddAC4 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "poradochrdusvlast")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}
				req["activity_sub_types"] = subTypes
			} else if (formVals.projectActivityTypeCode == "resdevsvc") {
				const typeObject = GlobalStore.CL["activityType"].find((row) => row.code == formVals.projectActivityTypeCode)

				if (formVals.activityTypeAddRE1 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "pripvyskplan")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.activityTypeAddRE2 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "pripplanarealiznakupsluz")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}

				if (formVals.activityTypeAddRE3 == true) {
					const clObject = typeObject.activity_sub_types.find((row) => row.code == "pripplanaimplnavybudintkapac")
					if (isSafe(clObject)) {
						subTypes.push(clObject._id)
					}
				}
				req["activity_sub_types"] = subTypes
			}
		}

		req["activity_type"] = isEmpty(req["activity_type"]) ? null : req["activity_type"]

		if (isIP_ZS_122 || isIP_ZS2_123 || isIP_SK2_122 || isIP_SK3_122 || isZSP_123) {
			req["consent_marketing_information"] = formVals.sentMarketingDataYes
		}

		api
			.saveRequest(req)
			.call()
			.then((response) => {
				GlobalStore.setNotificationMessage(
					version == "final" ? "Odoslanie prebehlo úspešne" : "Uloženie prebehlo úspešne",
					"",
					"success"
				)
				if (isSafe(callback)) {
					callback(response._id)
				}
			})
	}

	@action load(form, id, callback) {
		GlobalStore.loading(true)
		api
			.loadRequestWithAtt(id)
			.call()
			.then((response) => {
				form.reset()
				const company = response.company_data || {}
				const potentialSolver = response.better_price_company_data || {}
				const request_contact_data = response.request_contact_data || {}
				const companyAddresses = company.oa_address || {}
				const isIP_ZS_122 = response.call && response.call.call_type && response.call.call_type.code == "IP_ZS_122"
				const isIP_ZS2_123 = response.call && response.call.call_type && response.call.call_type.code == "IP_ZS2_123"
				const isIP_SK2_122 = response.call && response.call.call_type && response.call.call_type.code == "IP_SK2_122"
				const isIP_SK3_122 = response.call && response.call.call_type && response.call.call_type.code == "IP_SK3_122"
				const isZSP_123 = response.call && response.call.call_type && response.call.call_type.code == "ZSP_123"

				let formObj = {
					request_id: response.record_id,
					version: response.version,
					status: response.status._id,
					companyId: company.company,
					ico: company.identifier,
					dic: company.tax_identification_number,
					icdph: company.vat_identification_number,
					companyName: company.name,
					companyDesc: isSafe(company.company_type) ? company.company_type._id : "",
					callCode: response.call.call_type.code,
					//companyAddresses
					companyStreet: companyAddresses.street_plain,
					companyStreetNumber: companyAddresses.house_number,
					companyCity: companyAddresses.obj_city,
					companyRegion: companyAddresses.region,
					companyPSC: companyAddresses.zip_plain,
					companyLegalForm: company.legal_form._id,
					companyIBAN: company.iban,
					contactPersonName: request_contact_data.name,
					contactPersonMail: request_contact_data.email,
					contactPersonPhone: request_contact_data.phone,
					contactPersonId: request_contact_data.person,
					callId: response.call._id,
					zoIPNumber: response.innovation_request_id,
					projectName: response.project_name,
					projectDescAndTarget: response.project_desc_solution_target,
					projectBasicParameters: response.default_situation,
					projectImplementationMethod: response.project_activity_realization,
					projectAdditionalInfo: response.additional_information,
					potentialSolverIco: potentialSolver.identifier,
					potentialSolverName: potentialSolver.name,
					potentialSolverDic: potentialSolver.tax_identification_number,
					potentialSolverIcdph: potentialSolver.vat_identification_number,
					potentialSolverCompletePrice: response.total_project_price,
					potentialSolverVoucherPrice: response.innovation_voucher_amount,
					potentialSolverCofinancedPrice: response.sub_fund_amount,
					sent_date: response.sent_date_time
				}

				if (isIP_SK2_122 || isIP_SK3_122 || isIP_ZS2_123) {
					formObj["projectRealizationPlace"] = response.project_location_realization
					formObj["projectDeadlineCompletion"] = response.project_implementation_deadline
				}

				if (isIP_ZS_122 || isIP_ZS2_123 || isIP_SK2_122 || isIP_SK3_122) {
					formObj["eligibleArea"] = isSafe(response.activity_type) ? response.activity_type._id : ""
					formObj["eligibleAreaCode"] = isSafe(response.activity_type) ? response.activity_type.code : ""
					formObj["sentMarketingDataYes"] = !!response.consent_marketing_information
					formObj["sentMarketingDataNo"] = !response.consent_marketing_information
				} else if (isZSP_123) {
					formObj["sentMarketingDataYes"] = !!response.consent_marketing_information
					formObj["sentMarketingDataNo"] = !response.consent_marketing_information
				} else {
					formObj["projectActivityType"] = isSafe(response.activity_type) ? response.activity_type._id : ""
					formObj["projectActivityTypeCode"] = isSafe(response.activity_type) ? response.activity_type.code : ""
				}

				if (response.advice_area == "OP1") {
					formObj.ipceiAdvice1 = true
					formObj.ipceiAdvice2 = false
				} else {
					formObj.ipceiAdvice1 = false
					formObj.ipceiAdvice2 = true
				}

				const caAddress = company.ca_address
				if (
					isSafe(caAddress) &&
					(isNotEmpty(caAddress.street_plain) ||
						isNotEmpty(caAddress.house_number) ||
						isNotEmpty(caAddress.obj_city) ||
						isNotEmpty(caAddress.region) ||
						isNotEmpty(caAddress.zip_plain))
				) {
					formObj.companyCorrespondenceStreet = caAddress.street_plain
					formObj.companyCorrespondenceStreetNumber = caAddress.house_number
					formObj.companyCorrespondenceCity = caAddress.obj_city
					formObj.companyCorrespondenceRegion = caAddress.region
					formObj.companyCorrespondencePSC = caAddress.zip_plain

					formObj.companyAddressSameYes = false
					formObj.companyAddressSameNo = true
					form.$("companyCorrespondenceRegion").set("rules", "required")
					form.$("companyCorrespondenceStreet").set("rules", "required")
					form.$("companyCorrespondenceCity").set("rules", "required")
					form.$("companyCorrespondenceStreetNumber").set("rules", "required")
					form.$("companyCorrespondencePSC").set("rules", "required|between:5,5")
				}

				if (isSafe(response.statutories_data) && response.statutories_data.length > 0) {
					let statutors = []
					response.statutories_data.forEach((statutar) => {
						statutors.push({
							name: statutar.statutory_name,
							isStatutar: statutar.can_sign
						})
					})
					formObj.applicantChipForm = JSON.stringify(statutors)
				} else {
					formObj.applicantChipForm = JSON.stringify([{name: "", isStatutar: false}])
				}

				if (isSafe(response.attached_files) && response.attached_files.length) {
					response.attached_files.forEach((attachment) => {
						const blob = b64toBlob(attachment.content)
						let tmp = new Blob([blob], {
							type: attachment.content_type
						})
						tmp.name = attachment.file_name
						tmp._id = attachment.attachment_id
						tmp.databaseType = attachment.type
						this.dropedFiles0.push(tmp)
					})
				}

				if (isSafe(response.activity_sub_types)) {
					if (isIP_ZS_122) {
						response.activity_sub_types.forEach((sub_type) => {
							// A
							if (sub_type.code == "vyvprodaprocpouzdiagmonprelab") {
								formObj.eligibleActivityA1 = true
							}
							if (sub_type.code == "vyvprodaprocpouzdiagmonzobrtech") {
								formObj.eligibleActivityA2 = true
							}
							if (sub_type.code == "vyvprodaprocimplvprevochor") {
								formObj.eligibleActivityA3 = true
							}
							if (sub_type.code == "vyvprodaprocimplvstarostochor") {
								formObj.eligibleActivityA4 = true
							}

							// B
							if (sub_type.code == "vyvprodregmedkmenbun") {
								formObj.eligibleActivityB1 = true
							}
							if (sub_type.code == "vyvbiomatzlucspeckovaimpl") {
								formObj.eligibleActivityB2 = true
							}
							if (sub_type.code == "vyvbiomatspecochnanomatzeostent") {
								formObj.eligibleActivityB3 = true
							}
							if (sub_type.code == "vyvdezinfantibmat") {
								formObj.eligibleActivityB4 = true
							}
							if (sub_type.code == "vyvintmattext") {
								formObj.eligibleActivityB5 = true
							}

							// C
							if (sub_type.code == "vyvliekapriprhumpouz") {
								formObj.eligibleActivityC1 = true
							}
							if (sub_type.code == "vyvkozmprodzdravstarost") {
								formObj.eligibleActivityC2 = true
							}
							if (sub_type.code == "vyvchemprodzdravstarost") {
								formObj.eligibleActivityC3 = true
							}
							if (sub_type.code == "vyvnovforliekpriprav") {
								formObj.eligibleActivityC4 = true
							}

							//D
							if (sub_type.code == "vyvnovtypimplzdravpomatechprostrtridtlace") {
								formObj.eligibleActivityD1 = true
							}

							//E
							if (sub_type.code == "vyvpilotimpldiagterapmonsyspouzdomterdss") {
								formObj.eligibleActivityE1 = true
							}
							if (sub_type.code == "vyvnovsenzmikrosenzsys") {
								formObj.eligibleActivityE2 = true
							}
							if (sub_type.code == "vyvnovprodnositelektro") {
								formObj.eligibleActivityE3 = true
							}
							if (sub_type.code == "vyvnovprodambiassistliving") {
								formObj.eligibleActivityE4 = true
							}
							if (sub_type.code == "vyvprodsluzmonvplyvrizikfaktprostrnazdrav") {
								formObj.eligibleActivityE5 = true
							}
						})
					} else if (isIP_ZS2_123) {
						response.activity_sub_types.forEach((sub_type) => {
							// A
							if (sub_type.code == "zs2-123-A-1") {
								formObj.eligibleActivityA1 = true
							}
							if (sub_type.code == "zs2-123-A-2") {
								formObj.eligibleActivityA2 = true
							}
							if (sub_type.code == "zs2-123-A-3") {
								formObj.eligibleActivityA3 = true
							}
							if (sub_type.code == "zs2-123-A-4") {
								formObj.eligibleActivityA4 = true
							}

							// B
							if (sub_type.code == "zs2-123-B-1") {
								formObj.eligibleActivityB1 = true
							}
							if (sub_type.code == "zs2-123-B-2") {
								formObj.eligibleActivityB2 = true
							}
							if (sub_type.code == "zs2-123-B-3") {
								formObj.eligibleActivityB3 = true
							}
							if (sub_type.code == "zs2-123-B-4") {
								formObj.eligibleActivityB4 = true
							}
							if (sub_type.code == "zs2-123-B-5") {
								formObj.eligibleActivityB5 = true
							}

							// C
							if (sub_type.code == "zs2-123-C-1") {
								formObj.eligibleActivityC1 = true
							}
							if (sub_type.code == "zs2-123-C-2") {
								formObj.eligibleActivityC2 = true
							}
							if (sub_type.code == "zs2-123-C-3") {
								formObj.eligibleActivityC3 = true
							}
							if (sub_type.code == "zs2-123-C-4") {
								formObj.eligibleActivityC4 = true
							}

							//D
							if (sub_type.code == "zs2-123-D-1") {
								formObj.eligibleActivityD1 = true
							}

							//E
							if (sub_type.code == "zs2-123-E-1") {
								formObj.eligibleActivityE1 = true
							}
							if (sub_type.code == "zs2-123-E-2") {
								formObj.eligibleActivityE2 = true
							}
							if (sub_type.code == "zs2-123-E-3") {
								formObj.eligibleActivityE3 = true
							}
							if (sub_type.code == "zs2-123-E-4") {
								formObj.eligibleActivityE4 = true
							}
							if (sub_type.code == "zs2-123-E-5") {
								formObj.eligibleActivityE5 = true
							}
						})
					} else if (isIP_SK2_122) {
						response.activity_sub_types.forEach((sub_type) => {
							// A
							if (sub_type.code == "priptechnologplan") {
								formObj.eligibleActivityIPSK2A1 = true
							}
							if (sub_type.code == "mapaimplnovpostupproceszvysefektspracsur") {
								formObj.eligibleActivityIPSK2A2 = true
							}
							if (sub_type.code == "poradpripplanzavcistvyrtechvyuznovtech") {
								formObj.eligibleActivityIPSK2A3 = true
							}
							if (sub_type.code == "skumkomerpotencinovprodasluz") {
								formObj.eligibleActivityIPSK2A4 = true
							}
							if (sub_type.code == "vyskavyvtechinovavyrobpostup") {
								formObj.eligibleActivityIPSK2A5 = true
							}
							if (sub_type.code == "vyskavyvinovkonceptnovhightechvyrob") {
								formObj.eligibleActivityIPSK2A6 = true
							}
							if (sub_type.code == "pripplanrealnakupsluzvyskavyvzamimplinovries") {
								formObj.eligibleActivityIPSK2A7 = true
							}
							if (sub_type.code == "vyskavyvrobotsysaprislspracsurpolot") {
								formObj.eligibleActivityIPSK2A8 = true
							}
							if (sub_type.code == "poradochrdusvlasttransftecha") {
								formObj.eligibleActivityIPSK2A9 = true
							}

							// B
							if (sub_type.code == "poradvypracplanimplinovriesznizenergnarocpodnik") {
								formObj.eligibleActivityIPSK2B1 = true
							}
							if (sub_type.code == "poradvyprnavrhrieszvysenergefektusklprenos") {
								formObj.eligibleActivityIPSK2B2 = true
							}
							if (sub_type.code == "vyskavyvinteligenergetsyssiet") {
								formObj.eligibleActivityIPSK2B3 = true
							}
							if (sub_type.code == "vyskavyvuskladenergeltenergnosic") {
								formObj.eligibleActivityIPSK2B4 = true
							}
							if (sub_type.code == "poradochrdusvlasttransftechb") {
								formObj.eligibleActivityIPSK2B5 = true
							}

							// C
							if (sub_type.code == "poradpriprplanimplriesinovlokalpotravsys") {
								formObj.eligibleActivityIPSK2C1 = true
							}
							if (sub_type.code == "poradpriprnavrhinovriesdistrlokalpotrnovtechproc") {
								formObj.eligibleActivityIPSK2C2 = true
							}
							if (sub_type.code == "skumnovinovriesodolnbezpochrlokalpotrsys") {
								formObj.eligibleActivityIPSK2C3 = true
							}
							if (sub_type.code == "implnovtechpostupbezpochrlokalpotrsys") {
								formObj.eligibleActivityIPSK2C4 = true
							}
							if (sub_type.code == "poradochrdusvlasttransftechc") {
								formObj.eligibleActivityIPSK2C5 = true
							}
						})
					} else if (isIP_SK3_122) {
						response.activity_sub_types.forEach((sub_type) => {
							// A
							if (sub_type.code == "sk3-122-A-1") {
								formObj.eligibleActivityIPSK3A1 = true
							}
							if (sub_type.code == "sk3-122-A-2") {
								formObj.eligibleActivityIPSK3A2 = true
							}
							if (sub_type.code == "sk3-122-A-3") {
								formObj.eligibleActivityIPSK3A3 = true
							}
							if (sub_type.code == "sk3-122-A-4") {
								formObj.eligibleActivityIPSK3A4 = true
							}
							if (sub_type.code == "sk3-122-A-5") {
								formObj.eligibleActivityIPSK3A5 = true
							}
							// if (sub_type.code == "sk3-122-A-6") {
							// 	formObj.eligibleActivityIPSK3A6 = true
							// }
							// if (sub_type.code == "sk3-122-A-7") {
							// 	formObj.eligibleActivityIPSK3A7 = true
							// }
							// if (sub_type.code == "sk3-122-A-8") {
							// 	formObj.eligibleActivityIPSK3A8 = true
							// }
							// if (sub_type.code == "sk3-122-A-9") {
							// 	formObj.eligibleActivityIPSK3A9 = true
							// }
							// if (sub_type.code == "sk3-122-A-10") {
							// 	formObj.eligibleActivityIPSK3A9 = true
							// }

							// B
							if (sub_type.code == "sk3-122-B-1") {
								formObj.eligibleActivityIPSK3B1 = true
							}
							if (sub_type.code == "sk3-122-B-2") {
								formObj.eligibleActivityIPSK3B2 = true
							}
							if (sub_type.code == "sk3-122-B-3") {
								formObj.eligibleActivityIPSK3B3 = true
							}
							if (sub_type.code == "sk3-122-B-4") {
								formObj.eligibleActivityIPSK3B4 = true
							}
							if (sub_type.code == "sk3-122-B-5") {
								formObj.eligibleActivityIPSK3B5 = true
							}
							if (sub_type.code == "sk3-122-B-6") {
								formObj.eligibleActivityIPSK3B6 = true
							}
							if (sub_type.code == "sk3-122-B-7") {
								formObj.eligibleActivityIPSK3B7 = true
							}
						})
					} else {
						response.activity_sub_types.forEach((sub_type) => {
							if (sub_type.code == "zhodinovpotencial") {
								formObj.activityTypeAddAC1 = true
							}
							if (sub_type.code == "skumkomerpotenc") {
								formObj.activityTypeAddAC2 = true
							}
							if (sub_type.code == "priprinovreis") {
								formObj.activityTypeAddAC3 = true
							}
							if (sub_type.code == "poradochrdusvlast") {
								formObj.activityTypeAddAC4 = true
							}
							if (sub_type.code == "pripvyskplan") {
								formObj.activityTypeAddRE1 = true
							}
							if (sub_type.code == "pripplanarealiznakupsluz") {
								formObj.activityTypeAddRE2 = true
							}
							if (sub_type.code == "pripplanaimplnavybudintkapac") {
								formObj.activityTypeAddRE3 = true
							}
						})
					}
				}

				if (form.callCode == "IP_IPCEI_121") {
					this.handleFormForIpceiRequest(form)
				}

				if (formObj.callCode == "IP_SK_121") {
					this.handleFormForSKRequest(form)
				}

				if (formObj.callCode == "IP_ZS_122" || formObj.callCode == "IP_ZS2_123") {
					this.handleFormForZSRequest(form)
				}

				if (formObj.callCode == "IP_SK2_122" || isIP_SK3_122) {
					this.handleFormForIPSK2(form)
				}

				if (formObj.callCode == "ZSP_123") {
					this.handleFormForZSP_123(form)
				}

				form.set(formObj)

				if (form.$("status").value != "inp" && isNotEmpty(form.$("status").value)) {
					form.$("confirmCheckbox12mothsPassed").set("value", true)
					form.$("confirmCheckboxAddress").set("value", true)
					form.$("confirmCheckboxAllCorrect").set("value", true)
					form.$("confirmCheckboxConfirmConsulting").set("value", true)
					form.$("confirmCheckboxIP2SK5").set("value", true)
				}

				this.defaultFormValues = form.values()
				logger(this.defaultFormValues)
				GlobalStore.loading(false)

				if (isSafe(callback) && typeof callback == "function") {
					callback()
				}
			})
	}

	downloadSpiecificTemplate(uri, name) {
		api.downloadFile(uri, name)
	}

	validateDraft(form, callback) {
		const formVals = form.values()
		const isIP_ZS_122 = formVals.callCode == "IP_ZS_122"
		const isIP_ZS2_123 = formVals.callCode == "IP_ZS2_123"

		//Polia ktore su req pri drafte
		if (
			isEmpty(formVals.companyName) ||
			// isEmpty(isIP_ZS_122 ? formVals.eligibleAreaCode : formVals.projectActivityType) ||
			isEmpty(formVals.companyDesc) ||
			isEmpty(formVals.companyLegalForm) ||
			isEmpty(formVals.companyCity) ||
			isEmpty(formVals.companyRegion) ||
			isEmpty(formVals.companyStreet) ||
			isEmpty(formVals.companyStreetNumber) ||
			isEmpty(formVals.companyPSC) ||
			isEmpty(formVals.dic) ||
			isEmpty(formVals.icdph) ||
			isEmpty(formVals.companyIBAN) ||
			(formVals.companyAddressSameNo == true &&
				(isEmpty(formVals.companyCorrespondenceRegion) ||
					isEmpty(formVals.companyCorrespondenceCity) ||
					isEmpty(formVals.companyCorrespondenceStreet) ||
					isEmpty(formVals.companyCorrespondenceStreetNumber) ||
					isEmpty(formVals.companyCorrespondencePSC)))
		) {
			GlobalStore.setNotificationMessage(
				`Polia "Obchodné meno", ${
					isIP_ZS_122 || isIP_ZS2_123 ? "Oprávnená oblasť" : "Typ oprávnených aktivít IP"
				}, "Základná charakteristika žiadateľa", "Právna forma", "Obec", "Kraj", "Ulica", "Popisné číslo", "PSČ" sú povinné pre uloženie rozpracovanei verzie`,
				null,
				"error"
			)
		} else {
			callback()
		}
	}

	async loadCodelistAndForm(form, id) {
		GlobalStore.loading(true)
		await RegistersCacheStore.checkRegisters("/request")
		this.load(form, id)
	}

	handleFormForIpceiRequest(form) {
		let ipceiActivity = GlobalStore.CL["activityType"].find((row) => row.code == "accinnconssvc")
		if (isSafe(ipceiActivity)) {
			form.$("projectActivityType").value = ipceiActivity._id
		}
		form.$("projectName").value = "Dokumentácia pre notifikačný proces IPCEI"
		// form.$("projectAdditionalInfo").value =
		// 	"Vypracovanie dokumentácie, alebo jej časti pre potreby notifikačného procesu v rámci tvorby uceleného IPCEI projektu."
		form.$("projectActivityType").set("label", "Oprávnená aktivita IP")
		form.$("projectAdditionalInfo").set("rules", "")
		form.$("projectDescAndTarget").set("rules", "")
		form.$("projectBasicParameters").set("rules", "")
		form.$("projectImplementationMethod").set("rules", "")
	}

	handleFormForSKRequest(form) {
		form.$("projectAdditionalInfo").set("rules", "")
	}

	handleFormForZSRequest(form) {
		form.$("projectAdditionalInfo").set("rules", "")
		form.$("projectActivityType").set("rules", "")
		form.$("eligibleArea").set("rules", "required")

		if (form.$("callCode").value == "IP_ZS2_123") {
			form.$("projectRealizationPlace").set("rules", "required")
			form.$("projectDeadlineCompletion").set("rules", "required")
		}
	}

	handleFormForIPSK2(form) {
		form.$("projectAdditionalInfo").set("rules", "")
		form.$("projectActivityType").set("rules", "")
		form.$("eligibleArea").set("rules", "required")
		form.$("projectRealizationPlace").set("rules", "required")
		form.$("projectDeadlineCompletion").set("rules", "required")
	}

	handleFormForZSP_123(form) {
		form.$("projectAdditionalInfo").set("rules", "")
		form.$("projectActivityType").set("rules", "")
		form.$("eligibleArea").set("rules", "")
		form.$("projectRealizationPlace").set("rules", "")
		form.$("projectDeadlineCompletion").set("rules", "")

		form.$("potentialSolverCofinancedPrice").set("rules", "")
		form.$("potentialSolverCompletePrice").set("rules", "")
		form.$("potentialSolverDic").set("rules", "")
		form.$("potentialSolverIcdph").set("rules", "")
		form.$("potentialSolverIco").set("rules", "")
		form.$("potentialSolverName").set("rules", "")
		form.$("potentialSolverVoucherPrice").set("rules", "")
		form.$("projectBasicParameters").set("rules", "")
		form.$("projectDescAndTarget").set("rules", "")
		form.$("projectImplementationMethod").set("rules", "")
		form.$("projectName").set("rules", "")
		form.$("companyIBAN").set("rules", "")

		form.$("companyAddressSameYes").value = true
		form.$("companyAddressSameNo").value = false
	}

	loadCompanyInfo(form) {
		GlobalStore.loading(true)
		const id = getUserCompanyInfo()._id
		api
			.loadCompanyInfo(id)
			.call()
			.then((company) => {
				form.$("companyName").value = company.name
				form.$("ico").value = company.identifier
				form.$("companyDesc").value = isSafe(company.type) ? company.type.code : ""
				form.$("companyLegalForm").value = isSafe(company.legal_form) ? company.legal_form.code : ""
				form.$("dic").value = company.tax_identification_number
				form.$("icdph").value = company.vat_identification_number
				let orgunit = company.specific_rels.find((row) => row._type == "INN.Data.Entity.OrgUnit")
				if (isSafe(orgunit) && isSafe(orgunit.addresses)) {
					let address = orgunit.addresses.find(
						(row) => row._type == "Entity.Data.Address" && row.active == true && row.type._id == "OA"
					)
					if (isSafe(address)) {
						form.$("companyRegion").value = isSafe(address.region) ? address.region._id : ""
						form.$("companyCity").value = isSafe(address.obj_city) ? address.obj_city._id : ""
						form.$("companyStreet").value = address.street_plain
						form.$("companyStreetNumber").value = address.house_number
						form.$("companyPSC").value = address.zip_plain
					}

					let corespondingAddress = orgunit.addresses.find(
						(row) => row._type == "Entity.Data.Address" && row.active == true && row.type._id == "CA"
					)

					if (isSafe(corespondingAddress) && !form.$("callCode").value == "ZSP_123") {
						form.$("companyAddressSameYes").value = false
						form.$("companyAddressSameNo").value = true
						form.$("companyCorrespondenceRegion").value = isSafe(corespondingAddress.region)
							? corespondingAddress.region._id
							: ""
						form.$("companyCorrespondenceCity").value = isSafe(corespondingAddress.obj_city)
							? corespondingAddress.obj_city._id
							: ""
						form.$("companyCorrespondenceStreet").value = corespondingAddress.street_plain
						form.$("companyCorrespondenceStreetNumber").value = corespondingAddress.house_number
						form.$("companyCorrespondencePSC").value = corespondingAddress.zip_plain
					}

					let account = orgunit.accounts.find((row) => row._type == "Fin.Data.BankAccount")
					if (isSafe(account)) {
						form.$("companyIBAN").value = account.number
					}
				}

				this.checkRegionAvailability(form)
				GlobalStore.loading(false)
			})
			.catch(() => {
				GlobalStore.loading(false)
			})
	}

	checkRegionAvailability(form) {
		const {callId, companyRegion} = form.values()
		if (isNotEmpty(callId) && isNotEmpty(companyRegion)) {
			const req = {
				region_id: companyRegion,
				call_id: callId
			}

			api
				.regionRestriction(req)
				.call()
				.then((response) => {
					if (isSafe(response) && response.is_allowed === false) {
						InfoStore.open(
							"Aktuálne nie je možné podať ŽoIP so sídlom podnikania v rámci Vášho kraja. Viac informácií je dostupných v rámci oznámenia zverejnenom na webovom sídle wwww.inovujme.sk.",
							"Upozornenie"
						)
					}
				})
		}
	}
}

var singleton = new RequestFormStore()
export default singleton

