import React, {useEffect, useState} from "react"
import {observer} from "mobx-react"

import fields from "./fields"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import api from "../../global/actions/api"

import {Container, Grid, Typography, Hidden /*, Link */, Link} from "@material-ui/core"
import ReactTooltip from "react-tooltip"
import {FormattedMessage, injectIntl} from "react-intl"
import XsInput from "../../global/ui/xsInput/xsInput"
import XsAutocomplete from "../../global/ui/xsInput/xsAutocomplete"
import XsNumberInput from "../../global/ui/xsInput/xsNumberInput"
import dvr from "mobx-react-form/lib/validators/DVR"
import AddIcon from "@material-ui/icons/Add"
import XsTextArea from "../../global/ui/xsTextArea/xsTextArea"
import GlobalStore from "../../global/store/GlobalStore"
// import Dropzone from "react-dropzone"
import XsConfirmationDialog from "../../global/ui/xsDialog/xsConfirmationDialog"
// import XsCheckbox from "../../global/ui/xsCheckbox/xsCheckbox"
import XsButton from "../../global/ui/xsButton/xsButton"
import XsChipForm from "../../global/ui/xsChipForm/xsChipForm"
import XsChip from "../../global/ui/xsChip/xsChip"
import XsIconButton from "../../global/ui/xsButton/xsIconButton"

import InfoStore from "../../global/store/InfoStore"
import RequestFormStore from "./RequestFormStore"
import RouterStore from "../../global/store/RouterStore"
import XsSearchSelect from "../../global/ui/xsSearchSelect/xsSearchSelect"
import RequestPrintStore from "./RequestPrintStore"
import RegistersCacheStore from "../../global/store/RegistersCacheStore"
import {getUserAccessRights, getUserCompanyInfo} from "../../global/helpers/actions"
import XsCheckbox from "../../global/ui/xsCheckbox/xsCheckbox"
import renderHTML from "react-render-html"
import {isIBANValid} from "../../global/helpers/functions"

// import XsMultiSelect from "../../global/ui/xsMultiSelect/xsMultiSelect"
// import XsPeriodPicker from "../../global/ui/xsPeriodPicker/xsPeriodPicker"
// import XsSimpleTextArea from "../../global/ui/xsTextArea/xsSimpleTextArea"
// import XsDateTimePicker from "../../global/ui/xsDateTimePicker/xsDateTimePicker"

function RequestForm(props) {
	const requiredAttachemnts = ["price"]
	const [editingMode, setEditingMode] = useState(false)
	const hooks = {
		onSubmit(values) {
			if (!values.callCode == "ZSP_123") {
				for (let index in requiredAttachemnts) {
					if (RequestFormStore[`dropedFiles${index}`].length < 1) {
						form.$("dropzone").invalidate("minimum")
						GlobalStore.setNotificationMessage("Žiadosť nemôže byť odoslaná bez prílohy!", null, "error")
					}
				}
			}

			if (isIP_ZS_122 || isIP_ZS2_123) {
				if (form.$("projectActivityTypeCode").value == "prjvavonkoneumetakvsroio") {
					if (
						form.$("eligibleActivityA1").value == false &&
						form.$("eligibleActivityA2").value == false &&
						form.$("eligibleActivityA3").value == false &&
						form.$("eligibleActivityA4").value == false
					) {
						form.$("eligibleActivityA1").invalidate("minimum")
					}
				}

				if (form.$("projectActivityTypeCode").value == "matabiomatzdrav") {
					if (
						form.$("eligibleActivityB1").value == false &&
						form.$("eligibleActivityB2").value == false &&
						form.$("eligibleActivityB3").value == false &&
						form.$("eligibleActivityB4").value == false &&
						form.$("eligibleActivityB5").value == false
					) {
						form.$("eligibleActivityB1").invalidate("minimum")
					}
				}

				if (form.$("projectActivityTypeCode").value == "liekyaformy") {
					if (
						form.$("eligibleActivityC1").value == false &&
						form.$("eligibleActivityC2").value == false &&
						form.$("eligibleActivityC3").value == false &&
						form.$("eligibleActivityC4").value == false
					) {
						form.$("eligibleActivityC1").invalidate("minimum")
					}
				}

				if (form.$("projectActivityTypeCode").value == "tridtlacabiotlac") {
					if (form.$("eligibleActivityD1").value == false) {
						form.$("eligibleActivityD1").invalidate("minimum")
					}
				}

				if (form.$("projectActivityTypeCode").value == "senzorydiagtechmonzdrav") {
					if (
						form.$("eligibleActivityE1").value == false &&
						form.$("eligibleActivityE2").value == false &&
						form.$("eligibleActivityE3").value == false &&
						form.$("eligibleActivityE4").value == false &&
						form.$("eligibleActivityE5").value == false
					) {
						form.$("eligibleActivityE1").invalidate("minimum")
					}
				}
			} else if (isIP_SK2_122 || isIP_ZS2_123) {
				if (form.$("eligibleAreaCode").value == "spracsurdovyrobsvyspridhod") {
					if (
						form.$("eligibleActivityIPSK2A1").value == false &&
						form.$("eligibleActivityIPSK2A2").value == false &&
						form.$("eligibleActivityIPSK2A3").value == false &&
						form.$("eligibleActivityIPSK2A4").value == false &&
						form.$("eligibleActivityIPSK2A5").value == false &&
						form.$("eligibleActivityIPSK2A6").value == false &&
						form.$("eligibleActivityIPSK2A7").value == false &&
						form.$("eligibleActivityIPSK2A8").value == false &&
						form.$("eligibleActivityIPSK2A9").value == false
					) {
						form.$("eligibleActivityIPSK2A1").invalidate("minimum")
					}
				}
				if (form.$("eligibleAreaCode").value == "zvysenergefektvhosp") {
					if (
						form.$("eligibleActivityIPSK2B1").value == false &&
						form.$("eligibleActivityIPSK2B2").value == false &&
						form.$("eligibleActivityIPSK2B3").value == false &&
						form.$("eligibleActivityIPSK2B4").value == false &&
						form.$("eligibleActivityIPSK2B5").value == false
					) {
						form.$("eligibleActivityIPSK2B1").invalidate("minimum")
					}
				}
				if (form.$("eligibleAreaCode").value == "systodolazdravlokalpotr") {
					if (
						form.$("eligibleActivityIPSK2C1").value == false &&
						form.$("eligibleActivityIPSK2C2").value == false &&
						form.$("eligibleActivityIPSK2C3").value == false &&
						form.$("eligibleActivityIPSK2C4").value == false &&
						form.$("eligibleActivityIPSK2C5").value == false
					) {
						form.$("eligibleActivityIPSK2C1").invalidate("minimum")
					}
				}
			} else if (!isZSP_123) {
				if (form.$("projectActivityTypeCode").value == "accinnconssvc" && form.$("callCode").value == "IP_SK_121") {
					if (
						form.$("activityTypeAddAC1").value == false &&
						form.$("activityTypeAddAC2").value == false &&
						form.$("activityTypeAddAC3").value == false &&
						form.$("activityTypeAddAC4").value == false
					) {
						form.$("activityTypeAddAC1").invalidate("minimum")
					}
				}

				if (form.$("projectActivityTypeCode").value == "resdevsvc" && form.$("callCode").value == "IP_SK_121") {
					if (
						form.$("activityTypeAddRE1").value == false &&
						form.$("activityTypeAddRE2").value == false &&
						form.$("activityTypeAddRE3").value == false
					) {
						form.$("activityTypeAddRE1").invalidate("minimum")
					}
				}
			}

			/*
			else if (isIP_SK3_122) {
				if (form.$("eligibleAreaCode").value == "sk3-122-A") {
					if (
						form.$("eligibleActivityIPSK3A1").value == false &&
						form.$("eligibleActivityIPSK3A2").value == false &&
						form.$("eligibleActivityIPSK3A3").value == false &&
						form.$("eligibleActivityIPSK3A4").value == false &&
						form.$("eligibleActivityIPSK3A5").value == false
					) {
						form.$("eligibleActivityIPSK3A1").invalidate("minimum")
					}
				}
				if (form.$("eligibleAreaCode").value == "sk3-122-B") {
					if (
						form.$("eligibleActivityIPSK3B1").value == false &&
						form.$("eligibleActivityIPSK3B2").value == false &&
						form.$("eligibleActivityIPSK3B3").value == false &&
						form.$("eligibleActivityIPSK3B4").value == false &&
						form.$("eligibleActivityIPSK3B5").value == false &&
						form.$("eligibleActivityIPSK3B6").value == false &&
						form.$("eligibleActivityIPSK3B7").value == false
					) {
						form.$("eligibleActivityIPSK3B1").invalidate("minimum")
					}
				}
			} 
			*/

			try {
				let statutars = JSON.parse(form.$("applicantChipForm").value)
				if (
					statutars.some((row) => {
						return isEmpty(row.name)
					})
				) {
					form.$("applicantChipForm").invalidate("minimum")
					GlobalStore.setNotificationMessage("Žiadosť nemôže byť odoslaná bez štatutára!", null, "error")
				}

				if (
					!statutars.some((row) => {
						return row.isStatutar == true
					})
				) {
					form.$("applicantChipForm").invalidate("minimum")
					GlobalStore.setNotificationMessage("Zaškrtnite aspoň jedného člena!", null, "error")
				}
			} catch (e) {
				logger(e)
				GlobalStore.setNotificationMessage("Žiadosť nemôže byť odoslaná bez štatutára!", null, "error")
				form.$("applicantChipForm").invalidate("minimum")
			}
		},
		onSuccess(form) {
			logger("SAVE", form.values())
			RequestFormStore.generateDataAndSave(form, "final", () => {
				RouterStore.push("/requests")
				GlobalStore.loading(false)
			})
		},
		onError(form) {
			logger("CHYBY:", form.errors())
			GlobalStore.setNotificationMessage("Skontrolujte povinné polia", null, "error")
		}
	}

	const [form] = useState(new MobxReactForm(fields.load(), {plugins: {dvr: dvr(validatorjs)}, hooks}))
	// const [consortiumRowNumber, setConsortiumRowNumber] = useState(0)

	const initNewForm = async () => {
		if (isEmpty(RequestFormStore.callId)) {
			RouterStore.push("/calls")
		} else {
			form.$("callId").value = RequestFormStore.callId
			form.$("callCode").value = RequestFormStore.callCode
			RequestFormStore.callId = null
			RequestFormStore.callCode = null
		}

		await RegistersCacheStore.checkRegisters("/request")

		//Pre ipcei req su ine validacie
		if (form.$("callCode").value == "IP_IPCEI_121") {
			RequestFormStore.handleFormForIpceiRequest(form)
		} else if (form.$("callCode").value == "IP_SK_121") {
			// validacie pre SK
			RequestFormStore.handleFormForSKRequest(form)
		} else if (form.$("callCode").value == "IP_ZS_122" || form.$("callCode").value == "IP_ZS2_123") {
			// validacie pre ZS
			RequestFormStore.handleFormForZSRequest(form)
		} else if (form.$("callCode").value == "IP_SK2_122" || form.$("callCode").value == "IP_SK3_122") {
			// validacie pre ZS
			RequestFormStore.handleFormForIPSK2(form)
		} else if (form.$("callCode").value == "ZSP_123") {
			// validacie pre Oznamenie
			RequestFormStore.handleFormForZSP_123(form)
		}

		//Predplnenie mena a ica
		RequestFormStore.loadCompanyInfo(form)
		const company = getUserCompanyInfo() || {}
		form.$("companyName").value = company.name
		form.$("ico").value = company.identifier

		RequestFormStore.defaultFormValues = form.values()
	}

	useEffect(() => {
		RequestFormStore.formRef = form
		RequestFormStore.resetDropzones()
		if (isNotEmpty(props.id)) {
			RequestFormStore.loadCodelistAndForm(form, props.id)
		} else {
			initNewForm()
		}
	}, [])

	// const handleDrop = (files, index, length) => {
	// 	for (let fileIndex in files) {
	// 		let file = files[fileIndex]
	// 		if (file.size > 20971520) {
	// 			GlobalStore.setNotificationMessage("Size limit exceeded!", null, "error")
	// 			continue
	// 		}
	// 		if (RequestFormStore[`dropedFiles${index}`].length >= length) {
	// 			GlobalStore.setNotificationMessage("Maximum number of files exceeded!", null, "error")
	// 			continue
	// 		}

	// 		if (index != "Other") {
	// 			if (index == 0) {
	// 				file.databaseType = "priceComparison"
	// 			}
	// 		} else {
	// 			file.databaseType = "General"
	// 		}
	// 		RequestFormStore[`dropedFiles${index}`].push(file)
	// 	}
	// }

	// const handleDropzonePreview = (attachmentIndex, dropzoneIndex) => {
	// 	var FileSaver = require("file-saver") // eslint-disable-line no-undef
	// 	FileSaver.saveAs(
	// 		RequestFormStore[`dropedFiles${dropzoneIndex}`][attachmentIndex],
	// 		isNotEmpty(form.$("zoIPNumber").value)
	// 			? form.$("zoIPNumber").value.replace("ZoIP", "CP")
	// 			: RequestFormStore[`dropedFiles${dropzoneIndex}`][attachmentIndex].name
	// 	)
	// }

	const handleDropzoneDelete = (attachmentIndex, dropzoneIndex) => {
		if (isSafe(RequestFormStore[`dropedFiles${dropzoneIndex}`][attachmentIndex]._id)) {
			RequestFormStore.deleteLoadedAttachemnt(
				RequestFormStore[`dropedFiles${dropzoneIndex}`][attachmentIndex]._id,
				dropzoneIndex,
				attachmentIndex
			)
		} else {
			RequestFormStore[`dropedFiles${dropzoneIndex}`].splice(attachmentIndex, 1)
		}
	}

	const isFormDisabled = () => {
		return Boolean(
			!editingMode || GlobalStore.isRegistrationClose()
			// ((form.$("status").value != "inp" && isNotEmpty(form.$("status").value)) || GlobalStore.isRegistrationClose()) &&
		)
	}

	const handleCorespondingAddressValidation = () => {
		if (form.$("companyAddressSameNo").value == true) {
			form.$("companyCorrespondenceRegion").set("rules", "required")
			form.$("companyCorrespondenceStreet").set("rules", "required")
			form.$("companyCorrespondenceCity").set("rules", "required")
			form.$("companyCorrespondenceStreetNumber").set("rules", "required")
			form.$("companyCorrespondencePSC").set("rules", "required|between:5,5")
		} else {
			form.$("companyCorrespondenceRegion").set("value", "")
			form.$("companyCorrespondenceStreet").set("value", "")
			form.$("companyCorrespondenceCity").set("value", "")
			form.$("companyCorrespondenceStreetNumber").set("value", "")
			form.$("companyCorrespondencePSC").set("value", "")

			form.$("companyCorrespondenceRegion").set("rules", "")
			form.$("companyCorrespondenceStreet").set("rules", "")
			form.$("companyCorrespondenceCity").set("rules", "")
			form.$("companyCorrespondenceStreetNumber").set("rules", "")
			form.$("companyCorrespondencePSC").set("rules", "")
		}
	}

	const resetAdditionalCheckboxes = () => {
		const fields = [
			"activityTypeAddAC1",
			"activityTypeAddAC2",
			"activityTypeAddAC3",
			"activityTypeAddAC4",
			"activityTypeAddRE1",
			"activityTypeAddRE2",
			"activityTypeAddRE3",
			"eligibleActivityA1",
			"eligibleActivityA2",
			"eligibleActivityA3",
			"eligibleActivityA4",
			"eligibleActivityB1",
			"eligibleActivityB2",
			"eligibleActivityB3",
			"eligibleActivityB4",
			"eligibleActivityB5",
			"eligibleActivityC1",
			"eligibleActivityC2",
			"eligibleActivityC3",
			"eligibleActivityC4",
			"eligibleActivityD1",
			"eligibleActivityE1",
			"eligibleActivityE2",
			"eligibleActivityE3",
			"eligibleActivityE4",
			"eligibleActivityE5",
			"eligibleActivityIPSK2A1",
			"eligibleActivityIPSK2A2",
			"eligibleActivityIPSK2A3",
			"eligibleActivityIPSK2A4",
			"eligibleActivityIPSK2A5",
			"eligibleActivityIPSK2A6",
			"eligibleActivityIPSK2A7",
			"eligibleActivityIPSK2A8",
			"eligibleActivityIPSK2A9",
			"eligibleActivityIPSK2B1",
			"eligibleActivityIPSK2B2",
			"eligibleActivityIPSK2B3",
			"eligibleActivityIPSK2B4",
			"eligibleActivityIPSK2B5",
			"eligibleActivityIPSK2C1",
			"eligibleActivityIPSK2C2",
			"eligibleActivityIPSK2C3",
			"eligibleActivityIPSK2C4",
			"eligibleActivityIPSK2C5",
			"eligibleActivityIPSK3A1",
			"eligibleActivityIPSK3A2",
			"eligibleActivityIPSK3A3",
			"eligibleActivityIPSK3A4",
			"eligibleActivityIPSK3A5",
			// "eligibleActivityIPSK3A6",
			// "eligibleActivityIPSK3A7",
			// "eligibleActivityIPSK3A8",
			// "eligibleActivityIPSK3A9",
			// "eligibleActivityIPSK3A10",
			"eligibleActivityIPSK3B1",
			"eligibleActivityIPSK3B2",
			"eligibleActivityIPSK3B3",
			"eligibleActivityIPSK3B4",
			"eligibleActivityIPSK3B5",
			"eligibleActivityIPSK3B6",
			"eligibleActivityIPSK3B7"
			// "eligibleActivityIPSK3C1",
			// "eligibleActivityIPSK3C2",
			// "eligibleActivityIPSK3C3",
			// "eligibleActivityIPSK3C4",
			// "eligibleActivityIPSK3C5"
		]

		fields.forEach((name) => {
			form.$(name).value = false
		})
	}

	const isIP_ZS_122 = form.$("callCode").value == "IP_ZS_122"
	const isIP_ZS2_123 = form.$("callCode").value == "IP_ZS2_123"
	const isIP_SK2_122 = form.$("callCode").value == "IP_SK2_122"
	const isIP_SK3_122 = form.$("callCode").value == "IP_SK3_122"
	const isZSP_123 = form.$("callCode").value == "ZSP_123"

	return (
		<React.Fragment>
			<Container maxWidth="lg">
				<Container maxWidth="lg" className="request_form_fields">
					<Grid container spacing={2} className="pt-5">
						<Grid item xs={12}>
							<Typography variant="h4" align="center">
								Žiadosť o základné skupinové poradenstvo (ŽoZSP)
							</Typography>
						</Grid>
						<Grid item xs={12} className="mb-8">
							<Typography variant="h6" align="center">
								{form.$("zoIPNumber").value}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="h5">Identifikácia oprávneného žiadateľa</Typography>
						</Grid>
						<Grid item xs={12} md={6} lg={3} data-tip data-for="companyName">
							<XsInput field={form.$("companyName")} disabled={true} />
							<ReactTooltip id="companyName">
								Obchodné meno spoločnosti už nie je možné zmeniť. Prípadná oprava bude vykonaná administrátorom SIEA pri
								posúdení podmienok ŽoIP.
							</ReactTooltip>
						</Grid>
						<Grid item xs={12} md={6} lg={3} data-tip data-for="ico">
							<XsNumberInput field={form.$("ico")} step={"1"} min={"1"} disabled={true} />
							<ReactTooltip id="ico">
								IČO spoločnosti už nie je možné zmeniť. Prípadná oprava bude vykonaná administrátorom SIEA pri posúdení
								podmienok ŽoIP.
							</ReactTooltip>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsNumberInput field={form.$("dic")} step={"1"} min={"1"} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12} md={6} lg={3} data-tip data-for="icdph">
							<XsInput field={form.$("icdph")} disabled={isFormDisabled()} />
							<ReactTooltip id="icdph">
								V prípade, že máte IČ DPH, je potrebné ho uviesť. V prípade, že IČ DPH nemáte, je potrebné uviesť do
								bunky IČ DPH nulu.
							</ReactTooltip>
						</Grid>

						<Grid item xs={12} md={6} lg={3}>
							<XsSearchSelect
								field={form.$("companyDesc")}
								disabled={isFormDisabled()}
								items={GlobalStore.CL["companyType"]}
							/>
						</Grid>

						<Grid item xs={12} md={6} lg={3} /*data-tip data-for="companyLegalForm"*/>
							<XsSearchSelect
								field={form.$("companyLegalForm")}
								disabled={isFormDisabled()}
								items={GlobalStore.CL["legalForm"]}
								specialTooltip={
									"Ak ste vybrali možnosť Iný typ právnej formy, doplňte prosím informáciu o inom type v časti žiadosti Predmet ŽoIP - Opis inovačného projektu v polí Doplňujúce údaje."
								}
							/>
							{/* <ReactTooltip id="companyLegalForm">
								Ak ste vybrali možnosť Iný typ právnej formy, doplňte prosím informáciu o inom type v časti žiadosti
								Predmet ŽoIP - Opis inovačného projektu v polí Doplňujúce údaje.
							</ReactTooltip> */}
						</Grid>
						{!isZSP_123 && (
							<Grid item xs={12} md={6} lg={6}>
								<XsInput field={form.$("companyIBAN")} disabled={isFormDisabled()} regex={(val) => isIBANValid(val)} />
							</Grid>
						)}
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Typography variant="h6">Adresa sídla podnikania</Typography>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsSearchSelect
								field={form.$("companyRegion")}
								items={
									//Okrem BB to ma vlastnu ziadost
									form.$("callCode").value == "IP_SK_121" && isSafe(GlobalStore.CL["region"])
										? GlobalStore.CL["region"].filter((row) => row.code != "SK032")
										: GlobalStore.CL["region"]
								}
								required
								disabled={isFormDisabled()}
								onChange={() => {
									if (form.$("callCode").value == "IP_SK_121") {
										RequestFormStore.checkRegionAvailability(form)
									}
									// form.$("companyCounty").value = ""
									form.$("companyCity").value = ""
								}}
							/>
						</Grid>
						{/* <Grid item xs={12} md={6} lg={3}>
							<XsSearchSelect
								field={form.$("companyCounty")}
								items={
									isNotEmpty(form.$("companyRegion").value) && isSafe(GlobalStore.CL["addressCounty"])
										? GlobalStore.CL["addressCounty"].filter((row) => {
												return row.region == form.$("companyRegion").value
										  })
										: GlobalStore.CL["addressCounty"]
								}
								required
								disabled={isFormDisabled()}
								onChange={() => {
									form.$("companyCity").value = ""
								}}
							/>
						</Grid> */}
						<Grid item xs={12} md={6} lg={3}>
							<XsAutocomplete
								field={form.$("companyCity")}
								minLengthForSearch="1"
								api={api.loadEntityCity}
								inputRenderer={(obj) => obj.name_ext}
								saveValue={(obj) => obj.code}
								disabled={isFormDisabled()}
								addSpecialFilter={() => {
									// if (isNotEmpty(form.$("companyCounty").value)) {
									// 	return [
									// 		{
									// 			associated_column: "county",
									// 			predicate: "=",
									// 			values: [
									// 				{
									// 					id_value: form.$("companyCounty").value
									// 				}
									// 			]
									// 		}
									// 	]
									// }
								}}
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsInput field={form.$("companyStreet")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsInput field={form.$("companyStreetNumber")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsNumberInput field={form.$("companyPSC")} min={"1"} step={"1"} disabled={isFormDisabled()} />
						</Grid>
						{!isZSP_123 && (
							<React.Fragment>
								<Grid item xs={12}>
									<Typography variant="body2">Je adresa sídla zhodná s korešpondenčnou adresou?</Typography>
								</Grid>
								<Grid item xs={12} md={6} lg={3}>
									<XsCheckbox
										field={form.$("companyAddressSameYes")}
										disabled={isFormDisabled()}
										onChange={() => {
											form.$("companyAddressSameNo").value = false
											handleCorespondingAddressValidation()
										}}
									/>
								</Grid>
								<Grid item xs={12} md={6} lg={3}>
									<XsCheckbox
										field={form.$("companyAddressSameNo")}
										disabled={isFormDisabled()}
										onChange={() => {
											form.$("companyAddressSameYes").value = false
											handleCorespondingAddressValidation()
										}}
									/>
								</Grid>
							</React.Fragment>
						)}
						{form.$("companyAddressSameNo").value == true && (
							<React.Fragment>
								<Grid item xs={12}></Grid>
								<Grid item xs={12} md={6} lg={3}>
									<XsSearchSelect
										field={form.$("companyCorrespondenceRegion")}
										items={GlobalStore.CL["region"]}
										// required
										disabled={isFormDisabled()}
										onChange={() => {
											form.$("companyCorrespondenceCity").value = ""
										}}
									/>
								</Grid>
								<Grid item xs={12} md={6} lg={3}>
									<XsAutocomplete
										field={form.$("companyCorrespondenceCity")}
										minLengthForSearch="1"
										api={api.loadEntityCity}
										inputRenderer={(obj) => obj.name_ext}
										saveValue={(obj) => obj.code}
										disabled={isFormDisabled()}
									/>
								</Grid>
								<Grid item xs={12} md={6} lg={3}>
									<XsInput field={form.$("companyCorrespondenceStreet")} disabled={isFormDisabled()} />
								</Grid>
								<Grid item xs={12} md={6} lg={3}>
									<XsInput field={form.$("companyCorrespondenceStreetNumber")} disabled={isFormDisabled()} />
								</Grid>
								<Grid item xs={12} md={6} lg={3}>
									<XsNumberInput
										field={form.$("companyCorrespondencePSC")}
										min={"1"}
										step={"1"}
										disabled={isFormDisabled()}
									/>
								</Grid>
							</React.Fragment>
						)}
						<Grid item xs={12}>
							<Typography variant="h5">Štatutárny orgán žiadateľa / Fyzická osoba žiadateľa</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="body2">
								V prípade viacerých členov štatutárneho orgánu ich môžete pridať pomocou kliknutia na znamienko +
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<XsChipForm
								field={form.$("applicantChipForm")}
								label={" "}
								configuration={{
									header: {
										chipsRenderer: (key, dataRow, clickHandler) => (
											<XsChip
												key={key}
												label={
													isSafe(dataRow["name"]) && isNotEmpty(dataRow["name"]["value"])
														? dataRow["name"]["value"]
														: ""
												}
												onClick={(e) => clickHandler(false, e, key)}
											/>
										),
										addButtonRenderer: (clickHandler) => (
											<XsIconButton className="xs-default xs-outline" icon={<AddIcon />} onClick={clickHandler} />
										)
									},
									columns: [
										{
											name: "name",
											label: "Titul, Meno, Priezvisko, Titul",
											width: 5,
											validationRule: "required",
											renderer: (field) => <XsInput field={field} disabled={isFormDisabled()} />
										},
										{
											name: "isStatutar",
											label: "ŽoZSP v INOWA odosiela",
											width: 5,
											validationRule: "required",
											type: "checkbox",
											renderer: (field) => (
												<XsCheckbox
													tooltipId={
														"Zaškrtnutím označte iba tých členov štatutárneho orgánu / Fyzickej osoby žiadateľa, ktorí odosielajú túto žiadosť v rámci INOWA."
													}
													field={field}
													disabled={isFormDisabled()}
												/>
											)
										}
									],
									options: {
										disabled: isFormDisabled(),
										disableFirstRowDelete: true,
										isFormMode: true,
										isModalMode: false,
										hideButtons: true,
										startOpen: true,
										maxLength: 5,
										hideHeader: true,
										onFormOpen: () => {},
										onFormClose: () => {},
										onFormSave: (vals) => {
											RequestFormStore.applicantChipForm = vals
										}
									},
									data: RequestFormStore.applicantChipForm
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="h5" data-tip data-for="contactPersonText">
								Kontaktná osoba žiadateľa
							</Typography>
							{isZSP_123 ? (
								<ReactTooltip id="contactPersonText">
									Osoba, ktorá bude kontaktovaná ohľadom konania podujatia, resp. prípadného oznámenia o nesplnení
									podmienok umožňujúcich účasť na podujatí.
								</ReactTooltip>
							) : (
								<ReactTooltip id="contactPersonText">
									Osoba, ktorá zabezpečuje komunikáciu vo veci všetkých aktivít národného projektu ZIVSE, ktorých sa
									bude Váš podnik zúčastňovať.
								</ReactTooltip>
							)}
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsInput field={form.$("contactPersonName")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsInput field={form.$("contactPersonPhone")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsInput field={form.$("contactPersonMail")} disabled={isFormDisabled()} />
						</Grid>
						{!isZSP_123 && (
							<Grid item xs={12}>
								<Typography variant="h5">Predmet ŽoIP - Opis inovačného projektu</Typography>
							</Grid>
						)}
						{(isIP_ZS_122 || isIP_ZS2_123) && (
							<Grid item xs={12}>
								<XsInput field={form.$("skDomain")} disabled={true} />
							</Grid>
						)}
						{/* {isIP_SK2_122 && (
							<Grid item xs={12}>
								<XsInput field={form.$("skDomainSK2")} disabled={true} />
							</Grid>
						)} */}
						{!isZSP_123 && (
							<Grid item xs={12} md={6} lg={4} data-tip data-for="projectName">
								<XsInput
									field={form.$("projectName")}
									disabled={isFormDisabled() || form.$("callCode").value == "IP_IPCEI_121"}
									maxLength={300}
								/>
								{form.$("callCode").value != "IP_IPCEI_121" && !(isIP_ZS_122 || isIP_ZS2_123) && (
									<ReactTooltip id="projectName">
										Doplňte názov projektu, ktorý má byť predmetom realizácie v prípade schválenia ŽoIP
									</ReactTooltip>
								)}
								{isIP_ZS_122 && (
									<ReactTooltip id="projectName">
										{`Uveďte totožne s Opisom inovačného projektu, ktorý ste vyplnili vo formáte .xlsx a zaslali ako prílohu
									e-mailu "Oslovenie potenciálnych riešiteľov na predloženie cenovej ponuky".`}
									</ReactTooltip>
								)}
								{isIP_ZS2_123 && (
									<ReactTooltip id="projectName">
										{`Uveďte totožne s Opisom inovačného projektu, ktorý ste vyplnili vo formáte .docx a zaslali ako prílohu e-mailu "Oslovenie potenciálnych riešiteľov na predloženie cenovej ponuky".`}
									</ReactTooltip>
								)}
							</Grid>
						)}
						{isIP_SK3_122 ? (
							<React.Fragment>
								<Hidden only={["sm", "xs"]}>
									<Grid item xs={1} md={6} lg={9}>
										<div></div>
									</Grid>
								</Hidden>
								<Grid item xs={12} md={6} lg={4} data-tip data-for="projectEligibleArea">
									<XsSearchSelect
										field={form.$("eligibleArea")}
										disabled={isFormDisabled()}
										items={
											isSafe(GlobalStore.CL["activityType"])
												? GlobalStore.CL["activityType"].filter((row) =>
														row.call_type ? row.call_type.code == "IP_SK3_122" : false
												  )
												: []
										}
										onChange={(value) => {
											resetAdditionalCheckboxes()
											const type = GlobalStore.CL["activityType"].find((row) => row._id == value)
											form.$("eligibleAreaCode").value = type.code
										}}
									/>
									<ReactTooltip id="projectEligibleArea">
										{`Uveďte totožne s Opisom inovačného projektu, ktorý ste vyplnili vo formáte .xlsx a zaslali ako prílohu e-mailu "Oslovenie potenciálnych riešiteľov na predloženie cenovej ponuky".`}
									</ReactTooltip>
								</Grid>
							</React.Fragment>
						) : isZSP_123 ? (
							<React.Fragment></React.Fragment>
						) : isIP_SK2_122 ? (
							<React.Fragment>
								<Hidden only={["sm", "xs"]}>
									<Grid item xs={1} md={6} lg={9}>
										<div></div>
									</Grid>
								</Hidden>
								<Grid item xs={12} md={6} lg={4} data-tip data-for="projectEligibleArea">
									<XsSearchSelect
										field={form.$("eligibleArea")}
										disabled={isFormDisabled()}
										items={
											isSafe(GlobalStore.CL["activityType"])
												? GlobalStore.CL["activityType"].filter((row) =>
														row.call_type ? row.call_type.code == "IP_SK2_122" : false
												  )
												: []
										}
										onChange={(value) => {
											resetAdditionalCheckboxes()
											const type = GlobalStore.CL["activityType"].find((row) => row._id == value)
											form.$("eligibleAreaCode").value = type.code
										}}
									/>
									<ReactTooltip id="projectEligibleArea">
										{`Uveďte totožne s Opisom inovačného projektu, ktorý ste vyplnili vo formáte .xlsx a zaslali ako prílohu e-mailu "Oslovenie potenciálnych riešiteľov na predloženie cenovej ponuky".`}
									</ReactTooltip>
								</Grid>
							</React.Fragment>
						) : isIP_ZS_122 ? (
							<React.Fragment>
								<Hidden only={["sm", "xs"]}>
									<Grid item xs={1} md={6} lg={9}>
										<div></div>
									</Grid>
								</Hidden>
								<Grid item xs={12} md={6} lg={4} data-tip data-for="projectEligibleArea">
									<XsSearchSelect
										field={form.$("eligibleArea")}
										disabled={isFormDisabled()}
										items={
											isSafe(GlobalStore.CL["activityType"])
												? GlobalStore.CL["activityType"].filter((row) =>
														row.call_type ? row.call_type.code == "IP_ZS_122" : false
												  )
												: []
										}
										onChange={(value) => {
											resetAdditionalCheckboxes()
											const type = GlobalStore.CL["activityType"].find((row) => row._id == value)
											form.$("eligibleAreaCode").value = type.code
										}}
									/>

									<ReactTooltip id="projectEligibleArea">
										{`Uveďte totožne s Opisom inovačného projektu, ktorý ste vyplnili vo formáte .xlsx a zaslali ako prílohu e-mailu "Oslovenie potenciálnych riešiteľov na predloženie cenovej ponuky".`}
									</ReactTooltip>
								</Grid>
							</React.Fragment>
						) : isIP_ZS2_123 ? (
							<React.Fragment>
								<Hidden only={["sm", "xs"]}>
									<Grid item xs={1} md={6} lg={9}>
										<div></div>
									</Grid>
								</Hidden>
								<Grid item xs={12} md={6} lg={4} data-tip data-for="projectEligibleArea">
									<XsSearchSelect
										field={form.$("eligibleArea")}
										disabled={isFormDisabled()}
										items={
											isSafe(GlobalStore.CL["activityType"])
												? GlobalStore.CL["activityType"].filter((row) =>
														row.call_type ? row.call_type.code == "IP_ZS2_123" : false
												  )
												: []
										}
										onChange={(value) => {
											resetAdditionalCheckboxes()
											const type = GlobalStore.CL["activityType"].find((row) => row._id == value)
											form.$("eligibleAreaCode").value = type.code
										}}
									/>
									<ReactTooltip id="projectEligibleArea">
										{`Uveďte totožne s Opisom inovačného projektu, ktorý ste vyplnili vo formáte .docx a zaslali ako prílohu e-mailu "Oslovenie potenciálnych riešiteľov na predloženie cenovej ponuky".`}
									</ReactTooltip>
								</Grid>
							</React.Fragment>
						) : (
							<React.Fragment>
								<Hidden only={["sm", "xs"]}>
									<Grid item xs={1} md={6} lg={9}>
										<div></div>
									</Grid>
								</Hidden>
								<Grid item xs={12} md={6} lg={4} data-tip data-for="projectActivityType">
									<XsSearchSelect
										field={form.$("projectActivityType")}
										items={
											isSafe(GlobalStore.CL["activityType"])
												? GlobalStore.CL["activityType"].filter((row) =>
														row.call_type ? row.call_type.code == "IP_SK_121" : false
												  )
												: []
										}
										disabled={isFormDisabled() || form.$("callCode").value == "IP_IPCEI_121"}
										onChange={(value) => {
											resetAdditionalCheckboxes()
											const type = GlobalStore.CL["activityType"].find((row) => row._id == value)
											form.$("projectActivityTypeCode").value = type.code
										}}
									/>
									{form.$("callCode").value != "IP_IPCEI_121" && (
										<ReactTooltip id="projectActivityType">Pozrite príručku s opisom oprávnených aktivít</ReactTooltip>
									)}
								</Grid>
							</React.Fragment>
						)}
						{form.$("callCode").value == "IP_SK_121" && (
							<React.Fragment>
								{form.$("projectActivityTypeCode").value == "accinnconssvc" && (
									<React.Fragment>
										<Grid item xs={12}>
											<Typography variant="h6">Poradenské služby zamerané na akceleráciu inovácií*</Typography>
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("activityTypeAddAC1")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("activityTypeAddAC2")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("activityTypeAddAC3")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("activityTypeAddAC4")} disabled={isFormDisabled()} />
										</Grid>
									</React.Fragment>
								)}
								{form.$("projectActivityTypeCode").value == "resdevsvc" && (
									<React.Fragment>
										<Grid item xs={12}>
											<Typography variant="h6">Služby spojené s výskumom a vývojom*</Typography>
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("activityTypeAddRE1")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("activityTypeAddRE2")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("activityTypeAddRE3")} disabled={isFormDisabled()} />
										</Grid>
									</React.Fragment>
								)}
							</React.Fragment>
						)}
						{form.$("callCode").value == "IP_IPCEI_121" && (
							<React.Fragment>
								<Grid item xs={12} md={12} lg={12}>
									<Typography variant="h6">Oblasť poradenstva:</Typography>
								</Grid>
								<Grid item xs={12} md={12} lg={12}>
									<XsCheckbox
										field={form.$("ipceiAdvice1")}
										disabled={isFormDisabled()}
										onChange={() => {
											form.$("ipceiAdvice1").value = true
											form.$("ipceiAdvice2").value = false
										}}
									/>
								</Grid>
								<Grid item xs={12} md={12} lg={12}>
									<XsCheckbox
										field={form.$("ipceiAdvice2")}
										disabled={isFormDisabled()}
										onChange={() => {
											form.$("ipceiAdvice2").value = true
											form.$("ipceiAdvice1").value = false
										}}
									/>
								</Grid>
							</React.Fragment>
						)}
						{isIP_ZS_122 && (
							<React.Fragment>
								{isNotEmpty(form.$("eligibleAreaCode").value) && (
									<React.Fragment>
										<Grid item xs={12} data-tip data-for="eligibleActivityHeader">
											<Typography variant="h6">Oprávnené aktivity*</Typography>
											{isIP_ZS2_123 ? (
												<ReactTooltip id="projectEligibleArea">
													{`Uveďte totožne s Opisom inovačného projektu, ktorý ste vyplnili vo formáte .docx a zaslali ako prílohu e-mailu "Oslovenie potenciálnych riešiteľov na predloženie cenovej ponuky".`}
												</ReactTooltip>
											) : (
												<ReactTooltip id="eligibleActivityHeader">
													{`Uveďte totožne s Opisom inovačného projektu, ktorý ste vyplnili vo formáte .xlsx a zaslali ako prílohu e-mailu "Oslovenie potenciálnych riešiteľov na predloženie cenovej ponuky"`}
												</ReactTooltip>
											)}
										</Grid>
									</React.Fragment>
								)}
								{form.$("eligibleAreaCode").value == "prjvavonkoneumetakvsroio" && (
									<React.Fragment>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityA1")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityA2")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityA3")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityA4")} disabled={isFormDisabled()} />
										</Grid>
									</React.Fragment>
								)}
								{form.$("eligibleAreaCode").value == "matabiomatzdrav" && (
									<React.Fragment>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityB1")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityB2")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityB3")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityB4")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityB5")} disabled={isFormDisabled()} />
										</Grid>
									</React.Fragment>
								)}
								{form.$("eligibleAreaCode").value == "liekyaformy" && (
									<React.Fragment>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityC1")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityC2")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityC3")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityC4")} disabled={isFormDisabled()} />
										</Grid>
									</React.Fragment>
								)}
								{form.$("eligibleAreaCode").value == "tridtlacabiotlac" && (
									<React.Fragment>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityD1")} disabled={isFormDisabled()} />
										</Grid>
									</React.Fragment>
								)}
								{form.$("eligibleAreaCode").value == "senzorydiagtechmonzdrav" && (
									<React.Fragment>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityE1")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityE2")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityE3")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityE4")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityE5")} disabled={isFormDisabled()} />
										</Grid>
									</React.Fragment>
								)}
							</React.Fragment>
						)}
						{isIP_ZS2_123 && (
							<React.Fragment>
								{isNotEmpty(form.$("eligibleAreaCode").value) && (
									<React.Fragment>
										<Grid item xs={12} data-tip data-for="eligibleActivityHeader">
											<Typography variant="h6">Oprávnené aktivity*</Typography>
											{isIP_ZS2_123 ? (
												<ReactTooltip id="projectEligibleArea">
													{`Uveďte totožne s Opisom inovačného projektu, ktorý ste vyplnili vo formáte .docx a zaslali ako prílohu e-mailu "Oslovenie potenciálnych riešiteľov na predloženie cenovej ponuky".`}
												</ReactTooltip>
											) : (
												<ReactTooltip id="eligibleActivityHeader">
													{`Uveďte totožne s Opisom inovačného projektu, ktorý ste vyplnili vo formáte .xlsx a zaslali ako prílohu e-mailu "Oslovenie potenciálnych riešiteľov na predloženie cenovej ponuky"`}
												</ReactTooltip>
											)}
										</Grid>
									</React.Fragment>
								)}
								{form.$("eligibleAreaCode").value == "zs2-123-A" && (
									<React.Fragment>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityA1")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityA2")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityA3")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityA4")} disabled={isFormDisabled()} />
										</Grid>
									</React.Fragment>
								)}
								{form.$("eligibleAreaCode").value == "zs2-123-B" && (
									<React.Fragment>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityB1")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityB2")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityB3")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityB4")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityB5")} disabled={isFormDisabled()} />
										</Grid>
									</React.Fragment>
								)}
								{form.$("eligibleAreaCode").value == "zs2-123-C" && (
									<React.Fragment>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityC1")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityC2")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityC3")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityC4")} disabled={isFormDisabled()} />
										</Grid>
									</React.Fragment>
								)}
								{form.$("eligibleAreaCode").value == "zs2-123-D" && (
									<React.Fragment>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityD1")} disabled={isFormDisabled()} />
										</Grid>
									</React.Fragment>
								)}
								{form.$("eligibleAreaCode").value == "zs2-123-E" && (
									<React.Fragment>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityE1")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityE2")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityE3")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityE4")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityE5")} disabled={isFormDisabled()} />
										</Grid>
									</React.Fragment>
								)}
							</React.Fragment>
						)}
						{isIP_SK2_122 && (
							<React.Fragment>
								{isNotEmpty(form.$("eligibleAreaCode").value) && (
									<React.Fragment>
										<Grid item xs={12} data-tip data-for="eligibleActivityHeader">
											<Typography variant="h6">Oprávnené aktivity*</Typography>
											<ReactTooltip id="eligibleActivityHeader">
												{`Uveďte totožne s Opisom inovačného projektu, ktorý ste vyplnili vo formáte .xlsx a zaslali ako prílohu e-mailu "Oslovenie potenciálnych riešiteľov na predloženie cenovej ponuky"`}
											</ReactTooltip>
										</Grid>
									</React.Fragment>
								)}
								{form.$("eligibleAreaCode").value == "spracsurdovyrobsvyspridhod" && (
									<React.Fragment>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityIPSK2A1")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityIPSK2A2")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityIPSK2A3")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityIPSK2A4")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityIPSK2A5")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityIPSK2A6")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityIPSK2A7")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityIPSK2A8")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityIPSK2A9")} disabled={isFormDisabled()} />
										</Grid>
									</React.Fragment>
								)}
								{form.$("eligibleAreaCode").value == "zvysenergefektvhosp" && (
									<React.Fragment>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityIPSK2B1")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityIPSK2B2")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityIPSK2B3")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityIPSK2B4")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityIPSK2B5")} disabled={isFormDisabled()} />
										</Grid>
									</React.Fragment>
								)}
								{form.$("eligibleAreaCode").value == "systodolazdravlokalpotr" && (
									<React.Fragment>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityIPSK2C1")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityIPSK2C2")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityIPSK2C3")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityIPSK2C4")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityIPSK2C5")} disabled={isFormDisabled()} />
										</Grid>
									</React.Fragment>
								)}
							</React.Fragment>
						)}
						{isIP_SK3_122 && (
							<React.Fragment>
								{isNotEmpty(form.$("eligibleAreaCode").value) && (
									<React.Fragment>
										<Grid item xs={12} data-tip data-for="eligibleActivityHeader">
											<Typography variant="h6">Oprávnené aktivity*</Typography>
											<ReactTooltip id="eligibleActivityHeader">
												{`Uveďte totožne s Opisom inovačného projektu, ktorý ste vyplnili vo formáte .xlsx a zaslali ako prílohu e-mailu "Oslovenie potenciálnych riešiteľov na predloženie cenovej ponuky"`}
											</ReactTooltip>
										</Grid>
									</React.Fragment>
								)}
								{form.$("eligibleAreaCode").value == "sk3-122-A" && (
									<React.Fragment>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityIPSK3A1")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityIPSK3A2")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityIPSK3A3")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityIPSK3A4")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityIPSK3A5")} disabled={isFormDisabled()} />
										</Grid>
										{/* <Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityIPSK3A6")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityIPSK3A7")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityIPSK3A8")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityIPSK3A9")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityIPSK3A10")} disabled={isFormDisabled()} />
										</Grid> */}
									</React.Fragment>
								)}
								{form.$("eligibleAreaCode").value == "sk3-122-B" && (
									<React.Fragment>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityIPSK3B1")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityIPSK3B2")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityIPSK3B3")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityIPSK3B4")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityIPSK3B5")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityIPSK3B6")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityIPSK3B7")} disabled={isFormDisabled()} />
										</Grid>
									</React.Fragment>
								)}
								{/* {form.$("eligibleAreaCode").value == "systodolazdravlokalpotr" && (
									<React.Fragment>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityIPSK2C1")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityIPSK2C2")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityIPSK2C3")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityIPSK2C4")} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("eligibleActivityIPSK2C5")} disabled={isFormDisabled()} />
										</Grid>
									</React.Fragment>
								)} */}
							</React.Fragment>
						)}
						<Hidden only={["sm", "xs"]}>
							<Grid item xs={1} md={6} lg={9}>
								<div></div>
							</Grid>
						</Hidden>
						<Grid item xs={12} md={12} lg={12}>
							{(form.$("callCode").value == "IP_BBSK_121" ||
								form.$("callCode").value == "IP_SK_121" ||
								isIP_ZS_122 ||
								isIP_ZS2_123 ||
								isIP_SK2_122 ||
								isIP_SK3_122) && (
								<XsTextArea
									field={form.$("projectDescAndTarget")}
									disabled={isFormDisabled() || form.$("callCode").value == "IP_IPCEI_121"}
									maxLength={
										isIP_ZS_122 || isIP_ZS2_123
											? 3000
											: form.$("callCode").value == "IP_SK_121" || isIP_SK2_122 || isIP_SK3_122
											? 1500
											: 1000
									}
									onChange={() => {
										if (form.$("projectDescAndTarget").value.includes("<img src=")) {
											GlobalStore.setNotificationMessage("Vložte vstup bez obrázka!", null, "error")
											form.$("projectDescAndTarget").value = ""
										}
									}}
									tooltip={
										isIP_SK2_122 || isIP_SK3_122
											? renderHTML(
													`Povinne definujte cieľ projektu a aké budú jeho prínosy. Definujte súlad Inovačného projektu s definovanou oblasťou<br /> a aktivitou/aktivitami. Popíšte činnosti prostredníctvom ktorých sa bude realizovať oprávnená oblasť<br /> a aktivita/aktivity (Pre všetky oprávnené aktivity je akceptovaná realizácia výstupov vo forme, štúdie, analýzy, výskumnej správy,<br /> postupov alebo procesov, technickej alebo výkresovej dokumentácie nových/inovovaných produktov a prípadné dodanie<br /> ich modelov alebo prototypov a funkčných vzoriek). Stručne popíšte riešenie, uveďte ako bude riešenie fungovať v praxi<br /> po zrealizovaní projektu. V čom je vaše riešenie unikátne a inovatívne a aký prínos inovácia prináša pre žiadateľa/zákazníka?`
											  )
											: isIP_ZS_122
											? renderHTML(
													`Uveďte text totožný s Opisom inovačného projektu, ktorý ste vyplnili vo formáte .xlsx a zaslali ako prílohu<br />
													e-mailu "Oslovenie potenciálnych riešiteľov na predloženie cenovej ponuky".Povinne definujte cieľ projektu a aké budú <br />
													jeho prínosy a jasne formulujte, aký problém bude vyriešený. Definujte súlad Inovačného projektu s doménou <br />
													SK RIS 2021+ a definovanou oblasťou a aktivitou.Popíšte oprávnené činnosti prostredníctvom ktorých sa bude realizovať<br />
													oprávnená oblasť a aktivity (Pre všetky oprávnené aktivity je akceptovaná realizácia výstupov vo forme: štúdie; analýzy; <br />
													výskumnej správy; postupov alebo procesov; technickej alebo výkresovej dokumentácie nových/inovovaných produktov a prípadné dodanie <br />
													ich modelov alebo prototypov a funkčných vzoriek). Stručne popíšte riešenie, uveďte ako bude riešenie fungovať v praxi po<br />
													zrealizovaní projektu. V čom je vaše riešenie unikátne a inovatívne?
													`
											  )
											: isIP_ZS2_123
											? renderHTML(
													`Uveďte totožne s Opisom inovačného projektu, ktorý ste vyplnili vo formáte .docx a zaslali ako prílohu<br />
													e-mailu "Oslovenie potenciálnych riešiteľov na predloženie cenovej ponuky".<br /><br />
													Povinne definujte cieľ projektu a aké budú jeho prínosy. Definujte súlad Inovačného projektu s definovanou oblasťou a aktivitou/aktivitami.<br />
													Popíšte činnosti prostredníctvom ktorých sa bude realizovať oprávnená oblasť a aktivita/aktivity (Pre všetky oprávnené aktivity je<br />
													akceptovaná realizácia výstupov vo forme: štúdie, analýzy, výskumnej správy, postupov alebo procesov, technickej alebo výkresovej<br />
													dokumentácie nových/inovovaných produktov a prípadné dodanie ich modelov alebo prototypov a funkčných vzoriek). Stručne popíšte<br />
													riešenie, uveďte ako bude riešenie fungovať v praxi po zrealizovaní projektu. V čom je vaše riešenie unikátne a inovatívne a aký prínos<br />
													inovácia prináša pre žiadateľa/zákazníka?
												`
											  )
											: form.$("callCode").value == "IP_SK_121"
											? renderHTML(`Povinne definujte cieľ projektu a aké budú jeho prínosy a jasne formulujte, aký problém bude vyriešený. <br />
											Definujte súlad Inovačného projektu so zvolenou kategóriou oprávnených aktivít a kategóriou oprávnených výdavkov IP. <br />
											Stručne popíšte riešenie, uveďte ako bude riešenie fungovať v praxi po zrealizovaní projektu. Je vaše riešenie v niečom unikátne a inovatívne?`)
											: renderHTML(`Definujete, čo je cieľom projektu a jasne formulujte, aký problém bude vyriešený a aké budú jeho prínosy. <br />
									Definujte súlad Inovačného projektu so zvolenou kategóriou oprávnených aktivít a kategóriou oprávnených výdavkov IP. <br />
									Stručne popíšte riešenie, uveďte ako bude riešenie fungovať v praxi po zrealizovaní projektu. <br />
									Je vaše riešenie v niečom unikátne a inovatívne?`)
									}
								/>
							)}

							{form.$("callCode").value == "IP_IPCEI_121" && (
								<XsTextArea
									field={form.$("projectAdditionalInfo")}
									disabled={isFormDisabled() /*|| form.$("callCode").value == "IP_IPCEI_121"*/}
									onChange={() => {
										if (form.$("projectAdditionalInfo").value.includes("<img src=")) {
											GlobalStore.setNotificationMessage("Vložte vstup bez obrázka!", null, "error")
											form.$("projectAdditionalInfo").value = ""
										}
									}}
									tooltip={renderHTML(`<b>V prípade výberu ktorejkoľvek oblasti poradenstva</b> je tiež potrebné špecifikovať podmienky realizácie zmluvy (najmä lehotu plnenia a miesto realizácie,<br />
										pričom lehota plnenia nemôže prekročiť 6 mesiacov od vystavenia IP). Realizáciu Inovačného projektu je možné začať už pred vyhlásením Výzvy.<br />
									 <br />
									 <b>V prípade výberu oblasti poradenstva 2</b>: Poradenské služby zamerané na tvorbu dokumentácie pre proces notifikácie IPCEI - Vybrané jednotlivé kapitoly dokumentu Project Portfolio,<br />
									  alebo Funding Gap Questionnaire podľa výberu a potreby žiadateľa, je potrebné uviesť konkrétne vybrané časti Project portfólia.
									 <br />`)}
								/>
							)}
						</Grid>
						{(form.$("callCode").value == "IP_BBSK_121" ||
							form.$("callCode").value == "IP_SK_121" ||
							isIP_ZS_122 ||
							isIP_ZS2_123 ||
							isIP_SK2_122 ||
							isIP_SK3_122) && (
							<React.Fragment>
								<Grid item xs={12} md={12} lg={12}>
									<XsTextArea
										field={form.$("projectBasicParameters")}
										maxLength={
											isIP_ZS_122 || isIP_ZS2_123
												? 3000
												: form.$("callCode").value == "IP_SK_121" || isIP_SK2_122 || isIP_SK3_122
												? 1500
												: 1000
										}
										disabled={isFormDisabled()}
										onChange={() => {
											if (form.$("projectBasicParameters").value.includes("<img src=")) {
												GlobalStore.setNotificationMessage("Vložte vstup bez obrázka!", null, "error")
												form.$("projectBasicParameters").value = ""
											}
										}}
										tooltip={
											isIP_SK2_122 || isIP_SK3_122
												? renderHTML(
														`Popíšte východiskovú situáciu vo vzťahu k predkladanému inovačnému projektu, resp. vstupom, ktoré ovplyvňujú realizáciu <br />inovačného projektu, identifikované problémy a potreby. Ak ide o inovatívne riešenie, ktoré podlieha procesu výskumu a vývoja,<br /> uveďte v akej fáze vývoja sa nachádzate (TRL1 až 8).  Uveďte základné funkcie, prípadne technológie, ktoré budú <br />využívané. Do akej miery vedie inovačný projekt k tvorbe inovatívnych výrobkov, služieb a technológií?`
												  )
												: isIP_ZS_122
												? renderHTML(
														`Uveďte text totožný s Opisom inovačného projektu, ktorý ste vyplnili vo formáte ".xlsx" a zaslali ako prílohu e-mailu "Oslovenie potenciálnych riešiteľov na predloženie cenovej ponuky".<br />
														Popíšte východiskovú situáciu vo vzťahu k predkladanému inovačnému projektu, resp. vstupom, ktoré ovplyvňujú realizáciu inovačného projektu, identifikované problémy a potreby.<br />
														Ak ide o inovatívne riešenie, ktoré podlieha procesu výskumu a vývoja, uveďte v akej fáze vývoja sa nachádzate (TRL1 až 8).  Uveďte základné funkcie, prípadne technológie, ktoré budú využívané.`
												  )
												: isIP_ZS2_123
												? renderHTML(
														`Uveďte text totožný s Opisom inovačného projektu, ktorý ste vyplnili vo formáte .docx a zaslali ako prílohu<br>
														e-mailu "Oslovenie potenciálnych riešiteľov na predloženie cenovej ponuky".<br /><br />
														Popíšte východiskovú situáciu vo vzťahu k predkladanému inovačnému projektu, resp. vstupom, ktoré ovplyvňujú realizáciu inovačného<br />
														projektu, identifikované problémy a potreby. Ak ide o inovatívne riešenie, ktoré podlieha procesu výskumu a vývoja, uveďte v akej fáze<br />
														vývoja sa nachádzate (TRL1 až 8).  Uveďte základné funkcie, prípadne technológie, ktoré budú využívané. Do akej miery vedie inovačný<br />
														projekt k tvorbe inovatívnych výrobkov, služieb a technológií?
													`
												  )
												: renderHTML(`Popíšte východiskovú situáciu vo vzťahu k predkladanému inovačnému projektu, resp. vstupom, ktoré ovplyvňujú realizáciu inovačného projektu, identifikované problémy a potreby. <br/>
									Ak ide o inovatívne riešenie, ktoré podlieha procesu výskumu a vývoja, uveďte v akej fáze vývoja sa nachádzate. <br/>
									Zadefinujte merateľné ukazovatele, ktoré budete v projekte sledovať. Uveďte základné funkcie, prípadne technológie, ktoré budú využívané.`)
										}
									/>
								</Grid>
								<Grid item xs={12} md={12} lg={12}>
									<XsTextArea
										field={form.$("projectImplementationMethod")}
										maxLength={
											isIP_ZS_122 || isIP_ZS2_123
												? 3000
												: form.$("callCode").value == "IP_SK_121" || isIP_SK2_122 || isIP_SK3_122
												? 1500
												: 1000
										}
										disabled={isFormDisabled()}
										onChange={() => {
											if (form.$("projectImplementationMethod").value.includes("<img src=")) {
												GlobalStore.setNotificationMessage("Vložte vstup bez obrázka!", null, "error")
												form.$("projectImplementationMethod").value = ""
											}
										}}
										tooltip={
											isIP_SK2_122 || isIP_SK3_122
												? renderHTML(
														`Uveďte prípadné podmienky realizácie zmluvy, okrem lehoty plnenia a miesta realizácie uvádzaných nižšie. Môžete zároveň<br/> uviesť: predpokladaný spôsob realizácie aktivity projektu; charakteristiku projektu, riziká riešenia (ak sú známe), prínosy<br/> riešenia; situáciu po realizácii projektu; očakávané výsledky a ako dôjde po realizácii projektu k vyriešeniu problémov<br/> identifikovaných v popise východiskovej situácie; očakávané praktické využitie výsledkov projektu; potenciál zmeny pozície na<br/> trhu po uvedení inovácie; vplyv na národné hospodárstvo spolu s kvantifikáciou (zvýšenie vývozu, možnosti ďalšieho rozvoja a iné).<br/>				
														`
												  )
												: isIP_ZS_122
												? renderHTML(
														`Uveďte text totožný s Opisom inovačného projektu, ktorý ste vyplnili vo formáte ".xlsx" a zaslali ako
														 prílohu e-mailu "Oslovenie potenciálnych riešiteľov na predloženie cenovej ponuky". <br/>
														Povinne uveďte podmienky realizácie zmluvy, najmä lehotu plnenia a miesto realizácie, pričom lehota plnenia nemôže prekročiť 3 mesiace od vystavenia IP. <br/>
														Môžete zároveň uviesť: predpokladaný spôsob realizácie aktivity projektu; charakteristiku projektu, riziká riešenia (ak sú známe), prínosy riešenia; situáciu<br/>
														po realizácii projektu; očakávané výsledky a ako dôjde po realizácii projektu k vyriešeniu problémov identifikovaných v popise východiskovej situácie;<br/>
														posúdenie udržateľnosti (prevádzky) výsledkov realizácie projektu z hľadiska administratívnej/technickej a finančnej udržateľnosti výstupov projektu<br/>
														zo strany žiadateľa; očakávané praktické využitie výsledkov projektu; potenciál dopytu a odbytu.														
														`
												  )
												: isIP_ZS2_123
												? renderHTML(
														`Uveďte text totožný s Opisom inovačného projektu, ktorý ste vyplnili vo formáte .docx a zaslali ako prílohu<br>
														e-mailu "Oslovenie potenciálnych riešiteľov na predloženie cenovej ponuky".<br /><br />
														Uveďte prípadné podmienky realizácie zmluvy, okrem lehoty plnenia a miesta realizácie uvádzaných nižšie. Môžete zároveň uviesť:<br />
														predpokladaný spôsob realizácie aktivity projektu; charakteristiku projektu, riziká riešenia (ak sú známe), prínosy riešenia; situáciu po<br />
														realizácii projektu; očakávané výsledky a ako dôjde po realizácii projektu k vyriešeniu problémov identifikovaných v popise východiskovej<br />
														situácie; očakávané praktické využitie výsledkov projektu; potenciál zmeny pozície na trhu po uvedení inovácie; vplyv na národné<br />
														hospodárstvo spolu s kvantifikáciou (zvýšenie vývozu, možnosti ďalšieho rozvoja a iné).<br />
													`
												  )
												: form.$("callCode").value == "IP_SK_121"
												? renderHTML(
														`Povinne uveďte podmienky realizácie zmluvy, najmä lehotu plnenia a miesto realizácie, pričom lehota plnenia nemôže prekročiť 3 mesiace od vystavenia IP. <br/>
														Môžete zároveň uviesť: predpokladaný spôsob realizácie aktivity projektu; charakteristiku projektu, riziká riešenia (ak sú známe), prínosy riešenia; situáciu po realizácii projektu;<br/>
														očakávané výsledky a ako dôjde po realizácii projektu k vyriešeniu problémov identifikovaných v popise východiskovej situácie; posúdenie udržateľnosti (prevádzky)<br/> 
														výsledkov realizácie projektu z hľadiska administratívnej/technickej a finančnej udržateľnosti výstupov projektu zo strany žiadateľa; očakávané praktické využitie výsledkov projektu; potenciál dopytu a odbytu.
														`
												  )
												: renderHTML(`V tejto časti špecifikujte konkrétne podkategórie oprávnených aktivít, ktoré chcete realizovať v Inovačnom projekte a spôsob ich realizácie v podniku.  <br/>
									 V rámci OIP je potrebné špecifikovať podmienky realizácie zmluvy (najmä lehotu plnenia a miesto realizácie, pričom lehota plnenia nemôže prekročiť 6 mesiacov).  <br/>
									 Môžete zároveň uviesť: predpokladaný spôsob realizácie aktivity projektu; charakteristiku projektu, riziká riešenia (ak sú známe), prínosy riešenia;  <br/>
									  situáciu po realizácii projektu; očakávané výsledky a ako dôjde po realizácii projektu k vyriešeniu problémov identifikovaných v popise východiskovej situácie;  <br/>
									 posúdenie udržateľnosti (prevádzky) výsledkov realizácie projektu z hľadiska administratívnej/technickej a finančnej udržateľnosti výstupov projektu zo strany žiadateľa; <br/>
									 očakávané praktické využitie výsledkov projektu; potenciál dopytu a odbytu.`)
										}
									/>
								</Grid>
								{(isIP_SK2_122 || isIP_SK3_122 || isIP_ZS2_123) && (
									<Grid item xs={12} md={12} lg={12}>
										<XsTextArea
											field={form.$("projectRealizationPlace")}
											maxLength={500}
											disabled={isFormDisabled()}
											onChange={() => {
												if (form.$("projectRealizationPlace").value.includes("<img src=")) {
													GlobalStore.setNotificationMessage("Vložte vstup bez obrázka!", null, "error")
													form.$("projectRealizationPlace").value = ""
												}
											}}
											tooltip={
												isIP_ZS2_123
													? renderHTML(
															`Uveďte text totožný s Opisom inovačného projektu, ktorý ste vyplnili vo formáte .docx a zaslali ako prílohu<br>
															e-mailu "Oslovenie potenciálnych riešiteľov na predloženie cenovej ponuky".<br /><br />
															Povinne uveďte všetky miesta realizácie vrátane terénnych alebo laboratórnych podmienok. Pokiaľ Žiadateľ v tomto čase ešte nevie<br />
															špecifikovať miesto realizácie, uvedie ako miesto realizácie - územie SR okrem Bratislavského kraja. V prípade ak prebieha riešenie projektu s riešiteľom<br />
															so sídlom v Bratislavskom kraji, resp. mimo územia SR, Žiadateľ uvádza miesto realizácie napr. „v sídle riešiteľa“, pričom výsledky/výstupy<br />
															Inovačného projektu musia byť umiestnené na území SR okrem Bratislavského kraja.
															`
													  )
													: renderHTML(
															`Povinne uveďte všetky miesta realizácie vrátane terénnych alebo laboratórnych podmienok.  Pokiaľ Žiadateľ v tomto čase ešte nevie<br/>špecifikovať miesto realizácie, uvedie ako miesto realizácie - územie SR. V prípade ak prebieha riešenie projektu s riešiteľom so<br/>sídlom mimo územia SR, Žiadateľ uvádza miesto realizácie napr. „v sídle riešiteľa“, pričom výsledky/výstupy Inovačného projektu musia<br/> byť umiestnené na území SR.`
													  )
											}
										/>
									</Grid>
								)}
								{(isIP_SK2_122 || isIP_SK3_122 || isIP_ZS2_123) && (
									<Grid item xs={12} md={12} lg={12}>
										<XsTextArea
											field={form.$("projectDeadlineCompletion")}
											maxLength={500}
											disabled={isFormDisabled()}
											onChange={() => {
												if (form.$("projectDeadlineCompletion").value.includes("<img src=")) {
													GlobalStore.setNotificationMessage("Vložte vstup bez obrázka!", null, "error")
													form.$("projectDeadlineCompletion").value = ""
												}
											}}
											tooltip={
												isIP_ZS2_123
													? renderHTML(
															`Uveďte text totožný s Opisom inovačného projektu, ktorý ste vyplnili vo formáte .docx a zaslali ako prílohu<br>
															e-mailu "Oslovenie potenciálnych riešiteľov na predloženie cenovej ponuky".<br /><br />
															Povinne definujte lehotu plnenia, ktorá nemôže prekročiť 3 mesiace od vystavenia IP.
															`
													  )
													: renderHTML(
															`Povinne definujte lehotu plnenia, ktorá nemôže prekročiť 3 mesiace od vystavenia IP.`
													  )
											}
										/>
									</Grid>
								)}
								<Grid item xs={12} md={12} lg={12}>
									<XsTextArea
										field={form.$("projectAdditionalInfo")}
										maxLength={
											isIP_SK2_122 || isIP_SK3_122
												? 5000
												: isIP_ZS_122 || isIP_ZS2_123
												? 10000
												: form.$("callCode").value == "IP_SK_121"
												? 1500
												: 1000
										}
										disabled={isFormDisabled()}
										onChange={() => {
											if (form.$("projectAdditionalInfo").value.includes("<img src=")) {
												GlobalStore.setNotificationMessage("Vložte vstup bez obrázka!", null, "error")
												form.$("projectAdditionalInfo").value = ""
											}
										}}
										tooltip={
											isIP_SK2_122 || isIP_SK3_122
												? renderHTML(
														`Tu uveďte akékoľvek doplňujúce údaje, ktoré je potrebné uviesť vo vzťahu k potenciálnemu riešiteľovi inovačného projektu a k<br/> Opisu inovačného projektu pre zhotovenie cenovej ponuky, prípadne ostatné údaje potrebné k dostatočnosti popisu<br/> inovačného projektu, ktoré majú vplyv na hodnotiace kritéria popísané v kapitole 3.4.1 Príručky.<br/>
														Ak sú informácie k inovačnému projektu na webovom sídle môžete ho pripojiť.<br/>
														`
												  )
												: isIP_ZS_122
												? renderHTML(
														`Uveďte text totožný s Opisom inovačného projektu, ktorý ste vyplnili vo formáte ${
															isIP_ZS2_123 ? ".docx" : ".xlsx"
														} a zaslali ako prílohu e-mailu "Oslovenie potenciálnych riešiteľov na predloženie cenovej ponuky".<br/>
														Tu uveďte akékoľvek doplňujúce údaje, ktoré je potrebné uviesť vo vzťahu k odbornému riešiteľovi inovačného projektu a k Opisu inovačného projektu pre zhotovenie cenovej ponuky,<br/>
														prípadne ostatné údaje potrebné k dostatočnosti popisu inovačného projektu. Ak sú informácie k inovačnému projektu na webovom sídle môžete ho pripojiť.
														`
												  )
												: isIP_ZS2_123
												? renderHTML(
														`Uveďte text totožný s Opisom inovačného projektu, ktorý ste vyplnili vo formáte .docx a zaslali ako prílohu<br>
														e-mailu "Oslovenie potenciálnych riešiteľov na predloženie cenovej ponuky".<br /><br />
														Tu uveďte akékoľvek doplňujúce údaje, ktoré je potrebné uviesť vo vzťahu k potenciálnemu riešiteľovi inovačného projektu a k Opisu<br />
														inovačného projektu pre zhotovenie cenovej ponuky, prípadne ostatné údaje potrebné k dostatočnosti popisu inovačného projektu, ktoré<br />
														majú vplyv na hodnotiace kritéria popísané v kapitole 3.4.1 Príručky. Ak sú informácie k inovačnému projektu na webovom sídle môžete ho pripojiť.
														`
												  )
												: form.$("callCode").value == "IP_SK_121"
												? renderHTML(
														`Tu uveďte akékoľvek doplňujúce údaje, ktoré je potrebné uviesť vo vzťahu k odbornému riešiteľovi inovačného projektu a k Opisu inovačného projektu pre zhotovenie cenovej ponuky, <br/>
														 prípadne ostatné údaje potrebné k dostatočnosti popisu inovačného projektu.`
												  )
												: renderHTML(`Tu uveďte akékoľvek doplňujúce údaje, ktoré je potrebné uviesť vo vzťahu k odbornému riešiteľovi inovačného projektu a k Opisu inovačného projektu pre zhotovenie cenovej ponuky,  <br/>
								 prípadne ostatné údaje potrebné k dostatočnosti popisu inovačného projektu. V prípade ak je sídlo v inom samosprávnom kraji ako je BBSK, musí/musia byť prevádzkareň/e v BBSK, <br/>
									pričom sídlo/a prevádzkarne/prevádzky je potrebné uviesť v tejto časti. Ak ste vybrali právnu formu Iný typ, doplňte prosím o aký typ spoločnosti sa jedná.`)
										}
									/>
								</Grid>
							</React.Fragment>
						)}
						{!isZSP_123 && (
							<React.Fragment>
								<Grid item xs={12}>
									<Typography variant="h5">
										Identifikácia potenciálneho riešiteľa s najnižšou cenovou ponukou/oprávneného riešiteľa
									</Typography>
								</Grid>
								<Grid item xs={12} md={6} lg={3}>
									<XsInput field={form.$("potentialSolverName")} disabled={isFormDisabled()} />
								</Grid>
								<Grid item xs={12} md={6} lg={3}>
									<XsNumberInput
										field={form.$("potentialSolverIco")}
										step={"1"}
										min={"1"}
										disabled={isFormDisabled()}
									/>
								</Grid>
								<Grid item xs={12} md={6} lg={3}>
									<XsNumberInput
										field={form.$("potentialSolverDic")}
										step={"1"}
										min={"1"}
										disabled={isFormDisabled()}
									/>
								</Grid>
								<Grid item xs={12} md={6} lg={3}>
									<XsInput field={form.$("potentialSolverIcdph")} disabled={isFormDisabled()} />
								</Grid>
								<Grid item xs={12} md={6} lg={4}>
									<XsNumberInput
										field={form.$("potentialSolverCompletePrice")}
										step={"0.01"}
										min={"1"}
										disabled={isFormDisabled()}
										endText={"€"}
									/>
								</Grid>
								<Grid item xs={12} md={6} lg={4}>
									<XsNumberInput
										field={form.$("potentialSolverVoucherPrice")}
										step={"0.01"}
										min={"1"}
										disabled={isFormDisabled()}
										endText={"€"}
									/>
								</Grid>
								<Grid item xs={12} md={6} lg={4}>
									<XsNumberInput
										field={form.$("potentialSolverCofinancedPrice")}
										step={"0.01"}
										min={"1"}
										disabled={isFormDisabled()}
										endText={"€"}
									/>
								</Grid>
								<Grid item xs={12}>
									<Typography variant="body2">
										1) V súlade s údajom vo formulári Vyhodnotenie cenového prieskumu <br />
										{isIP_SK2_122 || isIP_SK3_122
											? `2) Výšku Inovačnej poukážky stanovuje Žiadateľ v súlade s Príručkou, kapitolou 2.4 Stanovenie
									výšky oprávnených výdavkov`
											: isIP_ZS_122 || isIP_ZS2_123
											? `2) Výšku Inovačnej poukážky stanovuje Žiadateľ v súlade s Príručkou IP`
											: form.$("callCode").value == "IP_SK_121"
											? `2) Výšku Inovačnej poukážky stanovuje Žiadateľ v súlade s Príručkou IP, kapitolou 2.4 Stanovenie výšky oprávnených výdavkov.`
											: `2) Výšku Inovačnej poukážky stanovuje Žiadateľ v súlade s Príručkou IPCEI, kapitolou 2.4 Stanovenie
								výšky oprávnených výdavkov`}
										<br />
										3) Žiadateľ uvádza vlastné spolufinancovanie spolu s neoprávnenými výdavkami hradenými z vlastných
										zdrojov (ak relevantné)
									</Typography>
								</Grid>
							</React.Fragment>
						)}
						<Grid item xs={12} style={{marginTop: "50px"}}>
							<Typography variant="h5" align="center">
								Vyhlásenie žiadateľa o splnení podmienok účasti v Schéme na podporu zvyšovania inovačnej výkonnosti
								podnikateľských subjektov a klastrov v znení neskorších dodatkov (schéma pomoci de minimis) - DM –
								10/2017
							</Typography>
						</Grid>
						<Grid item xs={12}>
							{isZSP_123 && (
								<Typography variant="h6" style={{fontWeight: "400"}}>
									Vyhlasujem, že ku dňu podania ŽoZSP som fyzická alebo právnická osoba podľa § 2 ods. 2 zákona č.
									513/1991 Zb. Obchodný zákonník v znení neskorších predpisov, ktorá je registrovaná a vykonáva
									podnikateľskú činnosť v rámci Slovenskej republiky
								</Typography>
							)}
							{form.$("callCode").value == "IP_IPCEI_121" && (
								<Typography variant="h6" style={{fontWeight: "400"}}>
									Vyhlasujem, že som právnická osoba podľa § 2 ods. 2 písm. a) zákona č. 513/1991 Zb. Obchodný zákonník
									v znení neskorších predpisov.
								</Typography>
							)}
							{form.$("callCode").value == "IP_BBSK_121" && (
								<Typography variant="h6" style={{fontWeight: "400"}}>
									Vyhlasujem, že ku dňu podania ŽoIP od začiatku podnikania, v zmysle § 2 ods. 1 zákona č. 513/1991 Zb.
									Obchodný zákonník v znení neskorších predpisov, uplynulo viac ako 12 mesiacov.
								</Typography>
							)}
							{form.$("callCode").value == "IP_SK_121" && (
								<Typography variant="h6" style={{fontWeight: "400"}}>
									Vyhlasujem, že ku dňu podania žiadosti od začiatku podnikania, v zmysle § 2 ods. 1 zákona č. 513/1991
									Zb. Obchodný zákonník v znení neskorších predpisov, uplynulo viac ako 12 mesiacov.
								</Typography>
							)}
							{(isIP_ZS_122 || isIP_ZS2_123) && (
								<Typography variant="h6" style={{fontWeight: "400"}}>
									1. Vyhlasujem, že ku dňu podania ŽoIP od začiatku podnikania, v zmysle § 2 ods. 1 zákona č. 513/1991
									Zb. Obchodný zákonník v znení neskorších predpisov, uplynulo viac ako 12 mesiacov.
								</Typography>
							)}
							{(isIP_SK2_122 || isIP_SK3_122) && (
								<Typography variant="h6" style={{fontWeight: "400"}}>
									1. Vyhlasujem, že ku dňu podania ŽoIP od začiatku podnikania, v zmysle § 2 ods. 1 zákona č. 513/1991
									Zb. Obchodný zákonník v znení neskorších predpisov, uplynulo viac ako 12 mesiacov
								</Typography>
							)}
						</Grid>
						<Grid item xs={12}>
							<XsCheckbox field={form.$("confirmCheckbox12mothsPassed")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12}>
							{isZSP_123 && (
								<Typography variant="h6" style={{fontWeight: "400"}}>
									Vyhlasujem, že za žiadateľa samostatne, alebo spolu s inými hospodárskymi subjektami tvoriacimi
									skupinu podnikov, ktorá je považovaná za jediný podnik podľa článku 2 ods. 2 Nariadenia komisie (EÚ)
									1407/2013, sú uvedené všetky údaje v portáli na evidenciu a monitorovanie pomoci (ďalej len „IS SEMP“)
									správne. Nežiadal som (resp. nežiadam) o inú pomoc, ktorá nie je zapísaná v „IS SEMP“ za skupinu
									podnikov, s ktorou tvorím jediný podnik. V opačnom prípade o takejto žiadanej pomoci do poskytnutia
									pomoci na základe tohto oznámenia bezodkladne informujem SIEA e-mailovou komunikáciou. Zároveň
									vyhlasujem, že celkový objem minimálnej pomoci poskytnutý podniku spolu s navrhovanou minimálnou
									pomocou v hodnote 0,55 EUR neprekročí maximálnu výšku pomoci stanovenú v článku J) bod 4 Schémy na
									podporu zvyšovania inovačnej výkonnosti podnikateľských subjektov a klastrov v znení neskorších
									dodatkov (schéma pomoci de minimis) - DM – 10/ 2017 (ďalej len „schéma“) počas obdobia, ktoré pokrýva
									príslušný fiškálny rok, ako aj predchádzajúce dva fiškálne roky, a že sa dodržiavajú kumulačné
									pravidlá podľa článku L) schémy.
								</Typography>
							)}
							{form.$("callCode").value == "IP_IPCEI_121" && (
								<Typography variant="h6" style={{fontWeight: "400"}}>
									Vyhlasujem, že ku dňu podania ŽoIP som bol vyhodnotený národnou výberovou komisiou (nominované
									projekty) pre IPCEI H2 dňa 2.2.2021 a pre IPCEI mikroelektronika dňa 1.4.2021, za účelom ich
									predloženia Európskej komisii v rámci prednotifikačného a notifikačného procesu, alebo bol v týchto
									projektoch identifikovaný ako partner a na základe konzultácií s EK DG COMP sa rozhodol predložiť do
									prednotifikačného a notifikačného procesu svoju vlastnú dokumentáciu (Project Portfolio a ostatné).
								</Typography>
							)}
							{form.$("callCode").value == "IP_BBSK_121" && (
								<Typography variant="h6" style={{fontWeight: "400"}}>
									Vyhlasujem, že ku dňu podania ŽoIP je sídlo alebo prevádzkareň/ne podniku v Banskobystrickom
									samosprávnom kraji.
								</Typography>
							)}
							{form.$("callCode").value == "IP_SK_121" && (
								<Typography variant="h6" style={{fontWeight: "400"}}>
									Vyhlasujem, že ku dňu podania ŽoIP je sídlo podniku na území SR okrem územia Banskobystrického
									samosprávneho kraja.
								</Typography>
							)}
							{isIP_ZS_122 && (
								<Typography variant="h6" style={{fontWeight: "400"}}>
									2. Vyhlasujem, že ku dňu podania ŽoIP som fyzická alebo právnické osoby podľa § 2 ods. 2 zákona č.
									513/1991 Zb. Obchodný zákonník v znení neskorších predpisov, ktorá je registrovaná a vykonáva
									podnikateľskú činnosť v rámci Slovenskej republiky mimo bratislavského kraja.
								</Typography>
							)}
							{isIP_ZS2_123 && (
								<Typography variant="h6" style={{fontWeight: "400"}}>
									2. Vyhlasujem, že ku dňu podania ŽoIP som fyzická alebo právnická osoba podľa § 2 ods. 2 zákona č.
									513/1991 Zb. Obchodný zákonník v znení neskorších predpisov, ktorá je registrovaná a vykonáva
									podnikateľskú činnosť v rámci Slovenskej republiky mimo bratislavského kraja.
								</Typography>
							)}

							{(isIP_SK2_122 || isIP_SK3_122) && (
								<Typography variant="h6" style={{fontWeight: "400"}}>
									2. Vyhlasujem, že ku dňu podania ŽoIP som fyzická alebo právnická osoba podľa § 2 ods. 2 zákona č.
									513/1991 Zb. Obchodný zákonník v znení neskorších predpisov, ktorá je registrovaná a vykonáva
									podnikateľskú činnosť v rámci Slovenskej republiky
								</Typography>
							)}
						</Grid>
						<Grid item xs={12}>
							<XsCheckbox field={form.$("confirmCheckboxAddress")} disabled={isFormDisabled()} />
						</Grid>
						{!isZSP_123 && (
							<React.Fragment>
								<Grid item xs={12}>
									{isIP_SK3_122 ? (
										<Typography variant="h6" style={{fontWeight: "400"}}>
											3. Vyhlasujem, že ku dňu podania ŽoIP mi nebola preplatená Inovačná poukážka z akejkoľvek Výzvy v
											rámci NP ZIVSE, ani som schválenú ŽoIP v rámci akejkoľvek inej predchádzajúcej Výzvy NP ZIVSE
											nespäťvzal po jej schválení, ani neodstúpil od Zmluvy IP uzatvorenej na základe schválenej ŽoIP.
										</Typography>
									) : isIP_SK2_122 ? (
										<Typography variant="h6" style={{fontWeight: "400"}}>
											3. Vyhlasujem, že ku dňu podania ŽoIP mi nebola preplatená Inovačná poukážka z akejkoľvek Výzvy v
											rámci NP ZIVSE.
										</Typography>
									) : form.$("callCode").value == "IP_IPCEI_121" ? (
										<Typography variant="h6" style={{fontWeight: "400"}}>
											Vyhlasujem, že údaje uvedené v tejto ŽoIP sú úplné, pravdivé a správne a boli poskytnuté slobodne.
											Zároveň som si vedomý právnych následkov v prípade, že sa preukáže, že čestné vyhlásenie nie je
											pravdivé.
										</Typography>
									) : isIP_ZS_122 ? (
										<Typography variant="h6" style={{fontWeight: "400"}}>
											3. Vyhlasujem, že údaje uvedené v tejto ŽoIP a v Cenovom prieskume sú úplné, pravdivé a správne a
											boli poskytnuté slobodne a opis inovačného projektu, ktorý je súčasťou oslovenia potenciálnych
											riešiteľov na predloženie cenovej ponuky je totožný s opisom inovačného projektu v ŽoIP. Zároveň
											som si vedomý právnych následkov v prípade, že sa preukáže, že čestné vyhlásenie nie je pravdivé.
										</Typography>
									) : isIP_ZS2_123 ? (
										<Typography variant="h6" style={{fontWeight: "400"}}>
											3. Vyhlasujem, že ku dňu podania ŽoIP mi nebola preplatená Inovačná poukážka z akejkoľvek Výzvy v
											rámci NP ZIVSE, ani som schválenú ŽoIP v rámci akejkoľvek inej predchádzajúcej Výzvy NP ZIVSE
											nespäťvzal po jej schválení, ani neodstúpil od Zmluvy IP uzatvorenej na základe schválenej ŽoIP.
										</Typography>
									) : (
										<Typography variant="h6" style={{fontWeight: "400"}}>
											Vyhlasujem, že údaje uvedené v tejto ŽoIP sú úplné, pravdivé a správne a boli poskytnuté slobodne.
											Zároveň som si vedomý právnych následkov v prípade, že sa preukáže, že čestné vyhlásenie nie je
											pravdivé.
										</Typography>
									)}
								</Grid>
								<Grid item xs={12}>
									<XsCheckbox field={form.$("confirmCheckboxAllCorrect")} disabled={isFormDisabled()} />
								</Grid>

								{isIP_ZS_122 && (
									<React.Fragment>
										<Grid item xs={12}>
											<Typography variant="h6" style={{fontWeight: "400"}}>
												Vyhlasujem a rozumiem, že touto ŽoIP žiadam aj o poskytnutie inovačného poradenstva nepriamou
												formou, ktorej pomoc nebude preplatená prostredníctvom Inovačnej poukážky a bude poskytnutá
												bezodplatne od SIEA.
											</Typography>
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("confirmCheckboxConfirmConsulting")} disabled={isFormDisabled()} />
										</Grid>
									</React.Fragment>
								)}

								{isIP_ZS2_123 && (
									<React.Fragment>
										<Grid item xs={12}>
											<Typography variant="h6" style={{fontWeight: "400"}}>
												4. Vyhlasujem, že údaje uvedené v tejto ŽoIP a v Cenovom prieskume sú úplné, pravdivé a správne
												a boli poskytnuté slobodne a opis inovačného projektu, ktorý je súčasťou oslovenia potenciálnych
												riešiteľov na predloženie cenovej ponuky je totožný s opisom inovačného projektu v ŽoIP. Zároveň
												som si vedomý právnych následkov v prípade, že sa preukáže, že čestné vyhlásenie nie je
												pravdivé.
											</Typography>
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("confirmCheckboxConfirmConsulting")} disabled={isFormDisabled()} />
										</Grid>
									</React.Fragment>
								)}

								{(isIP_SK2_122 || isIP_SK3_122) && (
									<React.Fragment>
										<Grid item xs={12}>
											<Typography variant="h6" style={{fontWeight: "400"}}>
												4. Vyhlasujem, že údaje uvedené v tejto ŽoIP a v Cenovom prieskume sú úplné, pravdivé a správne
												a boli poskytnuté slobodne a opis inovačného projektu, ktorý je súčasťou oslovenia potenciálnych
												riešiteľov na predloženie cenovej ponuky je totožný s opisom inovačného projektu v ŽoIP. Zároveň
												som si vedomý právnych následkov v prípade, že sa preukáže, že čestné vyhlásenie nie je
												pravdivé.
											</Typography>
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("confirmCheckboxConfirmConsulting")} disabled={isFormDisabled()} />
										</Grid>
									</React.Fragment>
								)}

								{(isIP_SK2_122 || isIP_SK3_122) && (
									<React.Fragment>
										<Grid item xs={12}>
											<Typography variant="h6" style={{fontWeight: "400"}}>
												5. Vyhlasujem a rozumiem, že touto ŽoIP žiadam aj o poskytnutie inovačného poradenstva nepriamou
												formou, ktorej pomoc nebude preplatená prostredníctvom Inovačnej poukážky a bude poskytnutá
												bezodplatne od SIEA.
											</Typography>
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("confirmCheckboxIP2SK5")} disabled={isFormDisabled()} />
										</Grid>
									</React.Fragment>
								)}

								{isIP_ZS2_123 && (
									<React.Fragment>
										<Grid item xs={12}>
											<Typography variant="h6" style={{fontWeight: "400"}}>
												5. Vyhlasujem a rozumiem, že touto ŽoIP žiadam aj o poskytnutie inovačného poradenstva nepriamou
												formou, ktorej pomoc nebude preplatená prostredníctvom Inovačnej poukážky a bude poskytnutá
												bezodplatne od SIEA.
											</Typography>
										</Grid>
										<Grid item xs={12}>
											<XsCheckbox field={form.$("confirmCheckboxIP2SK5")} disabled={isFormDisabled()} />
										</Grid>
									</React.Fragment>
								)}
							</React.Fragment>
						)}
						{(isIP_ZS_122 || isIP_ZS2_123 || isIP_SK2_122 || isIP_SK3_122 || isZSP_123) && (
							<React.Fragment>
								<Grid item xs={12} style={{marginTop: "50px"}}>
									<Typography variant="h5">Súhlas na zasielanie marketingových informácií</Typography>
								</Grid>
								<Grid item xs={12}>
									Týmto udeľujem SIEA súhlas na zasielanie marketingových informácií a noviniek o službách a aktivitách
									SIEA a Národného projektu Zvýšenie inovačnej výkonnosti slovenskej ekonomiky - NP ZIVSE, kód ITMS2014+
									: 313000I692, a to prostredníctvom elektronickej pošty na emailovú adresu uvedenú v tejto ŽoZSP.
									Súhlas je udelený do 10 rokov od ukončenia doby udržateľnosti NP ZIVSE a je možné ho kedykoľvek
									odvolať zaslaním žiadosti na emailovú adresu{" "}
									<Link className="pointer" onClick={() => window.open("mailto:inovujme@siea.gov.sk ")}>
										inovujme@siea.gov.sk
									</Link>{" "}
									alebo zaslaním písomnej žiadosti na korešpondenčnú adresu SIEA.
								</Grid>

								<Grid item xs={12}>
									<XsCheckbox
										field={form.$("sentMarketingDataYes")}
										onChange={() => {
											form.$("sentMarketingDataYes").value = true
											form.$("sentMarketingDataNo").value = false
										}}
										disabled={isFormDisabled()}
									/>
								</Grid>

								<Grid item xs={12}>
									<XsCheckbox
										field={form.$("sentMarketingDataNo")}
										onChange={() => {
											form.$("sentMarketingDataNo").value = true
											form.$("sentMarketingDataYes").value = false
										}}
										disabled={isFormDisabled()}
									/>
								</Grid>
							</React.Fragment>
						)}
						{!isZSP_123 && (
							<React.Fragment>
								<Grid item xs={12} style={{marginTop: "50px"}}>
									{form.$("callCode").value == "IP_SK_121" ? (
										<Typography variant="h5">Cenový prieskum žiadosti</Typography>
									) : (
										<Typography variant="h5">Povinná príloha k ŽoIP</Typography>
									)}
								</Grid>

								<Grid item container>
									{isSafe(RequestFormStore.attachmentTemplates) &&
										RequestFormStore.attachmentTemplates
											.filter((row) => requiredAttachemnts.includes(row))
											.map((template, dropzoneIndex) => {
												return (
													<Grid item container spacing={2} key={dropzoneIndex}>
														{/* <Grid item xs={12}>
													Cenový prieskum
												</Grid> */}
														<Grid item xs={12}>
															{/* <Dropzone
																onDrop={(files) => {
																	handleDrop(files, dropzoneIndex, 1)
																}}
																id="dropzone"
																disabled={isFormDisabled()}
																accept={".pdf"}
															>
																{isSafe(RequestFormStore[`dropedFiles${dropzoneIndex}`]) &&
																RequestFormStore[`dropedFiles${dropzoneIndex}`].length ? (
																	RequestFormStore[`dropedFiles${dropzoneIndex}`].map((file, index) => (
																		<div key={index} className={"dropedFile"}>
																			<i
																				className="fal fa-file icon"
																				onClick={(e) => {
																					e.stopPropagation()
																					handleDropzonePreview(index, dropzoneIndex)
																				}}
																			/>
																			<i
																				className="far fa-trash-alt deleteIcon"
																				onClick={(e) => {
																					e.stopPropagation()
																					if (!isFormDisabled()) {
																						GlobalStore.openConfirmationDialog("xsDeleteAttachmentFromDropzone", {
																							fileIndex: index,
																							dropzoneIndex: dropzoneIndex
																						})
																					}
																				}}
																			/>
																			<br />
																			<div
																				className={"mac_ellipsis"}
																				data-content-start={file.name.substr(0, Math.floor(file.name.length / 2))}
																				data-content-end={
																					"\u200E" + file.name.substr(Math.floor(file.name.length / 2)) + "\u200E"
																				}
																			/>
																		</div>
																	))
																) : (
																	<div className={"dropzonePlaceholder"}>{`Nahrať cenový prieskum vo formáte PDF`}</div>
																)}
															</Dropzone> */}
														</Grid>
													</Grid>
												)
											})}
								</Grid>
							</React.Fragment>
						)}
					</Grid>
				</Container>
				<Container maxWidth="lg" className="request_form_buttons" style={{marginTop: "15px"}}>
					<Grid item container spacing={1}>
						{!editingMode && (
							<Grid item>
								<XsButton
									icon={<i className="fal fa-pencil"></i>}
									disabled={getUserAccessRights() != "FULL"}
									onClick={() => {
										delayedCallback(300, () => {
											setEditingMode(true)
										})
									}}
									text={"Upraviť žiadosť"}
								/>
							</Grid>
						)}
						{editingMode && (
							<React.Fragment>
								<Grid item>
									<XsButton
										icon={<i className="fal fa-save"></i>}
										onClick={() => {
											delayedCallback(300, () => {
												GlobalStore.openConfirmationDialog("xsSaveChanges")
											})
										}}
										disabled={
											// form.$("status").value == "sent" ||
											// GlobalStore.isRegistrationClose() ||
											// form.$("confirmCheckboxAllCorrect").value == false ||
											form.$("confirmCheckboxAddress").value == false ||
											form.$("confirmCheckbox12mothsPassed").value == false ||
											(isIP_ZS_122 && form.$("confirmCheckboxConfirmConsulting").value == false)
										}
										text="Uložiť zmeny"
									/>
								</Grid>
							</React.Fragment>
						)}
						<Grid item>
							<XsButton
								// disabled={form.$("status").value != "sent"}
								icon={<i className="fal fa-print"></i>}
								onClick={() => {
									delayedCallback(300, () => {
										RequestPrintStore.print(form)
										InfoStore.open(
											"Povoľte, prosím, vyskakovacie okno pre túto stránku ak sa nezobrazí nove okno / záložka s vygenerovaným pdf.",
											<FormattedMessage id="Common.label.warning" />
										)
									})
								}}
								variant="outlined"
								text={"STIAHNUŤ PDF ŽIADOSTI"}
							/>
						</Grid>
						<Grid item>
							{/* <XsButton
								// disabled={form.$("status").value != "sent"}
								icon={<i className="fal fa-print"></i>}
								onClick={() => {
									delayedCallback(300, () => {
										RequestPrintStore.generateExcel(form)
										InfoStore.open(
											"Povoľte, prosím, vyskakovacie okno pre túto stránku ak sa nezobrazí nove okno / záložka s vygenerovaným pdf.",
											<FormattedMessage id="Common.label.warning" />
										)
									})
								}}
								variant="outlined"
								text={"STIAHNUŤ EXCEL ŽIADOSTI"}
							/> */}
						</Grid>
						<Grid item>
							<XsButton
								icon={<i className="fal fa-times"></i>}
								onClick={() => {
									delayedCallback(300, () => {
										if (RequestFormStore.isSameFormValues()) {
											form.reset()
											RouterStore.push("/requests")
										} else {
											GlobalStore.openConfirmationDialog("xsCloseRequestForm")
										}
									})
								}}
								color="secondary"
								text={editingMode ? "ZATVORIŤ A ZRUŠIŤ ZMENY" : "Zatvoriť"}
							/>
						</Grid>
					</Grid>
				</Container>
			</Container>
			<XsConfirmationDialog
				name="xsDeleteAttachmentFromDropzone"
				title={"Pozor"}
				text={"Naozaj chcete odstrániť prílohu?"}
				onConfirmation={() =>
					handleDropzoneDelete(
						GlobalStore.confirmationDialogParams.fileIndex,
						GlobalStore.confirmationDialogParams.dropzoneIndex
					)
				}
			/>
			<XsConfirmationDialog
				name="xsCloseRequestForm"
				text="Naozaj chcete opustiť formulár a zahodť zmeny?"
				confirmBtn={{
					icon: <i className="fal fa-check" />,
					text: "Common.btn.yes"
				}}
				cancelBtn={{
					icon: <i className="fal fa-times" />,
					text: "Common.btn.no"
				}}
				onConfirmation={() => {
					form.reset()
					RouterStore.push("/requests")
				}}
			/>

			<XsConfirmationDialog
				name="xsSaveChanges"
				text={`Prajete si uloziť zmeny?`}
				confirmBtn={{
					icon: <i className="fal fa-check" />,
					text: "Common.btn.yes"
				}}
				cancelBtn={{
					icon: <i className="fal fa-times" />,
					text: "Common.btn.no"
				}}
				onConfirmation={() => {
					form.submit()
				}}
			/>
		</React.Fragment>
	)
}

export default injectIntl(observer(RequestForm))

